import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { topAsset } from '../../dashboard.models';

@Component({
  selector: 'mam-top-asset-card',
  templateUrl: './top-asset-card.component.html',
  styleUrls: ['./top-asset-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TopAssetCardComponent {

  @Input() topAsset?: topAsset;

}
