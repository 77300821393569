<ng-container *ngIf="assets">
  <div class="actions-search-bar-container">
    <div class="search-bar-container" [class.loading]="progressbar.visible$ | async">
      <div class="dropdown_container">
        <button mat-flat-button class="clipbins_search__dropdown search_dropdown" [matMenuTriggerFor]="menu"
          [class.active]="menuTrigger.menuOpen" #menuTrigger="matMenuTrigger"
          mam-firebase-ga="Open search mode selector via dropdown" (click)="$event.stopPropagation()">
          <div class="mode_dropdown">
            <span>{{ currentDeletedMode | titlecase }}</span>
            <mat-icon class="arrow">arrow_drop_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu="matMenu" class="clipbins_search__dropdown_options search_dropdown_options">
          <div class="mat-menu-title">Result Type</div>
          <button *ngFor="let mode of deletedModes" mat-menu-item class="search_dropdown_options__item"
            (click)="handleDeletedModeChange(mode)" mam-firebase-ga="Select type">
            <mat-icon [style.opacity]="mode === currentDeletedMode ? 1 : 0">done</mat-icon>
            <span>{{ mode | titlecase }}</span>
          </button>
        </mat-menu>
      </div>
      <mat-form-field appearance="outline" class="search-bar">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" matInput placeholder="Search assets" aria-label="Search assets" autocomplete="off"
          [formControl]="search">
        <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
          mam-firebase-ga="Clear asset search query in asset deletion table" (click)="search.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-paginator [disabled]="progressbar.visible$ | async"
                     [length]="pagination.totalCount"
                     [pageSize]="pageSize"
                     [pageIndex]="pagination.pageIndex"
                     (page)="changePage($event)"
                     class="main__mat-paginator--border"
                     hidePageSize>
      </mat-paginator>
    </div>
  </div>

    @if (showListView) {
      <!-- ListViews table - feature flag ON -->
      <mam-ui-table id="DeletedAssetsTable" *ngIf="assets && assets?.length"
        class="deleted-list"
        [cols]="cols"
        [data]="assets"
        [options]="{
          tableInfo: {
            id: tableId,
            name: 'deletedAssetsTable'
          },
          multi: true,
          loading: (progressbar.visible$ | async) || false,
          mainColIdx: 1
        }"
        [activeSort]="activeSort"
        (sortClick)="onSort($event, assets)"
      >
        <ng-template #cellTpl let-row let-col="col">
          <!-- Icon -->
          <ng-container *ngIf="col.cellTpl === 'iconTpl'">
            <div class="table-icon">
              <mam-asset-info-icon-button
                [asset]="row"
                icon="insert_drive_file"
                [showIconForExternalUsers]="true">
              </mam-asset-info-icon-button>
            </div>
          </ng-container>

          <!-- Title -->
          <ng-container *ngIf="col.cellTpl === 'titleTpl'">
            <div class="icon-with-text">
              <span
                matTooltipClass="mobile-tooltip"
                matTooltip="{{row.title}}"
                matTooltipPosition="below"
              >
                {{row.title}}
              </span>
            </div>
          </ng-container>

          <!-- Undelete -->
          <ng-container *ngIf="col.cellTpl === 'undeleteTpl'">
            <button
              mat-flat-button aria-label="Undelete"
              mam-firebase-ga="Undelete the deleted asset"
              *ngIf="!undeletedAssetSet.has(row.name)"
              [disabled]="progressbar.visible$ | async"
              (click)="openUndeleteAssetDialog(row)"
              class="undelete-button asset-table-button">
              Undelete
            </button>
          </ng-container>

          <!-- Update time -->
          <ng-container *ngIf="col.cellTpl === 'updateTimeTpl'">
            {{row.updateTime | date:'MMM d, y, h:mm a'}}
          </ng-container>

          <!-- Duration -->
          <ng-container *ngIf="col.cellTpl === 'durationTpl'">
              {{row.duration | durationOrTime}}
          </ng-container>
        </ng-template>

        <ng-template #multiTpl let-row>
          <mam-transfer-details
            [task]="row.rawTask">
          </mam-transfer-details>
        </ng-template>

      </mam-ui-table>

    }
    @else {
      <!-- Standard table - feature flag OFF -->
      <table
      mat-table
      [trackBy]="trackByAsset"
      [dataSource]="assets"
      class="legacy-table"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>
          Title
        </th>
        <td class="main-column" mat-cell *matCellDef="let asset" [title]="asset.title">
          <div class="icon-with-text">
            <mam-asset-info-icon-button
              [asset]="asset"
              icon="insert_drive_file"
              [showIconForExternalUsers]="true"
            >
            </mam-asset-info-icon-button>
            <span matTooltipClass="mobile-tooltip"
              matTooltip="{{asset.title}}"
              matTooltipPosition="below">
              {{asset.title}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Deleted by Column -->
      <ng-container matColumnDef="source">
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="!showAssetsSource">
            <mam-sort-col-btn
              [rows]="assets"
              [key]="'source'"
              colName="Deleted by"
              (mamSortByField)="onSortByField($event)">
              Deleted by
            </mam-sort-col-btn>
        </th>
        <td
          mat-cell
          *matCellDef="let asset"
          [title]="asset.source">
          {{asset.source}}
        </td>
      </ng-container>

      <!-- Last Modified Column -->
      <ng-container matColumnDef="lastModified">
        <th
          mat-header-cell
          *matHeaderCellDef>
          <mam-sort-col-btn
            [rows]="assets"
            [key]="'updateTime'"
            colName="Last Modified"
            (mamSortByField)="onSortByField($event)">
            Last Modified
          </mam-sort-col-btn>
        </th>
        <td mat-cell *matCellDef="let asset">
          <span matTooltipClass="mobile-tooltip"
                matTooltip="{{asset.updateTime | date:'MMM d, y, h:mm a'}}"
                matTooltipPosition="below">
                {{asset.updateTime | date:'MMM d, y, h:mm a'}}
          </span>
        </td>
      </ng-container>

      <!-- Undelete Column -->
      <ng-container matColumnDef="undelete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let asset; let index = index">
          <button mat-flat-button aria-label="Undelete"
                  mam-firebase-ga="Undelete the deleted asset"
                  *ngIf="!undeletedAssetSet.has(asset.name)"
                  [disabled]="progressbar.visible$ | async"
                  (click)="openUndeleteAssetDialog(asset)"
                  [ngClass]="'asset-table-button'">
                  Undelete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedLegacyColumns"></tr>
      <tr mat-row
          *matRowDef="let asset; columns: displayedLegacyColumns;"
          [class.undeleted]="undeletedAssetSet.has(asset.name)">
      </tr>
    </table>

    <div *ngIf="!assets?.length"class="empty-message">
      No deleted assets
    </div>
  }


</ng-container>
