    <div class="actions-search-bar-container">
        <!-- Pagination -->
        <div *ngIf="(currentPage$ | async) as currentPage" class="search-bar-container">
            <div class="bulk-actions">
                <mat-checkbox
                    (change)="onChangeAll($event)"
                    color="primary"
                    class="checkbox-all"
                    [(ngModel)]="selectAll"
                ></mat-checkbox>
                <ng-container *ngIf="selectedResourcesDocumentId.length">
                    <button id="add-users" (click)="addUsersOnSelected()" matTooltip="Add users" mat-icon-button>
                        <mat-icon>person_add</mat-icon>
                    </button>
                    <button id="make-public" (click)="makePublicSelected()" matTooltip="Make public" mat-icon-button>
                        <mat-icon>public</mat-icon>
                    </button>
                </ng-container>
            </div>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" class="search-bar">
                <mat-icon matPrefix>search</mat-icon>
                <input
                    type="text"
                    matInput
                    [formControl]="search"
                    placeholder="Search assets"
                    aria-label="Search assets"
                    autocomplete="off"
                />
                <button
                    *ngIf="search.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    mam-firebase-ga="Clear assets search query in access assets"
                    (click)="search.setValue(''); getAssetsRestrictions();"
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-paginator
                #paginator
                [disabled]="loading || selectedResourcesDocumentId.length !== 0"
                [length]="totalItem"
                [pageSize]="currentPage.pageSize"
                [pageIndex]="currentPage.pageIndex"
                (page)="changePage($event, paginator)"
                [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                class="vod-staging-paginator main__mat-paginator--border"
            >
            </mat-paginator>
        </div>
    </div>

    @if (showListView) {
      <!-- ListViews table - feature flag ON -->
      <mam-ui-table
        [cols]="cols"
        [data]="dataSource.data"
        [options]="{
          tableInfo: {
            name: 'assetRestrictionTable',
            id: tableId
          },
          multi: true,
          mainColIdx: 1
        }"
        (sortClick)="onSort($event)"
        emptyMessage="No restrictions"
      >
      <ng-template #cellTpl let-row let-col="col">
        <!-- Checkbox -->
        <ng-container *ngIf="col.headerTpl === 'checkBoxTpl'">
          <div class="icon-with-text">
            <mat-checkbox
              color="primary"
              [(ngModel)]="row.selected"
              (change)="onChangeResource($event, row)"
            ></mat-checkbox>
          </div>
        </ng-container>

        <!-- Title / File Name -->
        <ng-container *ngIf="col.cellTpl === 'nameTpl'">
          <div class="icon-with-text">
            <span
              matTooltipClass="mobile-tooltip"
              matTooltip="{{row.title}}"
              matTooltipPosition="below"
            >
              {{row.title}}
            </span>
          </div>
        </ng-container>

        <!-- Access Column -->
        <ng-container *ngIf="col.cellTpl === 'permissionTpl'">
          <div class="icon-with-text">
            <mam-access-restriction-icon></mam-access-restriction-icon>
            <span> Restricted </span>
          </div>
        </ng-container>

        <!-- Users Column -->
        <ng-container *ngIf="col.cellTpl === 'usersTpl'">
          <div class="icon-with-text container-users">
            <ng-container *ngIf="row.permissions?.length; else onlyAdmin">
              <ng-container *ngFor="let assetPermission of row.permissions">
                <span
                  class="initial-icon"
                  matTooltipClass="mobile-tooltip"
                  matTooltip="{{ assetPermission.displayName }}"
                  matTooltipPosition="below"
                >
                  {{ assetPermission.displayName?.[0] }}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #onlyAdmin> Admins </ng-template>
          </div>
        </ng-container>

        <!-- Requested by Column -->
        <ng-container *ngIf="col.cellTpl === 'requestedBy'">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip" matTooltip="{{row.createdBy}}" matTooltipPosition="below">
            </span>
          </div>
        </ng-container>

        <!-- Updated Column -->
        <ng-container *ngIf="col.cellTpl === 'updatedTpl'">
          <div class="icon-with-text">
            @if (row.updatedAt) {
            <span
              matTooltipClass="mobile-tooltip"
              matTooltip="{{row.updatedAt  | timestamp_to_date  }}"
              matTooltipPosition="below"
            >
              {{row.updatedAt | timestamp_to_date | date:'medium' }}
            </span>
            }
            @else {
              <span>--</span>
            }
          </div>
        </ng-container>

        <!-- Action Column -->
        <ng-container *ngIf="col.cellTpl === 'actionTpl'">
          <button
            mat-icon-button
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="menu"
            aria-label="Access menu with restrictions actions"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="addUsers(row)">
              <mat-icon>group_add</mat-icon>
              <span> Add Users </span>
            </button>
            <button mat-menu-item (click)="makePublic(row)">
              <mat-icon>public</mat-icon>
              <span> Make Public </span>
            </button>
          </mat-menu>
        </ng-container>

      </ng-template>

      </mam-ui-table>
    }
    @else {
      <!-- Standard table - feature flag OFF -->
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortActive]="activeSort.active"
        [matSortDirection]="activeSort.direction"
        [matSortDisableClear]="true"
        (matSortChange)="onSort($event)"
    >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th id="select-header" mat-header-cell *matHeaderCellDef></th>
            <td class="main-column" mat-cell *matCellDef="let asset" [title]="'select'">
                <div class="icon-with-text">
                    <mat-checkbox
                        color="primary"
                        [(ngModel)]="asset.selected"
                        (change)="onChangeResource($event, asset)"
                    ></mat-checkbox>
                </div>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="title">
            <th id="title-header" mat-header-cell mat-sort-header *matHeaderCellDef>Title/ File Name</th>
            <td class="main-column" mat-cell *matCellDef="let asset" [title]="asset.title">
                <div class="icon-with-text">
                    <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.title}}" matTooltipPosition="below">
                        {{asset.title}}
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Access Column -->
        <ng-container matColumnDef="access">
            <th id="access-header" mat-header-cell *matHeaderCellDef>Access</th>
            <td class="main-column" mat-cell *matCellDef="let asset" [title]="asset.resourceAccessType">
                <div class="icon-with-text">
                    <mam-access-restriction-icon></mam-access-restriction-icon>
                    <span> Restricted </span>
                </div>
            </td>
        </ng-container>

        <!-- Permission Column -->
        <ng-container matColumnDef="users">
            <th id="users-header" mat-header-cell *matHeaderCellDef>Users</th>
            <td class="main-column" mat-cell *matCellDef="let asset">
                <div class="icon-with-text container-users">
                    <ng-container *ngIf="asset.permissions?.length; else onlyAdmin">
                        <ng-container *ngFor="let assetPermission of asset.permissions">
                            <span
                                class="initial-icon"
                                matTooltipClass="mobile-tooltip"
                                matTooltip="{{ assetPermission.displayName }}"
                                matTooltipPosition="below"
                            >
                                {{ assetPermission.displayName?.[0] }}
                            </span>
                        </ng-container>
                    </ng-container>
                    <ng-template #onlyAdmin> Admins </ng-template>
                </div>
            </td>
        </ng-container>

        <!-- Request By Column -->
        <ng-container matColumnDef="requestedBy">
            <th id="request-by-header" mat-header-cell *matHeaderCellDef>Requested by</th>
            <td class="main-column" mat-cell *matCellDef="let asset" [title]="''">
                <div class="icon-with-text">
                    <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.createdBy}}" matTooltipPosition="below">
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Updated At Column -->
        <ng-container matColumnDef="updatedAt">
          <th id="updated-at-header" mat-header-cell *matHeaderCellDef>Update</th>
          <td class="main-column" mat-cell *matCellDef="let asset">
            <div *ngIf="asset.updatedAt" class="icon-with-text">
              <span
                matTooltipClass="mobile-tooltip"
                matTooltip="{{asset.updatedAt  | timestamp_to_date  }}"
                matTooltipPosition="below"
              >
                {{asset.updatedAt | timestamp_to_date | date:'medium' }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- actions At Column -->
        <ng-container matColumnDef="actions">
            <th id="actions-at-header" mat-header-cell  *matHeaderCellDef></th>
            <td class="actions-column" mat-cell *matCellDef="let asset" [title]="'actions'">
                <button
                    mat-icon-button
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="menu"
                    aria-label="Access menu with restrictions actions"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="addUsers(asset)">
                      <mat-icon>group_add</mat-icon>
                      <span> Add Users </span>
                  </button>
                  <button mat-menu-item (click)="makePublic(asset)">
                        <mat-icon>public</mat-icon>
                        <span> Make Public </span>
                  </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [class.selected]="asset?.selected" mat-row *matRowDef="let asset; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="!dataSource.data.length" class="empty-message">No restrictions</div>

    }
