import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

import { ApiCompReelData } from '../api/ias/model/api-comp-reel-data';
import { assertExists } from '../asserts/asserts';
import { ResourceType, ResourceTypes } from '../landing/clip-bin-section/service/resource-types';
import { PaginationInfo, SearchOptions } from '../landing/clip-bin-section/service/resource.service';
import { CompReelInfo, RpcStatus } from '../models';

import { makeFakeClip } from './asset_api_fake_service';
import { Clip } from './asset_service';
import { Bin, BinWithClips, ListResponse, ListWithAssetsResponse } from './bin.service';
import { BinApiService, BinListFilter } from './bin_api.service';
import { pseudoRandom } from './fake_api_utils';
import { Interface } from './utils_service';

/** Serves bins with Fake data */
@Injectable({ providedIn: 'root' })
export class FakeBinApiService implements Interface<BinApiService> {
    create(title: string): Observable<Bin> {
        const newBin = makeFakeClipBin({
            name: `${title}${Math.floor(pseudoRandom(title) * 10)}`,
            title,
            createTime: Date.now()
        });

        // Fake database update
        getClipBinsDb().push(newBin);

        return of(newBin).pipe(delay(100));
    }

    delete(name: string): Observable<null> {
        getClipBinsDb().splice(
            getClipBinsDb().findIndex((clipBin) => clipBin.name === name),
            1
        );
        return of(null).pipe(delay(100));
    }

    rename(name: string, title: string): Observable<Bin> {
        const renamedBin = getClipBinsDb().find((clipbin) => clipbin.name === name);
        assertExists(renamedBin);
        renamedBin.title = title;
        return of(renamedBin).pipe(delay(100));
    }

    listWithAssets(pageToken?: string, pageSize = 20): Observable<ListWithAssetsResponse> {
        const listResponse: ListWithAssetsResponse = { binsWithAssets: [] };
        let index = 0;
        if (pageToken) {
            index = Number(pageToken.split('-')[2]);
        }

        listResponse.nextPageToken = `page-token-${index + 1}`;
        listResponse.binsWithAssets = getClipBins().slice(index * pageSize, pageSize * (index + 1));

        return of(listResponse).pipe(delay(100));
    }

    getBin(name: string): Observable<Bin> {
        const matchingBin = getClipBins().find((bin) => bin.name === name);
        if (!matchingBin) return throwError(() => 'Clipbin not found');
        return of(matchingBin);
    }

    list(filters: BinListFilter, pageToken?: string, pageSize = 40): Observable<ListResponse> {
        // reuse the clipbins to initialize the mock data
        // in real API, the response won't have assets
        let clipBins = getClipBins();
        if (filters.title) {
            const filtersTitle = filters.title.toLocaleLowerCase();
            clipBins = clipBins.filter((b) => b.title.toLocaleLowerCase().includes(filtersTitle));
        }

        const listResponse: ListResponse = { bins: clipBins.slice(0, pageSize) };

        // Generate three pages of results
        switch (pageToken) {
            case FIRST_PAGE_TOKEN:
                listResponse.nextPageToken = SECOND_PAGE_TOKEN;
                break;
            case SECOND_PAGE_TOKEN:
                listResponse.nextPageToken = undefined;
                break;
            default:
                listResponse.nextPageToken = FIRST_PAGE_TOKEN;
                break;
        }

        return of(listResponse).pipe(delay(100));
    }

    generateCompReel(label: string) {
        return this.getBin(label).pipe(delay(500));
    }

    listExportCompReels(): Observable<ListResponse> {
        const compReelsResponse = this.makeFakeListResponse();
        return of(compReelsResponse).pipe(delay(300));
    }

    private makeFakeState(seed: number): ApiCompReelData.StateEnum {
        switch (seed % 4) {
            case 0:
                return ApiCompReelData.StateEnum.COMP_REEL_STATE_UNSPECIFIED;
            case 1:
                return ApiCompReelData.StateEnum.COMP_REEL_FAILED;
            case 2:
                return ApiCompReelData.StateEnum.COMP_REEL_PENDING;
            case 3:
                return ApiCompReelData.StateEnum.COMP_REEL_READY;
        }
        throw new Error('should never get here at makeFakeState');
    }

    private makeFakeCompReelInfo(seed: number): CompReelInfo {
        return new CompReelInfo({
            compReelData: {
                [`/sites/lax/folders/fake-folder`]: {
                    filepath: `asset-file-name-${seed}`,
                    state: this.makeFakeState(seed),
                    updateTime: new Date(Date.now()).toISOString(),
                    errorDetails: new RpcStatus({
                        message: 'error message'
                    })
                }
            }
        });
    }

    private makeFakeBin(seed: number): Bin {
        return {
            name: 'name',
            title: `title-${seed}`,
            createTime: new Date(Date.now()).getTime(),
            assetCount: '1',
            compReelInfo: this.makeFakeCompReelInfo(seed)
        };
    }

    private makeFakeListResponse(): ListResponse {
        // create 4 fake comp reel bins, use index as seed
        return { bins: Array.from({ length: 8 }, (_, i) => this.makeFakeBin(i)) };
    }
}

const FIRST_PAGE_TOKEN = 'page-token-0';
const SECOND_PAGE_TOKEN = 'page-token-1';

/** Internal fake database of clip bins */
let clipBinsDb: BinWithClips[];

// singleton wrapper to ensure setup has taken place before mock data is created
const getClipBinsDb = (): BinWithClips[] => {
    if (!clipBinsDb) {
        clipBinsDb = [...buildEmptyBin(), ...buildOneAssetBin(), ...buildTwoAssetsBin(), ...buildRandomBins(50)];
    }

    return clipBinsDb;
};

/** Simulates a database call with new references each call */
export function getClipBins() {
    const bins = JSON.parse(JSON.stringify(getClipBinsDb())) as BinWithClips[];

    // Bins are always ordered by createTime.
    return [...bins].sort((bin1, bin2) => {
        return bin2.createTime - bin1.createTime;
    });
}

// The Clip Bin is empty
function buildEmptyBin(): BinWithClips[] {
    return [
        makeFakeClipBin({
            name: 'fakeBin-id-empty-asset',
            title: 'Empty Bin',
            assetCount: '0',
            createTime: DateTime.fromObject({ year: 2020, month: 11, day: 8 }).toMillis()
        })
    ];
}

// The Clip Bin will have 1 asset
function buildOneAssetBin(): BinWithClips[] {
    return [
        makeFakeClipBin({
            name: 'fakeBin-id-one-asset',
            title: 'One Thumb Bin',
            assetCount: '1',
            createTime: DateTime.fromObject({ year: 2020, month: 11, day: 9 }).toMillis(),
            clips: [
                makeFakeClip({
                    label: 'fakeBin-id-one-asset',
                    name: 'ChromeCast',
                    title: 'ChromeCast Ad',
                    duration: 60.07,
                    thumbnail: 'fake_data/thumbnails/kobe1.jpg'
                })
            ]
        })
    ];
}

// The Clip Bin will have 2 assets
function buildTwoAssetsBin(): BinWithClips[] {
    return [
        makeFakeClipBin({
            name: 'fakeBin-id-two-assets',
            title: 'Two Thumbs Bin',
            assetCount: '2',
            createTime: DateTime.fromObject({ year: 2020, month: 11, day: 10 }).toMillis(),
            compReelInfo: new CompReelInfo({
                compReelData: makeFakeCompReelDataItem(2)
            }),
            clips: [
                makeFakeClip({
                    label: 'fakeBin-id-two-assets',
                    name: 'ChromeCast',
                    title: 'ChromeCast Ad',
                    duration: 60.07,
                    thumbnail: 'fake_data/thumbnails/kobe1.jpg'
                }),
                makeFakeClip({
                    label: 'fakeBin-id-two-assets',
                    name: 'BBB',
                    title: 'Big Buck Bunny',
                    duration: 596.475,
                    thumbnail: 'fake_data/thumbnails/kobe2.jpg'
                })
            ]
        })
    ];
}

// The generated clip bins will have at least 3 assets.
function buildRandomBins(count: number): BinWithClips[] {
    const asset = makeFakeClip({ name: 'Sintel', title: 'Sintel', duration: 887.999 });
    const assets = Array.from<Clip>({ length: 3 }).fill(asset);

    const kobeAssets = assets.map((a: Clip, index) => {
        const kobeAsset: Clip = { ...a };
        kobeAsset.name += index;
        kobeAsset.thumbnail = `fake_data/thumbnails/kobe${(index % 3) + 1}.jpg`;
        return kobeAsset;
    });

    const winterAssets = assets.map((a: Clip, index) => {
        const winterAsset: Clip = { ...a };
        winterAsset.name += index;
        winterAsset.thumbnail = `fake_data/thumbnails/winter${(index % 3) + 1}.jpg`;
        return winterAsset;
    });

    const bins: BinWithClips[] = [];
    let compReelData = {};
    for (let i = 0; i < count; i++) {
        const customizedBin: Partial<BinWithClips> = {};
        const name = `fakeBin-id-${i}`;
        customizedBin.name = name;
        compReelData = { ...compReelData, ...makeFakeCompReelDataItem(i) };
        const random = pseudoRandom(name);
        // 10% of the time, simulate a "100+" count.
        customizedBin.assetCount = random < 0.1 ? '100+' : String(Math.floor(random * 10) + 3);
        customizedBin.createTime = DateTime.fromObject({ year: 2020, month: 11, day: 1 }).toMillis();
        switch (i % 2) {
            case 0:
                customizedBin.title = 'Remembering Kobe';
                customizedBin.clips = kobeAssets;
                break;
            default:
                customizedBin.title = '2020 Winter Man Skiing';
                customizedBin.clips = winterAssets;
                break;
        }
        for (const clip of customizedBin.clips) {
            clip.label = name;
        }
        bins.push(makeFakeClipBin(customizedBin));
    }

    const compReelInfo = new CompReelInfo({ compReelData });
    const binsWithCompReelInfo = bins.map((bin) => ({ ...bin, compReelInfo }));
    return binsWithCompReelInfo;
}

function makeFakeState(seed: number): ApiCompReelData.StateEnum {
    switch (seed % 4) {
        case 0:
            return ApiCompReelData.StateEnum.COMP_REEL_STATE_UNSPECIFIED;
        case 1:
            return ApiCompReelData.StateEnum.COMP_REEL_FAILED;
        case 2:
            return ApiCompReelData.StateEnum.COMP_REEL_PENDING;
        case 3:
            return ApiCompReelData.StateEnum.COMP_REEL_READY;
    }
    throw new Error('should never get here at makeFakeState');
}

function makeFakeCompReelDataItem(seed: number) {
    return {
        [`projects/234973717435/locations/global/sites/dev/folders/Export Folder ${seed % 5}`]: {
            filepath: `asset-file-name-${seed}`,
            state: makeFakeState(seed),
            updateTime: new Date(Date.now()).toISOString(),
            errorDetails: new RpcStatus({
                message: 'error message'
            })
        }
    };
}

/**
 * Helper function to be used in local development and unit test
 * This should match the ClipBin(Label) API returns with parsing
 */
export function makeFakeClipBin(customizedBin: Partial<BinWithClips> = {}): BinWithClips {
    const defaultBin = {
        title: 'Fake Bin',
        assetCount: '0',
        clips: [],
        createTime: new Date(2020, 3, 25).getTime()
    };

    const bin: BinWithClips = {
        ...defaultBin,
        ...customizedBin,
        name: customizedBin.name || customizedBin.title || 'fake_bin_name'
    };

    return bin;
}

/** Generates parameters for a `list` call with defaults, used by unit tests. */
export function binListResourceParams(
    overrides: Partial<{
        owner: 'current_user' | 'all_users' | undefined;
        searchTerm?: string;
        limit?: number;
        startAfter?: string;
    }> = {}
): [ResourceType, PaginationInfo, SearchOptions] {
    return [
        ResourceTypes.CLIPBIN,
        {
            limit: overrides.limit ?? 24,
            offset: 0,
            startAfter: overrides.startAfter ?? undefined
        },
        {
            owner: overrides.owner ?? 'current_user',
            searchTerm: overrides.searchTerm ?? ''
        }
    ];
}
