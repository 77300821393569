<h1 mat-dialog-title>Delete Folder</h1>
<div mat-dialog-content>
  <p>
    This will delete the folder and move all of it's contents to root, are you sure you want to continue?
  </p>
  <p *ngIf="hasChildrenError$ | async" class="error">
    You cannot delete this folder because it has content.
  </p>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel folder deletion"
      [mam-firebase-resource]="data.displayName">
    Cancel
  </button>
  <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      class="danger"
      mam-firebase-ga="Confirm folder deletion"
      [mam-firebase-resource]="data.displayName"
      [disabled]="isDeleteDisabled()"
      >
    Delete
  </button>
</div>
