@if(featureService.featureOn('enable-clip-bin-organization')) {
  @if(deletedAssets.length) {
    <div class="actions-search-bar-container"
    *ngIf="currentDeletedMode === 'organizers'">
      <div class="search-bar-container" [class.loading]="progressbar.visible$ | async">
        <div class="dropdown_container">
          <button mat-flat-button class="clipbins_search__dropdown search_dropdown" [matMenuTriggerFor]="menu"
            [class.active]="menuTrigger.menuOpen" #menuTrigger="matMenuTrigger" mam-firebase-ga="Open search mode selector via dropdown"
            (click)="$event.stopPropagation()">
            <div class="mode_dropdown">
              <span>{{ currentDeletedMode | titlecase }}</span>
              <mat-icon class="arrow">arrow_drop_down</mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu" class="clipbins_search__dropdown_options search_dropdown_options">
            <div class="mat-menu-title">Result Type</div>
            <button *ngFor="let mode of deletedModes" mat-menu-item class="search_dropdown_options__item"
              (click)="handleDeletedModeChange(mode)" mam-firebase-ga="Select type">
              <mat-icon [style.opacity]="mode === currentDeletedMode ? 1 : 0">done</mat-icon>
              <span>{{ mode | titlecase }}</span>
            </button>
          </mat-menu>
        </div>

        <mat-form-field appearance="outline" class="search-bar">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text" matInput placeholder="Search assets" aria-label="Search assets" autocomplete="off"
            [formControl]="search">
          <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
            mam-firebase-ga="Clear asset search query in asset deletion table" (click)="search.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!-- update -->
        @if (totalItems && pageSize) {
          <mat-paginator
            [length]="totalItems"
            [pageSize]="pageSize"
            (page)="changePage($event)"
            class="main__mat-paginator--border"
            hidePageSize>
          </mat-paginator>
        }
      </div>
    </div>

  @switch (currentDeletedMode) {
    @case ('organizers') {

      @if (showListView) {
      <!-- ListViews table - feature flag ON -->
        <mam-ui-table id="DeletedOrganizersTable"
          class="deleted-list"
          [cols]="cols"
          [data]="deletedAssets"
          [options]="{
            tableInfo: {
              id: tableId,
              name: 'deletedOrganizersTable'
            },
            multi: true,
            mainColIdx: 0
          }"
          [activeSort]="activeSort"
          (sortClick)="onSort($event, deletedAssets)"
        >
        <!-- Header -->
        <ng-template #headerTpl let-col>
          <ng-container *ngIf="col.headerTpl === 'typeTpl'">
            <mam-multiselect-table-header
              headerName="Type"
              [options]="typeFilterOptions"
              (toggle)="onStatusFilterChanged($event)"
            >
            </mam-multiselect-table-header>
          </ng-container>
        </ng-template>

        <!-- Cell -->
        <ng-template #cellTpl let-row let-col="col">
          <!-- Title -->
          <ng-container *ngIf="col.cellTpl === 'titleTpl'">
            <div class="icon-with-text">
              @switch (row.type) {
                @case ("folder") {
                  <mat-icon class="table-icon" role="img" aria-hidden="true"> folder </mat-icon>
                }
                @case ("clipbin") {
                  <mat-icon class="table-icon" role="img" aria-hidden="true"> subscriptions </mat-icon>
                }
                @case ("asset") {
                  <mam-asset-info-icon-button [asset]="row" icon="insert_drive_file" [showIconForExternalUsers]="true">
                  </mam-asset-info-icon-button>
                }
              }
              <span matTooltipClass="mobile-tooltip" matTooltip="{{row.name}}" matTooltipPosition="below">
                {{row.name}}
              </span>
            </div>
          </ng-container>

          <!-- Type -->
          <ng-container *ngIf="col.cellTpl === 'typeTpl'">
            {{row.type | titlecase }}
          </ng-container>

          <!-- Deleted by -->
          <ng-container *ngIf="col.cellTpl === 'userTpl'">
            {{ row.deletedByName || row.deletedBy}}
          </ng-container>

          <!-- Last Modified  -->
          <ng-container *ngIf="col.cellTpl === 'updateTimeTpl'">
            <span
              matTooltipClass="mobile-tooltip"
              matTooltip="{{row.updatedAt | date:'MMM d, y, h:mm a'}}"
              matTooltipPosition="below"
            >
              {{row.updatedAt | date:'MMM d, y, h:mm a'}}
            </span>
          </ng-container>

          <!-- Deleted at -->
          <ng-container *ngIf="col.cellTpl === 'deletedAtTpl'">
            <span
              matTooltipClass="mobile-tooltip"
              matTooltip="{{row.deletedAt | date:'MMM d, y, h:mm a'}}"
              matTooltipPosition="below"
            >
              {{row.deletedAt | date:'MMM d, y, h:mm a'}}
            </span>
            <!-- TODO: CHANGE THIS LATER  -->
            <span
              matTooltipClass="mobile-tooltip"
              matTooltip="{{row.updateTime | date:'MMM d, y, h:mm a'}}"
              matTooltipPosition="below"
            >
              {{row.updateTime | date:'MMM d, y, h:mm a'}}
            </span>
          </ng-container>

          <!-- Undelete -->
          <ng-container *ngIf="col.cellTpl === 'undeleteTpl'">
            <button
              mat-flat-button
              aria-label="Undelete"
              mam-firebase-ga="Undelete the deleted asset"
              *ngIf="!undeletedAssetSet.has(row.name)"
              [disabled]="progressbar.visible$ | async"
              (click)="openUndeleteAssetDialog(row)"
              [ngClass]="'asset-table-button'"
            >
              Undelete
            </button>
          </ng-container>

        </ng-template>

      </mam-ui-table>
      }
      @else {
        <table
          mat-table
          [dataSource]="deletedAssets"
          matSort
          matSortActive="deletedAt"
          matSortDirection="desc"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
              Title
            </th>
            <td class="main-column" mat-cell *matCellDef="let asset" [title]="asset.name">
              <div class="icon-with-text">
                @switch (asset.type)
                {
                @case ("folder")
                {
                <mat-icon role="img" aria-hidden="true"> folder </mat-icon>
                }
                @case ("clipbin")
                {
                <mat-icon role="img" aria-hidden="true"> subscriptions </mat-icon>
                }
                @case ("asset") {

              <mam-asset-info-icon-button [asset]="asset" icon="insert_drive_file" [showIconForExternalUsers]="true">
              </mam-asset-info-icon-button>
            }
          }
          <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.name}}" matTooltipPosition="below">
            {{asset.name}}
          </span>
        </div>
      </td>
    </ng-container>

          <!-- Type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef [hidden]="!showAssetsSource">
              <mam-sort-col-btn [rows]="deletedAssets" [key]="'type'" colName="Type" (mamSortByField)="onSortByField($event)">
                <ng-container matSuffix>
                  <button mat-button class="clipbins_search__dropdown search_dropdown" [matMenuTriggerFor]="menu"
                    [class.active]="menuTrigger.menuOpen" #menuTrigger="matMenuTrigger"
                    mam-firebase-ga="Open search mode selector via dropdown" (click)="$event.stopPropagation()">
                    <div class="search_dropdown_inner">
                      <span>Type</span>
                      <mat-icon class="arrow">arrow_drop_down</mat-icon>
                    </div>
                  </button>

                  <!-- Search type menu -->
                  <mat-menu #menu="matMenu" class="clipbins_search__dropdown_options search_dropdown_options">
                    <div class="mat-menu-title">Type</div>
                    <button *ngFor="let type of resourceTypes; index as $index" mat-menu-item class="search_dropdown_options__item"
                      (click)="handleFilterChange(type)" mam-firebase-ga="Select type">
                      <mat-icon [style.opacity]="getIconOpacity(type, $index)">done</mat-icon>
                      <span>{{ type | titlecase }}</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </mam-sort-col-btn>
              </th>
              <td mat-cell *matCellDef="let asset" [title]="asset.type">
                {{asset.type | titlecase }}
              </td>
            </ng-container>

          <!-- Deleted by Column -->
          <ng-container matColumnDef="deletedBy">
            <th mat-header-cell *matHeaderCellDef [hidden]="!showAssetsSource">
              <mam-sort-col-btn [rows]="deletedAssets" [key]="'deletedBy'" colName="Deleted by"
                (mamSortByField)="onSortByField($event)">
                Deleted by
              </mam-sort-col-btn>
            </th>
            <td mat-cell *matCellDef="let asset" [title]="asset.deletedBy">
              {{ asset.deletedByName || asset.deletedBy}}
            </td>
          </ng-container>

          <!-- Last Modified Column -->
          <ng-container matColumnDef="lastModified">
            <th mat-header-cell *matHeaderCellDef>
              <mam-sort-col-btn [rows]="deletedAssets" [key]="'updatedAt'" colName="Last Modified"
                (mamSortByField)="onSortByField($event)">
                Last Modified
              </mam-sort-col-btn>
            </th>
            <td mat-cell *matCellDef="let asset">
              <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.updatedAt | date:'MMM d, y, h:mm a'}}"
                matTooltipPosition="below">
                {{asset.updatedAt | date:'MMM d, y, h:mm a'}}
              </span>
            </td>
          </ng-container>

          <!-- Delete date Column -->
          <ng-container matColumnDef="deletedAt">
            <th mat-header-cell *matHeaderCellDef>
              <mam-sort-col-btn [rows]="deletedAssets" [key]="'deletedAt'" colName="Delete Date"
                (mamSortByField)="onSortByField($event)">
                Delete Date
              </mam-sort-col-btn>
            </th>
            <td mat-cell *matCellDef="let asset">
              <span matTooltipClass="mobile-tooltip"
                    matTooltip="{{asset.deletedAt | date:'MMM d, y, h:mm a'}}"
                    matTooltipPosition="below">
                    {{asset.deletedAt | date:'MMM d, y, h:mm a'}}
              </span>

              <!-- TODO: CHANGE THIS LATER  -->
              <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.updateTime | date:'MMM d, y, h:mm a'}}"
                matTooltipPosition="below">
                {{asset.updateTime | date:'MMM d, y, h:mm a'}}
              </span>
            </td>
          </ng-container>

          <!-- Undelete Column -->
          <ng-container matColumnDef="undelete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let asset; let index = index">
              <button mat-flat-button aria-label="Undelete" mam-firebase-ga="Undelete the deleted asset"
                *ngIf="!undeletedAssetSet.has(asset.name)" [disabled]="progressbar.visible$ | async"
                (click)="openUndeleteAssetDialog(asset)" [ngClass]="'asset-table-button'">
                Undelete
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let asset; columns: displayedColumns;"
            [class.undeleted]="undeletedAssetSet.has(asset.name)">
          </tr>
        </table>

        <div *ngIf="!deletedAssets?.length" class="empty-message">
          No deleted assets
        </div>
      }

    }

    @case ('assets') {
      <mam-asset-deletion-table-legacy
        [deletedModes]="deletedModes"
        [currentDeletedMode]="currentDeletedMode"
        (deletedModeChange)="handleDeletedModeChange($event)"
      ></mam-asset-deletion-table-legacy>
    }
  }
}

} @else {
<mam-asset-deletion-table-legacy></mam-asset-deletion-table-legacy>
}
