<div class="title-dialog">
    <h1>Access to restricted asset</h1>
</div>
<div class="content-dialog" mat-dialog-content>
    <mat-form-field class="field-wrapper" subscriptSizing="dynamic" appearance="outline">
        <mat-icon matSuffix>group_add</mat-icon>
        <input matInput type="text" [formControl]="searchEmail" placeholder="Add Users" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addToShare($event)">
            @for (option of filteredOptions$ | async; track option) {
            <mat-option [value]="option.email">{{option.email}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
    <p class="content-title">People with access</p>

    <div class="people-list">
        <ng-container *ngIf="resourceWithAccess.length; else empty">
            @for (item of resourceWithAccess; track $index) {
            <div class="person-item" [class.revoked-item]="item.toRevoke">
                <div class="person-item-container">
                    <div class="person-item-container__img">
                        <mat-icon>person</mat-icon>
                    </div>
                    <div class="person-item-container__description">
                        <p>{{item.displayName}}</p>
                        <p>{{item.email}}</p>
                    </div>
                </div>

                <mat-icon
                    class="person-item__action"
                    [class.revoked]="item.toRevoke"
                    (click)="item.toRevoke = !item.toRevoke"
                    >{{ item.toRevoke ? 'close': 'delete_forever' }}</mat-icon
                >
            </div>
            }
        </ng-container>
        <ng-template #empty>
            <p class="content-empty">Admins</p>
        </ng-template>
    </div>
</div>

<mat-divider> </mat-divider>
<p class="divider-text" [class.revoke-alert]="hasRevoke()">
    {{
      hasRevoke()
      ? 'This will revoke access for the selected users. Make sure to review your selection before proceeding. '
      : data.updateManyAsset ? 'Add new users to the existing list of users for each asset.' : 'Only people with access can see the asset'
      }}
</p>
<div class="actions-dialog" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">CANCEL</button>
    <ng-container *ngIf="data.updateManyAsset; else singleUpdate">
      <button mat-button (click)="confirmUpdateMany()">DONE</button>
    </ng-container>
    <ng-template #singleUpdate>
      <button mat-button (click)="confirmUpdate()">DONE</button>
    </ng-template>

</div>
