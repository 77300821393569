import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  ReplaySubject,
  takeUntil,
  tap,
} from 'rxjs';

import { Clip } from '../../services/asset_service';
import { Bin } from '../../services/bin.service';
import { DisplayMode } from '../../services/vod_search_service';
import { ClipBinBinMoveDialog } from '../../shared/clipbin_bin_move_dialog/clipbin_bin_move_dialog';
import { ClipBinFolderDeleteDialog } from '../../shared/clipbin_folder_delete_dialog/clipbin_folder_delete_dialog';
import { ClipBinFolderMoveDialog } from '../../shared/clipbin_folder_move_dialog/clipbin_folder_move_dialog';
import { ClipBinFolderUpdateDialog } from '../../shared/clipbin_folder_update_dialog/clipbin_folder_update_dialog';
import {
  DeleteBinDialog,
  DeleteBinDialogData,
} from '../../shared/delete_bin_dialog';
import { RenameBinDialog } from '../../shared/rename_bin_dialog';
import {
  ResourceTypeApiName,
  ResourceTypes,
} from '../clip-bin-section/service/resource-types';
import {
  Resource,
  ResourceContent,
  ResourceService,
} from '../clip-bin-section/service/resource.service';

type ResultItem = Resource | Bin | Clip;

interface ExpandedRow {
  id: string;
  element: ResultItem;
  detailRow: boolean;
  expanded$: BehaviorSubject<boolean>;
  isLoading$: BehaviorSubject<boolean>;
}

@Component({
  selector: 'mam-cbo-list-display',
  styleUrl: './cbo-list-display.component.scss',
  templateUrl: './cbo-list-display.component.html',
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CboListDisplayComponent implements OnDestroy, OnInit {
  @Input() result!: ResultItem[];
  @Input() selection!: Set<unknown>;
  @Output() toggleSelectionEvent = new EventEmitter();
  @Input() isSubRow: boolean = false;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  displayedColumns: string[] = [
    'name',
    'content',
    'owner',
    'menu',
  ];
  isDisplayingBin: boolean = false;

  showLoadMoreButton: boolean = false;

  dataSource$: Observable<ExpandedRow[]> = new Observable();

  // Updated cache type
  expandedFolderDataCache: { [folderId: string]: ResultItem[] } = {};

  // Simple ID counter for generating unique IDs
  private idCounter: number = 0;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private resourceService: ResourceService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSource$ = this.createDataSource$(this.result);
    if (this.result.length == 12) this.showLoadMoreButton = true;

    if (this.result && this.result.length > 0) {
      const firstItem = this.result[0];
      if (this.isResource(firstItem)) {
        this.isDisplayingBin = firstItem.type === 'clipbin';
      } else if (this.isBin(firstItem)) {
        this.isDisplayingBin = true;
      }
    }
  }

  createDataSource$(items: ResultItem[]): Observable<ExpandedRow[]> {
    return new BehaviorSubject(items).pipe(
      map((resultItems) =>
        resultItems.map((item) => {
          const id = this.getItemId(item);
          return {
            id,
            element: item,
            detailRow: false,
            expanded$: new BehaviorSubject<boolean>(false),
            isLoading$: new BehaviorSubject<boolean>(false),
          };
        })
      ),
      tap((expandedRows) =>
        expandedRows.forEach((row) => this.setupRowExpansionListener(row))
      )
    );
  }

  getItemId(item: ResultItem): string {
    return this.isResource(item)
      ? item.id ?? ''
      : `item-${this.idCounter++}`;
  }

  setupRowExpansionListener(row: ExpandedRow): void {
    row.expanded$.pipe(takeUntil(this.destroyed$)).subscribe((isExpanded) => {
      if (
        isExpanded &&
        this.isResource(row.element) &&
        row.element.directChildrenCount &&
        row.element.directChildrenCount > 0 &&
        !this.expandedFolderDataCache[row.id]
      ) {
        // Start loading
        row.isLoading$.next(true);
        this.fetchFolderData(row);
      }
    });
  }

  toggleExpansion(row: ExpandedRow): void {
    const currentExpansionState = row.expanded$.value;
    row.expanded$.next(!currentExpansionState);
  }

  fetchFolderData(row: ExpandedRow): void {
    const folderId = row.id;
    this.resourceService
      .getResourceChildren(ResourceTypes.FOLDER, folderId, {
        limit: 12,
        offset: 0,
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError((error) => {
          console.error('Error fetching folder data:', error);
          // Stop loading in case of error
          row.isLoading$.next(false);
          return of(null);
        })
      )
      .subscribe((resourceContent: ResourceContent | null) => {
        if (resourceContent) {
          // Cache the ResultItem[] directly
          this.expandedFolderDataCache[folderId] =
            resourceContent.parent.children;
          // Stop loading after data is fetched
          row.isLoading$.next(false);
          this.cdr.markForCheck();
        }
      });
  }

  isExpansionDetailRow = (index: number, item: ExpandedRow) => {
    return item.detailRow;
  };

  trackById(index: number, item: ExpandedRow): string {
    return item.id;
  }

  isResource(obj: ResultItem): obj is Resource {
    return 'type' in obj && typeof obj.type === 'string';
  }

  isBin(obj: ResultItem): obj is Bin {
    return 'type' in obj && typeof obj.type === 'string';
  }

  isClip(obj: ResultItem): obj is Clip {
    return 'type' in obj && typeof obj.type === 'string';
  }

  get resourceResult(): Resource[] {
    return this.result as Resource[];
  }

  toggleSelect(resource: ResultItem) {
    this.toggleSelectionEvent.emit(resource);
  }

  executeAction(action: string, resource: Resource | Bin | Clip) {
    if (!this.isResource(resource)) {
      return;
    }
    if (!resource.type || !this.ACTIONS_TARGET[resource.type]) {
      return;
    }
    this.ACTIONS_TARGET[resource.type][action](resource);
  }

  openShareBin(bin: Resource) {
    console.log('openShareBin', bin);
  }

  openRenameBin = (bin: Resource) => {
    this.dialog
      .open(RenameBinDialog, {
        ...RenameBinDialog.dialogOptions,
        data: { title: bin.name, name: bin.id },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newTitle) => {
        if (!newTitle) {
          return;
        }
        if ('title' in bin) {
          bin.title = newTitle;
        }
      });
  };

  moveBinToFolder = (bin: Resource) => {
    this.dialog.open(ClipBinBinMoveDialog, {
      ...ClipBinBinMoveDialog.dialogOptions,
      data: bin,
    });
  };

  openDeleteBin = (bin: Resource) => {
    this.dialog
      .open<DeleteBinDialog, DeleteBinDialogData>(DeleteBinDialog, {
        ...DeleteBinDialog.dialogOptions,
        data: { resource: bin },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$));
  };

  moveFolderToFolder = (folder: Resource) => {
    this.dialog.open(ClipBinFolderMoveDialog, {
      ...ClipBinFolderMoveDialog.dialogOptions,
      data: folder,
    });
  };

  openDeleteFolder = (folder: Resource) => {
    this.dialog.open(ClipBinFolderDeleteDialog, {
      ...ClipBinFolderDeleteDialog.dialogOptions,
      data: folder,
    });
  };

  openRenameFolder = (folder: Resource) => {
    this.dialog.open(ClipBinFolderUpdateDialog, {
      ...ClipBinFolderUpdateDialog.dialogOptions,
      data: folder,
    });
  };

  navigateToFolder = (folder: Resource) => {
    if (folder.id) {
      const queryParams = { viewMode: DisplayMode.LIST };
      this.router.navigate(['/folders', folder.id], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    }
  };

  navigateToClipBin = (bin: Resource) => {
    if (bin.id) {
      this.router.navigate(['/clipbin', bin.iasId, 'clip', 0], {
        queryParamsHandling: 'merge',
        queryParams: { type: 'bin' },
      });
    }
  };

  private ACTIONS_TARGET: Record<
    ResourceTypeApiName,
    Record<string, Function>
  > = {
    folder: {
      move: this.moveFolderToFolder,
      rename: this.openRenameFolder,
      delete: this.openDeleteFolder,
      navigate: this.navigateToFolder,
    },
    clipbin: {
      delete: this.openDeleteBin,
      move: this.moveBinToFolder,
      rename: this.openRenameBin,
      navigate: this.navigateToClipBin,
    }, //TODO: REVIEW BELOW RESOURCE TYPES ACCORDINGLY
    clip: {
      share: this.openShareBin,
    },
    asset: {
      share: this.openShareBin,
    },
  };

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
