import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssetKeys } from '../admin/dashboard/dashboard.models';
import { AuthService } from '../auth/auth_service';

const ASSETS_METRICS_ENDPOINT = '/metrics/assets-metrics';
const NLQ_ENDPOINT = '/metrics/nlq';
const DEFAULT_ASSETS_COUNT = 10;

@Injectable({
  providedIn: 'root',
})
export class DashBoardService {

  constructor(
    private readonly authService: AuthService,
    private _http: HttpClient,
  ) { }


  public fetchAssetsList(assetQuery: AssetKeys, limit?: number): Observable<object[]> {
    const accessToken = this.authService.getAccessToken();
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
    };
    const params = new HttpParams()
      .set('limit', limit || DEFAULT_ASSETS_COUNT)
      .set('assetQuery', assetQuery);
    return this._http.get<object[]>(ASSETS_METRICS_ENDPOINT, {headers, params});
  }

  public fetchNlq(query: string) {
    const accessToken = this.authService.getAccessToken();
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
    };
    return this._http.post(NLQ_ENDPOINT, { query }, { headers });
  }
}
