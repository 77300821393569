import { Timestamp } from '@firebase/firestore';

import { UserAccess } from '../../admin/admin_users_service';

export enum PermissionEnum {
  GRANTED = 'GRANTED',
}

export interface PermissionDetail {
  userId: string;
  displayName: string;
  permission: PermissionEnum;
  updatedAt: Timestamp;
  updatedBy: string;
  validUntil?: Timestamp;
}

export type ResourceAccessTypeEnum = 'RESTRICTION';

export type ResourceTypeEnum = 'ASSET';

export interface ResourceAccessInfo {
  resourceId: string;
  resourceAccessType: ResourceAccessTypeEnum;
  resourceType: ResourceTypeEnum;
  createdAt: Timestamp;
  createdBy: string;
  updatedAt: Timestamp;
  updatedBy: string;
  permissions: PermissionDetail[];
  params?:string;
  /**Firebase documentId */
  documentId?: string;
  /**Title */
  title:string;
}

export interface ResourceAccessUser {
  displayName: string;
  email: string;
  access: UserAccess;
}
