<ng-container *ngIf="(searchResponse$ | async) as usersPortion">
<div class="admin_users__mat-pagination--spacing">

  <div class="actions-search-bar-container">
    <!-- Pagination -->
    <div
        *ngIf="(currentPage$ | async) as currentPage"
        class="search-bar-container">
      <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="search-bar">
        <mat-icon matPrefix>search</mat-icon>
        <input
            type="text"
            matInput
            [formControl]="search"
            placeholder="Search users"
            aria-label="Search users"
            autocomplete="off">
        <button
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            mam-firebase-ga="Clear users search query in admin users"
            (click)="search.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-paginator
        [disabled]="loading"
        [length]="filteredUsers.length"
        [pageSize]="currentPage.pageSize"
        [pageIndex]="currentPage.pageIndex"
        (page)="changePage($event)"
        [pageSizeOptions]="PAGE_SIZE_OPTIONS"
        class="vod-staging-paginator main__mat-paginator--border">
      </mat-paginator>
    </div>
  </div>

  @if (showListView) {
    <!-- ListViews table - feature flag ON -->
    <mam-ui-table id="AdminUsersTable" *ngIf="usersPortion?.length"
      [cols]="cols"
      [data]="usersPortion"
      [options]="{
        tableInfo: {
            id: tableId,
            name: 'adminUsersTable',
          },
        multi: true,
        mainColIdx: 0
      }"
      [activeSort]="activeSort"
      (sortClick)="onSort($event)"
      [emptyMessage]="'No users'"
    >
      <!-- Header Templates -->
      <ng-template #headerTpl let-col>
        <ng-container *ngIf="col.headerTpl === 'oktaStatusHeaderTpl'">
          <mam-multiselect-table-header
              headerName="OKTA Status"
              [options]="oktaStatusFilterOptions"
              (toggle)="onOktaStatusFilterChanged($event)">
          </mam-multiselect-table-header>
        </ng-container>

        <ng-container *ngIf="col.headerTpl === 'accessHeaderTpl'">
          <mam-multiselect-table-header
              headerName="Access"
              [options]="accessFilterOptions"
              (toggle)="onAccessFilterChanged($event)">
          </mam-multiselect-table-header>
        </ng-container>

        <ng-container *ngIf="col.headerTpl === 'googleWorkspaceAccessHeaderTpl'">
          <mam-multiselect-table-header
              headerName="Workspace"
              [options]="googleWorkspaceAccessFilterOptions"
              (toggle)="onGoogleWorkspaceAccessFilterChanged($event)">
          </mam-multiselect-table-header>
        </ng-container>
      </ng-template>

      <!-- Cell Templates -->
      <ng-template #cellTpl let-row let-col="col">
        <ng-container *ngIf="col.cellTpl === 'displayNameTpl'">
          <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{row.displayName}}"
                  matTooltipPosition="below">
                  {{row.displayName}}
            </span>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'emailTpl'">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{row.email}}"
                  matTooltipPosition="below">
                  {{row.email}}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'oktaStatusTpl'">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{row.oktaStatus}}"
                  matTooltipPosition="below">
                  {{row.oktaStatus}}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'lastLoginTpl'">
          <span matTooltipClass="mobile-tooltip"
                matTooltip="{{row.lastLogin}}"
                matTooltipPosition="below">
                {{row.lastLogin}}
          </span>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'accessTpl'">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{row.access}}"
                  matTooltipPosition="below">
                  {{row.access}}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'googleWorkspaceAccessTpl'">
          <div *ngIf="row &&
           (row.googleWorkspaceAccess === GoogleWorkspaceAccess.YES ||
           row.googleWorkspaceAccess === GoogleWorkspaceAccess.NO) ; else noValue">
            <!-- HTML code to render if the value is present -->
              <span matTooltipClass="mobile-tooltip"
                    matTooltip="{{row.googleWorkspaceAccess}}"
                    matTooltipPosition="below">
                {{row.googleWorkspaceAccess}}
              </span>
          </div>
          <ng-template #noValue>
            <mam-admin-user-table-google-workspace-cell
              [email]="row.email"
              (updateGoogleWorkspaceEvent)="updateGoogleWorkspaceOnUser($event)">
            </mam-admin-user-table-google-workspace-cell>
          </ng-template>
        </ng-container>
        <ng-template #multiTpl let-row>
          <span>abc</span>
        </ng-template>
      </ng-template>
    </mam-ui-table>

  }
  @else {
    <!-- Standard table - feature flag OFF -->
    <table
      mat-table
      [dataSource]="usersPortion"
      matSort
      [matSortActive]="activeSort.active"
      [matSortDirection]="activeSort.direction"
      [matSortDisableClear]="true"
      (matSortChange)="onSortChanged($event)"
    >

      <!-- Name Column -->
      <ng-container matColumnDef="displayName">
        <th id="displayName-header" mat-header-cell mat-sort-header *matHeaderCellDef>
          Name
        </th>
        <td class="main-column" mat-cell *matCellDef="let user" [title]="user.displayName">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{user.displayName}}"
                  matTooltipPosition="below">
                  {{user.displayName}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th id="email-header" mat-header-cell mat-sort-header *matHeaderCellDef>
          Email
        </th>
        <td class="main-column" mat-cell *matCellDef="let user" [title]="user.email">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{user.email}}"
                  matTooltipPosition="below">
                  {{user.email}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Okta Status Column -->
      <ng-container matColumnDef="oktaStatus">
        <th id="oktaStatus-header" mat-header-cell *matHeaderCellDef>
          <mam-multiselect-table-header
              headerName="OKTA Status"
              [options]="oktaStatusFilterOptions"
              (toggle)="onOktaStatusFilterChanged($event)">
          </mam-multiselect-table-header>
        </th>
        <td class="main-column" mat-cell *matCellDef="let user" [title]="user.oktaStatus">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{user.oktaStatus}}"
                  matTooltipPosition="below">
                  {{user.oktaStatus}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Last Login Column -->
      <ng-container matColumnDef="lastLogin">
        <th id="lastLogin-header" mat-header-cell mat-sort-header *matHeaderCellDef>
          Last Login
        </th>
        <td class="main-column" mat-cell *matCellDef="let user" [title]="user.lastLogin">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{user.lastLogin}}"
                  matTooltipPosition="below">
                  {{user.lastLogin}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Access Column -->
      <ng-container matColumnDef="access">
        <th id="access-header" mat-header-cell *matHeaderCellDef>
          <mam-multiselect-table-header
              headerName="Access"
              [options]="accessFilterOptions"
              (toggle)="onAccessFilterChanged($event)">
          </mam-multiselect-table-header>
        </th>
        <td class="main-column" mat-cell *matCellDef="let user" [title]="user.access">
          <div class="icon-with-text">
            <span matTooltipClass="mobile-tooltip"
                  matTooltip="{{user.access}}"
                  matTooltipPosition="below">
                  {{user.access}}
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Google Workspace Access Column -->
      <ng-container matColumnDef="googleWorkspaceAccess">
        <th id="googleWorkspaceAccess-header" mat-header-cell *matHeaderCellDef>
          <mam-multiselect-table-header
              headerName="Workspace"
              [options]="googleWorkspaceAccessFilterOptions"
              (toggle)="onGoogleWorkspaceAccessFilterChanged($event)">
          </mam-multiselect-table-header>
        </th>
        <td mat-cell *matCellDef="let user" [title]="user.googleWorkspaceAccess">
          <div *ngIf="user &&
          (user.googleWorkspaceAccess === GoogleWorkspaceAccess.YES ||
          user.googleWorkspaceAccess === GoogleWorkspaceAccess.NO) ; else noValue">
            <!-- HTML code to render if the value is present -->
              <span matTooltipClass="mobile-tooltip"
                    matTooltip="{{user.googleWorkspaceAccess}}"
                    matTooltipPosition="below">
                {{user.googleWorkspaceAccess}}
              </span>
          </div>
          <ng-template #noValue>
            <mam-admin-user-table-google-workspace-cell
              [email]="user.email"
              (updateGoogleWorkspaceEvent)="updateGoogleWorkspaceOnUser($event)">
            </mam-admin-user-table-google-workspace-cell>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="!usersPortion.length" class="empty-message">
      No users
    </div>
  }

</div>
</ng-container>
