import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AdminModule } from '../admin/admin_module';
import { FeatureFlagModule } from '../feature_flag/feature_flag_module';
import { FirebaseModule } from '../firebase/firebase_module';
import { LiveModule } from '../live/live_module';
import { PipesModule } from '../pipes/pipes_module';
import { PlayerModule } from '../player/player_module';
import { MamApiInterceptor } from '../services/mam_api_interceptor';
import { ServicesModule } from '../services/services.module';
import { SharedModule } from '../shared/shared_module';
import {TransferMonitorModule} from '../transfer_monitor/transfer_monitor_module';
import { UiTableModule } from '../ui/ui_table.module';

import { AssetsSkeleton } from './assets-skeleton/assets-skeleton.component';
import { CboListDisplayComponent } from './cbo-list-display/cbo-list-display.component';
import { ClipBinPreview } from './clip-bin-preview/clip-bin-preview';
import { ClipBinSection } from './clip-bin-section/clip-bin-section.component';
import { ResourceService } from './clip-bin-section/service/resource.service';
import { ClipPreviewComponent } from './clip-preview/clip-preview.component';
import { EmptyAssetsCard } from './empty-assets-card/empty-assets-card.component';
import { FolderContentComponent } from './folder-content/folder-content.component';
import { BreadcrumbComponent, BreadcrumbService } from './folders-preview/breadcrumb.component';
import {
  ClipBinFoldersPreview
} from './folders-preview/folders-preview.component';
import { Landing } from './landing';
import { RecentAsset } from './recent-asset';
import { SearchListView } from './search-list-view/search-list-view';
import { SearchResults } from './search-results/search-results';
import { SearchFacetGroup } from './search_facet_group';

@NgModule({
	declarations: [
		Landing,
		SearchResults,
		RecentAsset,
		ClipBinPreview,
    ClipPreviewComponent,
		ClipBinSection,
		SearchFacetGroup,
		AssetsSkeleton,
		EmptyAssetsCard,
    ClipBinFoldersPreview,
    BreadcrumbComponent,
    FolderContentComponent,
    CboListDisplayComponent,
    SearchListView
	],
	imports: [
    UiTableModule,
    AdminModule,
    BrowserAnimationsModule,
    CommonModule,
    FeatureFlagModule,
    FirebaseModule,
    FormsModule,
    MatCardModule,
    LayoutModule,
    LiveModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    PipesModule,
    PlayerModule,
    ReactiveFormsModule,
    RouterModule,
    ServicesModule,
    SharedModule,
    TransferMonitorModule
  ],
  exports: [
		RecentAsset,
		ClipBinPreview,
    ClipPreviewComponent,
		ClipBinSection,
		SearchResults,
		SearchFacetGroup,
		AssetsSkeleton,
		EmptyAssetsCard,
    CboListDisplayComponent
	],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MamApiInterceptor,
      multi: true,
    },
    ResourceService,
    BreadcrumbService,
  ],
})
export class LandingModule {}
