<h1 mat-dialog-title class="dialog__title--size">New Clip Bin</h1>
<div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" mat-dialog-content>
    <p class="error" *ngIf="hasFolderInsideError$ | async">
        Unable to create clip bin: This folder has a folder inside.
    </p>
    <p class="error" *ngIf="isAnotherOwnedFolder">
        Unable to create clip bin: You can only create clip bins within your own folders.
    </p>
    <mat-form-field class="dialog__input--spacing" appearance="outline" subscriptSizing="dynamic">
        <input
            placeholder="Name"
            matInput
            cdkFocusInitial
            #input
            (keydown.enter)="onEnter(input)"
            autocomplete="off"
            [disabled]="processing || isAnotherOwnedFolder || (hasFolderInsideError$ | async)"
        />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mam-firebase-ga="Cancel create bin dialog" mat-button mat-dialog-close>Cancel</button>
    <button
        mat-button
        mam-firebase-ga="Confirm create bin dialog"
        [disabled]="!input.value.trim() || processing || isAnotherOwnedFolder || (hasFolderInsideError$ | async)"
        class="primary"
        #primary
        (click)="createClick(input)"
    >
        Create
    </button>
</div>
