import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, CurrencyPipe, DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BestSeekUrlPipe } from '../pipes/best_seek_url_pipe';
import { BytesPipe } from '../pipes/bytes_pipe';
import { DurationOrTimePipe } from '../pipes/duration_or_time_pipe';
import { DurationPipe } from '../pipes/duration_pipe';
import { FolderStateToExportStatusPipe } from '../pipes/folder_state_to_export_status';
import { PipesModule } from '../pipes/pipes_module';
import { SpacedPipe } from '../pipes/spaced_pipe';
import { TimeAgoPipe } from '../pipes/time_ago_pipe';
import { TzDatePipe } from '../pipes/tzdate_pipe';

import { ResizableDirective } from './resizable/resizable.directive';
import { DeleteViewDialog } from './ui-table-view-delete-dialog/delete-view-dialog';
import { UiTableViewNotFoundDialog } from "./ui-table-view-not-found-dialog/ui-table-view-not-found-dialog";
import { UpdateViewDialog } from './ui-table-view-update-dialog/update-view-dialog';
import { ComponeUiTableViewsDialogComponent } from './ui-table-views-dialog/ui-table-views-dialog.component';
import { ComponeUiTableViewsShareDialogComponent } from './ui-table-views-share-dialog/ui-table-views-share-dialog.component';
import { UiTableComponent } from './ui_table';
import { UiTableStyleDirective } from './ui_table.directive';
import { UiTablePipe } from './ui_table.pipe';

@NgModule({
  declarations: [
    UiTableComponent,
    DeleteViewDialog,
    UpdateViewDialog,
    ComponeUiTableViewsDialogComponent,
    ComponeUiTableViewsShareDialogComponent,
    UiTableViewNotFoundDialog,
    UiTableStyleDirective,
    ResizableDirective,
    UiTablePipe,
  ],
  imports: [
    CommonModule,
    MatBadgeModule,
    CdkTableModule,
    DragDropModule,
    CdkMenuTrigger,
    CdkMenu,
    MatButtonModule,
    CdkMenuItem,
    MatDividerModule,
    MatRippleModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTooltipModule,
    ObserversModule,
    PipesModule,
    MatIconModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    PortalModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatTableModule
  ],
  exports: [
    UiTableComponent
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    TitleCasePipe,
    UpperCasePipe,
    BytesPipe,
    DurationPipe,
    DurationOrTimePipe,
    BestSeekUrlPipe,
    SpacedPipe,
    TimeAgoPipe,
    TzDatePipe,
    FolderStateToExportStatusPipe,
  ]
})
export class UiTableModule {}
