import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';

import { ResourceAccessUser } from 'access_management/models/access_management.model';
import { AccessManagementService } from 'access_management/services/access_management.service';
import { AuthService } from 'auth/auth_service';
import { SnackBarService } from 'services/snackbar_service';

@Component({
  selector: 'mam-access-details-asset-dialog',
  templateUrl: './access-details-asset-dialog.component.html',
  styleUrl: './access-details-asset-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDetailsAssetDialog implements OnInit {
  options: ResourceAccessUser[] = [];
  resourceWithAccess: ResourceAccessUser[] = [];
  searchEmail = new FormControl<string>('');
  filteredOptions$!: Observable<Partial<ResourceAccessUser>[]>;

  constructor(
    readonly dialog: MatDialog,
    readonly snack: SnackBarService,
    readonly authService: AuthService,
    public readonly cdr: ChangeDetectorRef,
    readonly accessManagementService: AccessManagementService,
    readonly dialogRef: MatDialogRef<AccessDetailsAssetDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { documentId: string, permissions: ResourceAccessUser[] }
  ) {}

  ngOnInit(): void {
    this.resourceWithAccess = this.data?.permissions ?? [];
    this.getUsersEmail();
  }

  private getUsersEmail() {
    this.accessManagementService.getIasUsers()
      .subscribe({
        next: (options) => {
          this.options = options.filter(
            (value, index, self) =>
              index === self.findIndex((obj) => obj.email === value.email)
          );

          this.filteredOptions$ = this.searchEmail.valueChanges.pipe(
            startWith(''),
            map((value) => this.filterOptions(value ?? '')),
          );
        }
      });
  }

  private filterOptions(value: string): ResourceAccessUser[] {
    const filterValue = value.toLowerCase();

    return this.options
      .filter(option =>
        option.email.toLowerCase().includes(filterValue)
        || option.displayName.toLowerCase().includes(filterValue)
        && !this.resourceWithAccess.some(p => p.email === filterValue)
      );
  }

}

