<div class="panel-header">
    <!-- Select bin owner type (mine or all) -->
    <mam-clip-bin-owner-selector
        *ngIf="clipbinsOwner$ | async as clipbinsOwner"
        [owner]="clipbinsOwner"
        (ownerChange)="clipbinsOwner$.next($event)"
    >
    </mam-clip-bin-owner-selector>

    <div class="actions-button-container">
        <ng-container *ngIf="clips$ | async as clips">
            <ng-container *ngIf="clips.length;">
                <mam-bulk-clips-actions
                    class="actions-button-content"
                    [clipbin]="selectedBin.value"
                    [visibleClips]="clips"
                    [multiSelection]="multiSelection"
                >
                </mam-bulk-clips-actions>
            </ng-container>
        </ng-container>

        <!-- Create new bin -->
        <button
            id="create-bin"
            class="panel-header__create-bin"
            mat-stroked-button
            mam-firebase-ga="Create clipbin from persistent panel"
            (click)="openCreateBin()"
        >
            <mat-icon inline="true">add</mat-icon>
            <span class="hide-new-text">New</span>
            <span> Bin</span>
        </button>
    </div>
</div>

<ng-container *ngIf="{currentAsset: stateService.currentAsset$ | async} as tplState">
    <div class="panel-body">
        <!-- Dark Overlay while waiting for reorder api -->
        <div [class.disabled-drag-and-drop]="disableDragAndDrop"></div>

        <!-- Clipbin selection dropdown -->
        <mat-form-field class="clip-bin-select-form panel-padded" appearance="outline" subscriptSizing="dynamic">
            <mat-select
                panelClass="clip-bin-panel"
                [formControl]="selectedBin"
                [compareWith]="compareBins"
                mam-firebase-ga="Open clipbin selection from details panel"
                disableOptionCentering
                [placeholder]="(loadingBins$ | async) ? 'Loading...' : 'Choose a clipbin'"
                (openedChange)="onSelectOpenedChanged($event)"
                hideSingleSelectionIndicator
            >
                <!-- Customized the display for selected item -->
                <mat-select-trigger
                    *ngIf="selectedBin.value"
                    [title]="selectedBin.value.name + ' (' + selectedBin.value.directChildrenCount + ')'"
                >
                    {{ selectedBin.value.name }}
                    <span class="dropdown-clips-count" *ngIf="selectedBin.value">
                        <ng-container *ngTemplateOutlet="clipsCount; context: {clipbin: selectedBin.value}">
                        </ng-container>
                    </span>
                </mat-select-trigger>

                <!-- search box -->
                <mat-form-field class="clip-bin-search-form" appearance="outline">
                    <mat-icon matPrefix>search</mat-icon>
                    <!-- The (keydown) prevents auto-closure of the dropdown -->
                    <input
                        #searchInput
                        matInput
                        [formControl]="binsSearchControl"
                        placeholder="Search clip bins"
                        aria-label="Search clip bins"
                        autocomplete="off"
                        (keydown)="$event.stopPropagation()"
                    />
                </mat-form-field>

                <div
                    infinite-scroll
                    (nearBottom)="onNearBottomForBins()"
                    #binsScrollableView
                    class="scrollable-options"
                >
                    <!-- Dropdown select options -->
                    <mat-option
                        *ngFor="let clipbin of (clipbins$ | async); trackBy: trackName"
                        mam-firebase-ga="Select clip bin from details panel"
                        [mam-firebase-resource]="clipbin.iasId"
                        [value]="clipbin"
                    >
                        <ng-container *ngTemplateOutlet="clipbinSelection; context: {clipbin: clipbin}"> </ng-container>
                    </mat-option>

                    <!-- Extra hidden option to preserve selection on search with no results -->
                    <mat-option hidden [value]="extraBinOption"></mat-option>

                    <!-- Visual placeholder in case of zero bins at all or searched -->
                    <mat-option class="no-clipbin-placeholder" *ngIf="(clipbins$ | async)?.length === 0">
                        No clip bins found.
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="selectedBin.value">
            <div class="clips-search-bar-container">
                <!-- Sort/Unsort buttons -->
                <button
                    *ngIf="sortState === 0 && enableClipsSortFF"
                    mat-icon-button
                    class="unsort-button"
                    aria-label="Unsort the clips"
                    matTooltip="Unsort the clips"
                    mam-firebase-ga="Unsort the clips"
                    (click)="handleUnsortedClips()"
                >
                    <mat-icon>cancel</mat-icon>
                </button>
                <button
                    *ngIf="sortState === 1 && enableClipsSortFF"
                    mat-icon-button
                    class="partial-sort-button"
                    aria-label="Sort the partially sorted clips"
                    matTooltip="Sort the partially sorted clips"
                    mam-firebase-ga="Sort the partially sorted clips"
                    (click)="handleSortedClips()"
                >
                    <mat-icon>sort</mat-icon>
                </button>
                <button
                    *ngIf="sortState === 2 && enableClipsSortFF"
                    mat-icon-button
                    class="sort-button"
                    aria-label="Sort the clips"
                    matTooltip="Sort the clips"
                    mam-firebase-ga="Sort the clips"
                    (click)="handleSortedClips()"
                >
                    <mat-icon>sort</mat-icon>
                </button>
                <!-- Sort button placeholder; will be removed when sort feature will be tested/accepted (on FF removal) -->
                <div [hidden]="enableClipsSortFF" style="width: 16px"></div>

                <!-- Clips search bar -->
                <div class="clips-search-bar">
                    <input
                        type="text"
                        placeholder="Search clips"
                        aria-label="Search clips"
                        autocomplete="off"
                        [formControl]="clipsSearchControl"
                    />
                    <div class="search-controls">
                        <button
                            mat-icon-button
                            aria-label="Refresh clips"
                            mam-firebase-ga="Refresh clips"
                            (click)="refreshClips()"
                        >
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <!-- List of clips of a selected clip bin -->
            <ng-container *ngIf="clips$ | async as clips">
                <ng-container *ngIf="clips.length; else displayEmptyClips">
                    <div
                        infinite-scroll
                        (nearBottom)="onNearBottomForClips()"
                        #scrollView
                        class="panel-scrollview"
                        [class.multi-selection-started]="multiSelection.size > 0"
                        cdkDropList
                        cdkDropListAutoScrollStep="6"
                        (cdkDropListDropped)="dragAndDropClip($event)"
                    >
                        <!-- Clip item -->
                        <div
                            *ngFor="let clip of clips; let idx = index; trackBy: trackName"
                            class="row-container"
                            [class.active]="clip.name === tplState.currentAsset?.name"
                            [cdkDragDisabled]="disableDragAndDrop || sortState !== 2"
                            [cdkDragStartDelay]="dragStartDelay"
                            (cdkDragStarted)="clipDragging = true"
                            cdkDragLockAxis="y"
                            cdkDragBoundary=".panel-scrollview"
                            cdkDrag
                        >
                            <div class="dragDropPlaceholder" *cdkDragPlaceholder></div>
                            <mat-card
                                #clipCard
                                [id]="clip.name"
                                appearance="outlined"
                                class="clip-area"
                                [class.multi-selected]="multiSelection.has(clip.name)"
                                [routerLink]="['/clipbin', selectedBin.value.iasId, 'clip', clip.name]"
                                [queryParams]="{'type': 'bin'}"
                                queryParamsHandling="merge"
                                (click)="clickClipCard(clip)"
                            >
                                <div class="multi-selector" *featureFlagOn="'use-clips-selection'">
                                    <mat-checkbox
                                        color="primary"
                                        class="multi-selector__checkbox"
                                        (click)="toggleSelection(clips, clip, multiSelection, $event.shiftKey); $event.stopPropagation();"
                                        [checked]="multiSelection.has(clip.name)"
                                    >
                                    </mat-checkbox>
                                </div>
                                <div class="clip-thumbnail">
                                    <ng-container *featureFlagOn="'enable-access-management'">
                                        <mam-access-restriction-icon
                                            matTooltip="Restricted"
                                            class="thumbnail-restricted-icon"
                                            *ngIf="clip.permissions"
                                            size="medium"
                                        ></mam-access-restriction-icon>
                                    </ng-container>
                                    <mam-asset-thumbnail [asset]="clip"></mam-asset-thumbnail>
                                </div>
                                <div class="clip-titles">
                  <h3 class="clip-titles__title" [attr.title]="clip.title">{{ clip.title }}</h3>
                                    <div class="clip-subtitle-row">
                                        <p class="clip-titles__duration" *ngIf="clip.duration != null">
                                            {{ clip.duration | duration }}
                                        </p>

                                        <!-- Buttons -->
                                        <button
                                            *ngIf="clip.name === tplState.currentAsset?.name && trimEnabled && enableClipTrimFF"
                                            class="trim-clip-button"
                                            mat-icon-button
                                            mam-firebase-ga="Trim clip"
                                            [mam-firebase-resource]="clip.name"
                                            (click)="openTrimClip(clip)"
                                        >
                                            <mat-icon>content_cut</mat-icon>
                                        </button>
                                        <div
                                            class="selected_track"
                                            mam-firebase-ga="Audio track selected"
                                            *ngIf="changedTracks[idx] > -1 && !(clip.name === tplState.currentAsset?.name && trimEnabled)"
                                        >
                                            <mat-icon class="selected_track__icon">audiotrack</mat-icon>
                                            <span class="selected_track__number">{{ changedTracks[idx] + 1 }}</span>
                                        </div>
                                        <mam-asset-type-icon [asset]="clip" class="not-clickable"></mam-asset-type-icon>
                                        <mam-asset-location-status
                                            [asset]="clip"
                                            [binTitle]="selectedBin.value.name"
                                            class="not-clickable"
                                        >
                                        </mam-asset-location-status>
                                        <button
                                            (click)="$event.stopPropagation()"
                                            mat-icon-button
                                            class="clip-button"
                                            aria-label="Clip Actions"
                                            mam-firebase-ga="Open clip actions from details panel"
                                            [mam-firebase-resource]="clip.name"
                                            [matMenuTriggerFor]="menuClipActions"
                                        >
                                            <mat-icon>more_vert</mat-icon>
                                        </button>

                                        <!-- Clip item menu -->
                                        <mat-menu #menuClipActions="matMenu" class="clip-options-menu">
                                            <button
                                                [disabled]="clip.permissions"
                                                class="clip-bin__mat-item--spacing"
                                                *ngIf="isVideoShareable(clip)"
                                                mat-menu-item
                                                mam-firebase-ga="Share clip from details panel"
                                                [mam-firebase-resource]="clip.name"
                                                (click)="shareVideo(clip)"
                                            >
                                                Share {{ clip.original.duration.toFixed(0) === clip.duration.toFixed(0)
                                                ? "video" : "clip" }}
                                            </button>
                                            <button
                                                class="clip-bin__mat-item--spacing"
                                                mat-menu-item
                                                mam-firebase-ga="Open rename clip dialog from details panel"
                                                [mam-firebase-resource]="clip.name"
                                                (click)="openRenameClip(clip.name, clip.title)">
                                                Rename
                                            </button>
                                            <button
                                                class="clip-bin__mat-item--spacing"
                                                mat-menu-item
                                                mam-firebase-ga="Trigger clip copy from details panel"
                                                [mam-firebase-resource]="clip.name"
                                                (click)="requestClipMove(clip, 'copy')"
                                            >
                                                Copy to
                                            </button>
                                            <button
                                                class="clip-bin__mat-item--spacing"
                                                mat-menu-item
                                                mam-firebase-ga="Trigger clip move from details panel"
                                                [mam-firebase-resource]="clip.name"
                                                (click)="requestClipMove(clip, 'move')"
                                            >
                                                Move to
                                            </button>
                                            <div
                                                [matTooltip]="disabledExportTooltip"
                                                [matTooltipDisabled]="!isExportDisabled(clip)"
                                            >
                                                <button
                                                    class="clip-bin__mat-item--spacing"
                                                    [disabled]="isExportDisabled(clip)"
                                                    mat-menu-item
                                                    mam-firebase-ga="Open export clip dialog"
                                                    [mam-firebase-resource]="clip.name"
                                                    (click)="openExportClipDialog(clip)"
                                                >
                                                    Export
                                                </button>
                                            </div>
                                            <button
                                                mat-menu-item
                                                class="delete-button clip-bin__mat-item--spacing"
                                                mam-firebase-ga="Delete clip from details panel"
                                                [mam-firebase-resource]="clip.name"
                                                (click)="openDeleteClip(clip.name)"
                                            >
                                                Remove clip
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </ng-container>
                <mam-bulk-clips-actions
                    class="mam-bulk-clips-actions-content"
                    [clipbin]="selectedBin.value"
                    [visibleClips]="clips"
                    [multiSelection]="multiSelection"
                    [hasAudioChanges]="hasAudioChanges"
                >
                </mam-bulk-clips-actions>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<!-- No clipbins exist -->
<ng-container *ngIf="hasClipBins === false">
    <div class="empty-clip-bins">
        <div class="empty-logo">
            <img alt="Empty Clip Bins" src="images/empty_contents_logo.svg" />
        </div>
        <h1>No clip bins</h1>
        <h2>Please create a clip bin using the top right "+" button</h2>
    </div>
</ng-container>

<ng-template #clipbinSelection let-clipbin="clipbin">
    <div *ngIf="clipbin" class="clip-bin-option">
        <div class="clipbin-logo">
            <img src="images/clipbins_option_icon.svg" alt="Clipbins Option logo" />
        </div>
        <div class="clipbin-titles">
            <span class="name" [attr.name]="clipbin.name"> {{ clipbin.name }} </span>
            <span class="clips-count" *ngIf="clipbin">
                <ng-container *ngTemplateOutlet="clipsCount; context: {clipbin: clipbin}"> </ng-container>
            </span>
        </div>
    </div>
    <mat-divider></mat-divider>
</ng-template>

<ng-template #clipsCount let-clipbin="clipbin">
    <ng-container [ngPlural]="+clipbin.directChildrenCount">
        <ng-template ngPluralCase="=1">1 clip</ng-template>
        <ng-template ngPluralCase="other">{{ clipbin.directChildrenCount }} clips</ng-template>
    </ng-container>
</ng-template>

<!-- No clips exist or found. -->
<ng-template #displayEmptyClips>
    <div class="empty-clips">
        <h3 *ngIf="loadingClips$ | async">Loading...</h3>
        <h3 *ngIf="(loadingClips$ | async) === false">No clips found or added to this clip bin.</h3>
    </div>
</ng-template>
