import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth/auth_service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { Resource, ResourceResult, ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { SnackBarService } from '../../services/snackbar_service';

/** Clipbin rename dialog */
@Component({
  selector: 'mam-move-folder-dialog',
  templateUrl: './clipbin_folder_move_dialog.ng.html',
  styleUrl: './clipbin_folder_move_dialog.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinFolderMoveDialog implements OnInit, OnDestroy {
  private readonly destroyed$ = new ReplaySubject<void>(1);
  resources$ = new BehaviorSubject<Resource[]>([]);
  selectedFolder$ = new BehaviorSubject<Resource | null>(null);
  selectedFolder: Resource | null = null;

  isNestedFolder: boolean = false;
  selectedNestedFolder: Resource | null = null;

  isLoading: boolean = false;

  keyEnabled = true;

  isMaximumLevelError$ = new BehaviorSubject<boolean>(false);
  hasClipBinInside$ = new BehaviorSubject<boolean>(false);

  static readonly dialogOptions = { hasBackdrop: true };

  constructor(
    readonly dialogRef: MatDialogRef<ClipBinFolderMoveDialog>,
    private readonly snackBar: SnackBarService,
    private readonly resourceService: ResourceService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Resource) {
  }

  ngOnInit(): void {
    this.getResources();

    this.selectedFolder$.pipe(takeUntil(this.destroyed$)).subscribe(folder => {
      this.selectedFolder = folder;
      this.selectedNestedFolder = folder && folder.breadcrumb && folder.breadcrumb.length > 0 ? folder : null;
      this.cdr.markForCheck();
    });
  }

  getResources() {
    this.isLoading = true;
    this.isNestedFolder = false;

    this.isMaximumLevelError$.next(false);
    this.hasClipBinInside$.next(false);
    this.resourceService.getResource(ResourceTypes.FOLDER,
      { limit: 100, offset: 0 },
      { owner: this.authService.getUserEmail(), searchTerm: '' },
      false,
      true
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (resources) => {
          const result = resources as ResourceResult;
          this.resources$.next(result.folders.filter(folder => folder.id !== this.data.id && folder.level === 0));
        },
        complete: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
  }

  getNestedResources(folder: Resource) {
    this.isLoading = true;
    this.isMaximumLevelError$.next(false);
    this.hasClipBinInside$.next(false);
    if (folder && folder.id) {
      this.resourceService.getResourceChildren(ResourceTypes.FOLDER,
        folder.id,
        { limit: 100, offset: 0 },
        false,
        true
      )
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (result) => {
            this.isNestedFolder = true;
            this.selectedNestedFolder = result.parent as unknown as Resource;

            this.checkForMaximumDepth(this.data, folder);

            const hasClipBinInside = result.parent.children.findIndex((res) => res.type !== 'folder');
            if(hasClipBinInside > -1){
              this.hasClipBinInside$.next(true);
            }

            this.resources$.next(result.parent.children);
          },
          error: () => {
            this.isNestedFolder = false;
            this.selectedNestedFolder = null;
          },
          complete: () => {
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        });
    }
  }

  checkForMaximumDepth(sourceFolder: Resource, targetFolder: Resource, updateState: boolean = true) {
    let isAboveMaximum = false;

    //VALID SCENARIO UP 2 NESTED LEVELS FROM TOP LEVEL;

    if(sourceFolder.subTreeDepth === 2 || targetFolder.level === 2)
      isAboveMaximum = true;

    if((sourceFolder.level === 0 || sourceFolder.level === 1) && sourceFolder.subTreeDepth === 1 && targetFolder.level >= 1)
      isAboveMaximum = true;

    if(updateState) this.isMaximumLevelError$.next(isAboveMaximum);
    return isAboveMaximum;
  }

  selectFolder(folder: Resource) {
    this.isMaximumLevelError$.next(false);

    this.checkForMaximumDepth(this.data, folder);

    if (!this.isMaximumLevelError$.value && folder.directChildrenCount && folder.directChildrenCount > 0) {
      this.getNestedResources(folder);
    }

    this.selectedFolder$.next(folder);
  }

  moveButtonDisableCondition() {
    return !this.selectedFolder$.value || !this.keyEnabled || this.isMaximumLevelError$.value || this.hasClipBinInside$.value;
  }

  moveFolderToSelected() {
    this.keyEnabled = false;

    const selectedFolder = this.selectedFolder$.value;
    if (this.data.id && selectedFolder && selectedFolder.id) {
      this.resourceService.moveResource(ResourceTypes.FOLDER, this.data.id, selectedFolder.id)
      .subscribe({
        next: (result) => {
          this.snackBar.message('Folder has been moved successfully.');
          this.router.navigate(['/folders', result.parent.id]);
          this.dialogRef.close();
        },
        error: (error) => {
          this.snackBar.error(
            'Folder could not be moved.', undefined, error);
          this.keyEnabled = true;
        },
      });
    }
  }

  backClicked() {
    this.selectedFolder$.next(null);
    this.getResources();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
