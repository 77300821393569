import { Injectable } from '@angular/core';
import { asyncScheduler, BehaviorSubject, concatMap, Observable, of, scheduled, take, tap } from 'rxjs';

import { ClipBinsInfo, ClipBinsItem } from 'clip_bins/models/clip_bin.model';
import { ClipBinsInfoService } from 'clip_bins/services/clipbins_info.service';
import { Clip } from 'services/asset_service';
import { StateService } from 'services/state_service';
import { StorageLocalService } from 'services/storage/storage_local.service';

import { ClipLocalStorage, IClipBinStorage } from '../../models/storage.model';

export interface IStorage {
	clips: ClipLocalStorage[];
	ownerId: string;
  clipBinsId: string;
}

export const INITIAL_STATE: IStorage = {
	clips: [],
	ownerId: '',
  clipBinsId: ''
};

@Injectable({
	providedIn: 'root'
})
export class ClipbinStorageService extends StorageLocalService<IClipBinStorage> {
	override store$: BehaviorSubject<IClipBinStorage> = new BehaviorSubject<IClipBinStorage>(INITIAL_STATE);

	override key = 'CLIP_BIN_SHARING';

	clipbinOwner: string = this.store$.getValue().ownerId;

	clipsList: ClipLocalStorage[] = this.store$.getValue().clips;
  clipBinsId: string | undefined = '';

	constructor(
    private readonly stateService: StateService,
    private readonly clipBinsInfoService: ClipBinsInfoService
  ) {
		super();
		this.store$.subscribe((clipbin) => {
			this.clipbinOwner = clipbin.ownerId;
			this.clipsList = clipbin.clips;
		});

    this.stateService.persistentBinName$.subscribe((clipBinsId) => {
      this.clipBinsId = clipBinsId;
    });
	}

	init() {
		this.set<IStorage>(INITIAL_STATE);
	}

	setClipBinSharing(clipbin: IClipBinStorage) {
    clipbin.clips.forEach((clip) => clip.initialLoad = true);
		this.set(clipbin);
	}

  /** Update clips into clipbins store on firebase */

	updateClip(clip: ClipLocalStorage) {
    clip.initialLoad = false;
    return this.updateClipList(clip).pipe(concatMap(() =>
      scheduled(this.clipBinsInfoService
        .updateClipsList(this.clipBinsId as string, this.clipsList as ClipBinsItem[]), asyncScheduler)
    ));
	}

  updateClipInBin(clip: Clip, trackIndex: number) {
    const clipBinName = clip.label;
    const trackIndexes = new Map<string, number>([[clip.name, trackIndex]]);
    this.updateClipsInBin([clip], trackIndexes, clipBinName);
  }

  updateClipsInBin(clips: Clip[], trackIndexes: Map<string, number>, clipBinName: string) {
    this.clipBinsInfoService.getIASClipBins(clipBinName, true)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.length) {
          this.updateClipBinsInfo(response[0], clips, trackIndexes);
        } else {
          this.createClipBinsInfo(clipBinName, clips, trackIndexes);
        }
      });
  }

  private updateClipBinsInfo(clipBinsInfo: ClipBinsInfo, clipsToUpdate: Clip[], trackIndexes: Map<string, number>) {
    const clips = clipBinsInfo.clips || [];
    clipsToUpdate.forEach(clipToUpdate => {
      const trackIndex = trackIndexes.get(clipToUpdate.name);
      if (trackIndex === undefined) {
        return;
      }

      const idx = clips.findIndex((clip) => clip.name === clipToUpdate.name);
      if (idx !== -1) {
        clips[idx].audioTrack.current = trackIndex;
      } else {
        const clipBinsItem = this.createClipBinsItem(clipToUpdate, trackIndex);
        clips.push(clipBinsItem);
      }
    });
    this.clipBinsInfoService.updateClipBins(clipBinsInfo.id, clips);
  }

  private createClipBinsInfo(clipBinName: string, clipsToUpdate: Clip[], trackIndexes: Map<string, number>) {
    const clips: ClipBinsItem[] = [];
    clipsToUpdate.forEach(clipToUpdate => {
      const trackIndex = trackIndexes.get(clipToUpdate.name);
      if (trackIndex === undefined) {
        return;
      }

      const clipBinsItem = this.createClipBinsItem(clipToUpdate, trackIndex);
      clips.push(clipBinsItem);
    });
    this.clipBinsInfoService.createClipBins(clipBinName, clips);
  }

  private createClipBinsItem(clip: Clip, trackIndex: number): ClipBinsItem {
    return {
      name: clip.name,
      title: clip.title,
      audioTrack: {
        current: trackIndex,
        previous: -1,
      },
    };
  }

  private updateClipList(clip: ClipLocalStorage):Observable<ClipLocalStorage> {
    if (this.clipsList.some((item) => item.name === clip.name)) {
			const idx = this.clipsList.findIndex((item) => item.name === clip.name);
			this.clipsList[idx] = clip;
			return this.update({ clips: this.clipsList, ownerId: this.clipbinOwner, clipBinsId: this.clipBinsId });
		}
		const updatedClipList = this.clipsList.concat(clip);
		return this.update({ clips: updatedClipList, ownerId: this.clipbinOwner, clipBinsId: this.clipBinsId });
  }

	getClip(clipName: string): ClipLocalStorage | undefined {
		return this.clipsList.find((item) => item.name === clipName);
	}

	removeClip(clipId: string) {
		const idx = this.clipsList.findIndex((item) => item.name === clipId);
		if (idx === -1) return of();
		this.clipsList.splice(idx, 1);
		return this.update({ clips: this.clipsList, ownerId: this.clipbinOwner, clipBinsId: this.clipBinsId  })
    .pipe(tap(()=> scheduled(this.clipBinsInfoService
      .updateClipsList(this.clipBinsId as string, this.clipsList as ClipBinsItem[]), asyncScheduler)));
	}

  removeClips(clipsToRemove: string[]) {
    const clips = this.clipsList;
    clipsToRemove.forEach(clipToRemove => {
      const index = clips.findIndex((item) => item.name === clipToRemove);
      if (index !== -1) {
        clips.splice(index, 1);
      }

    });
    this.clipBinsInfoService.updateClipBins(this.clipBinsId as string, clips as ClipBinsItem[]);
  }

	updateOwner(owner: string) {
		this.set({ clips: this.clipsList, ownerId: owner,clipBinId: this.clipBinsId  });
	}

	/** Set item with initial state */
	override clear(): void {
		this.set(INITIAL_STATE);
		this.store$.next(INITIAL_STATE);
	}

	/** Delete item from local storage */
	override delete(): void {
		localStorage.removeItem(this.key);
	}
}

