import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { catchError, filter, firstValueFrom, from, map, mergeMap, Observable, of, Subject, switchMap, take, takeUntil, toArray } from "rxjs";

import { isErrorResponse } from '../error_service/error_response';
import { AssetService, Clip, Original } from '../services/asset_service';
import { DashBoardService } from "../services/dashboard.service";
import { Pagination } from '../services/pagination_service';
import { SnackBarService } from '../services/snackbar_service';

import { AdminMetricsChart } from './admin_metrics_chart';
import { AssetKeys, topAsset } from './dashboard/dashboard.models';



const TITLES:{[key:string]: string} = {
  topClippedAssets: 'Assets Clipped',
  topDownloadAssets: 'Assets Downloaded',
  topDownloadedSubclips: 'Subclips Downloaded',
  topSharedAssets: 'Assets with most shared links',
  topExportedFullAssets: 'Full Video Assets Exported',
  topExportedSubclips: 'Subclips Exported',
  topImportedPremierTitle : 'Title Imported in Premiere',
  topViewedAssets: 'Most Viewed Assets',
};

@Component({
  selector: 'mam-admin-metrics-list',
  templateUrl: './admin_metrics_list.ng.html',
  styleUrls: ['./admin_metrics_list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminMetricsList  implements OnDestroy, OnInit {
  private destroy$:Subject<boolean> = new Subject<boolean>();

  private cacheDuration = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
  protected TITLES = TITLES;
  protected AssetKeys = AssetKeys;
  protected iterableAssetKeys = Object.keys(AssetKeys); // for template only

  protected assetsMetrics: {[key:string]:topAsset[]|null} = {};
  protected paginations:{[key:string]:Pagination<topAsset>}={};

  protected readonly nlqSearchControl = new FormControl<string>('');
  protected nlqDataLoading = false;
  protected nlqChartData?: {y: string, x: number}[];
  protected nlqTextData?: string;


  @ViewChild(AdminMetricsChart) private metricsChart?: AdminMetricsChart;


  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly snackbar: SnackBarService,
    private dashBoardService:DashBoardService,
    private assetService:AssetService,
  ){}

  async readMetadata(nlqData: topAsset[]) {
    for(const topAsset of nlqData) {
      // TODO only asset_name should left
      const assetName = topAsset.asset_name || topAsset.resource || topAsset.resource_name || '';
      if (assetName.includes('assets')) {
        const assetObservable = this.assetService.getAsset(assetName)
            .pipe(catchError(() => of(null)));
        const original = await firstValueFrom(assetObservable);
        if (original) {
          topAsset.original = original;
          topAsset.url = original.name;
        }
      }
      if (assetName.includes('clips')) {
        const clipObservable = this.assetService.getClip(assetName);
        const clip = await firstValueFrom(clipObservable);
        if (!isErrorResponse(clip)) {
          topAsset.clip = clip;
          topAsset.url = clip.original.name;
        }
      }
    }
  }

  nlqClear() {
    this.nlqSearchControl.reset();
  }

  nlqReset() {
    this.nlqSearchControl.reset();
    this.nlqChartData = undefined;
    this.nlqTextData = undefined;
  }

  nlqSearch() {
    const query = this.nlqSearchControl.value;
    if (!query) {
      return;
    }

    this.nlqDataLoading = true;
    this.nlqChartData = undefined;
    this.nlqTextData = undefined;
    this.cdr.markForCheck();

    this.dashBoardService.fetchNlq(query)
        .pipe(
            take(1),
            catchError(err => {
              console.error('Error calling NLQ endpoint', err);
              return of(null);
            }),
        )
        .subscribe(async response => {
          if (!response || !('queryResults' in response)) {
            this.snackbar.error('Failed to get data for dashboard generation');
          } else {
            const queryResults = response['queryResults'];
            if (Array.isArray(queryResults)) {
              const data: {y: string, x: number}[] = [];
              if (queryResults.length) {
                const firstItem = queryResults[0];
                const fields = Object.keys(firstItem);
                if (fields.length === 1) {
                  this.nlqTextData = firstItem[fields[0]];
                } else {
                  // TODO only asset_name should left
                  if (firstItem.asset_name || firstItem.resource || firstItem.resource_name) {
                    const nlqData = queryResults.slice(0, 10);
                    await this.readMetadata(nlqData);
                    nlqData.forEach((nlqItem) => data.push({
                      y: nlqItem.original?.title || nlqItem.clip?.title,
                      x: nlqItem.event_count || nlqItem.resource_count || nlqItem.download_count || nlqItem.export_count,
                    }));
                  }
                }
              }
              this.nlqChartData = data;
            }
          }
          this.nlqDataLoading = false;
          this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    for (const assetKey in AssetKeys) {
      this.checkSummaryCache(
        assetKey as AssetKeys,
        ()=> this.dashBoardService.fetchAssetsList(assetKey as AssetKeys)
      );
    }
  }

  setPagination(list:topAsset[]|null,listName:string):void{
    if(!list){ return; }
    this.paginations[listName] = {
      pageIndex:0,
      pageSize:6,
      pagesCache:[],
      totalCount:list.length
    };
  }

  fillList(listName: AssetKeys,listValue:topAsset[]|null):void{
    this.setPagination(listValue,listName);
    this.assetsMetrics[listName] = listValue;
  }

  selectServiceObservable(summaryListKey: AssetKeys):Observable<object[]>{
    return this.dashBoardService.fetchAssetsList(summaryListKey);
  }

  checkSummaryCache(
    assetKey:AssetKeys,
    serviceObservable:()=>Observable<object[]>
  ):void{
    const cachedData = localStorage.getItem(assetKey);

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const now:number = Date.now();
      // Check if the cached data is still valid
      if (now - parsedData.timestamp < this.cacheDuration) {
        const cahedData = parsedData.data as topAsset[];
        this.fillList(assetKey,cahedData);
        this.cdr.markForCheck();
        return;
      }
    }
    this.fetchSummary(serviceObservable(),assetKey);
  }

  fetchSummary(summaryObservable:Observable<object[]>,assetKey:AssetKeys){
    summaryObservable.pipe(takeUntil(this.destroy$)).pipe(
      switchMap((object:object[]) => {
        const topAssets = object as topAsset[];
        const filteredTopAssets = topAssets.filter(topAsset => !!topAsset.asset_name);
        return from(filteredTopAssets);
      }),

      // Step 3: Process each object, fetch metadata, and combine with resource_count
      mergeMap((topAsset:topAsset) =>{
        const assetName = topAsset.asset_name || '';
        if(assetName.includes('assets')){
          return this.assetService.getAsset(assetName).pipe(
            filter((original): original is Original => original !== null),
            map((original:Original) => {
              topAsset.original = original;
              topAsset.url = original.name;
              return topAsset;
            }),
            catchError(err => {
              console.error('Error processing assets URL:', assetName, err);
              return of(null); // Handle errors gracefully
            })
          );
        }
        if(assetName.includes('clips')){
          return this.assetService.getClip(assetName).pipe(
            filter((metadata): metadata is Clip =>  metadata !== null),
            map((clip:Clip) => {
              topAsset.clip = clip;
              topAsset.url = clip.original.name;
              return topAsset;
            }),
            catchError(err => {
              console.error('Error processing clips URL:', assetName, err);
              return of(null); // Handle errors gracefully
            })
          );
        }
        return of(null);
      }),
      filter((metadata): metadata is topAsset => metadata !== null),

      toArray()
    ).subscribe({
      next: (results) => {
        if(!results){return;}
        results.sort((a, b) => {
          const aCount = a.event_count || 0;
          const bCount = b.event_count || 0;
          return bCount - aCount;
        });
        this.fillList(assetKey,results);
        localStorage.setItem(
          assetKey,
          JSON.stringify({ data: results, timestamp: Date.now() })
        );
        this.cdr.markForCheck();
      },
      error: (err) => {
        this.fillList(assetKey, []);
        this.cdr.markForCheck();
        console.error('An error occurred:', err);
        this.snackbar.error('Failed to get assets metrics');
      }
    });
  }

  refreshList(assetKey:string){
    const summaryListKey = assetKey as AssetKeys;
    const summaryObservable = this.selectServiceObservable(summaryListKey);
    this.fillList(summaryListKey,null);
    this.fetchSummary(summaryObservable,summaryListKey);
  }

  onPageChange(event: PageEvent,pagination:Pagination<topAsset>):void {
    pagination.pageIndex = event.pageIndex;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
