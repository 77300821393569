<span class="restriction-label">ASSET RESTRICTION</span>
<section id="accessRestrictionOption">
    <button
      [disabled]="disabled"
      class="btn-restrict__options" mat-button [matMenuTriggerFor]="menu" aria-label="Select the restriction">
        <div class="container__selected">
            <ng-container *ngIf="selectedOption ==='restrict'; else public">
                <mam-access-restriction-icon size="medium"></mam-access-restriction-icon>
                <span>Restricted</span>
            </ng-container>
            <ng-template #public>
                <mat-icon>public</mat-icon>
                <span>Public</span>
            </ng-template>
        </div>
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button
        [disabled]="disabled"
        (click)="openAccessManagement()"
        class="btn-manage-accounts"
        mat-button
        *ngIf="selectedOption === 'restrict'"
    >
        <mat-icon>manage_accounts</mat-icon>
        Manage Access
    </button>
</section>

<mat-menu #menu="matMenu">
    <button mat-menu-item class="custom-item--alignment" (click)="openRestrictDialog()">
        <mam-access-restriction-icon size="medium"></mam-access-restriction-icon>
        <span class="mat-mdc-menu-item-text"> Restrict </span>
    </button>
    <button #second mat-menu-item (click)="openPublicDialog()"><mat-icon>public</mat-icon> Public</button>
</mat-menu>
