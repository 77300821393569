import {DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {InjectionToken, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatOptionModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouterModule} from '@angular/router';

import { environment } from '../environments/environment';
import {ErrorService} from '../error_service/error_service';
import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {FirebaseFirestoreDataService} from '../firebase/firebase_firestore_data_service';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {RightPanelModule} from '../right_panel/right_panel_module';
import {FakeLocalUploadsTrackingService} from '../services/local_uploads_tracking_fake_service';
import {LocalUploadsTrackingService, STORAGE_FACTORY_METHOD} from '../services/local_uploads_tracking_service';
import {ResumableUploadService} from '../services/resumable_upload_service';
import {SnackBarService} from '../services/snackbar_service';
import {TransferService} from '../services/transfer_service';
import {ManColDirective, ManTableDirective} from '../shared/mam_table';
import { SharedModule } from '../shared/shared_module';
import {SortColBtnComponent} from '../shared/sort_col_btn.component';
import {UiTableModule} from '../ui/ui_table.module';

import { AssetDeletionTableLegacy } from './asset_deletion_legacy/asset_deletion_table_legacy';
import {AssetDeletionTable} from './asset_deletion_table';
import {CloudIngestDetails} from './cloud_ingest_details';
import {CloudIngestTable} from './cloud_ingest_table';
import {CutDownDetails} from './cut_down_details';
import {ExportMonitor} from './export_monitor';
import {ExportMonitorClipBinCell} from './export_monitor_clip_bin_cell';
import {LiveStagingTable} from './live_staging_table';
import {LocalUploadTable} from './local_upload_table';
import {MultiselectTableHeader} from './multiselect_table_header';
import {TransferDetails} from './transfer_details';
import {TransferLanding} from './transfer_landing';
import {TransferMonitor} from './transfer_monitor';
import {TransferTable} from './transfer_table';
import {VodStagingErrorTable} from './vod_staging_error_table';
import {VodStagingTable} from './vod_staging_table';

const useMockService = !environment.clientId;

@NgModule({
    declarations: [
        CloudIngestDetails,
        TransferMonitor,
        TransferTable,
        MultiselectTableHeader,
        TransferDetails,
        TransferLanding,
        CloudIngestTable,
        VodStagingTable,
        VodStagingErrorTable,
        LiveStagingTable,
        LocalUploadTable,
        AssetDeletionTable,
        AssetDeletionTableLegacy,
        CutDownDetails,
        ExportMonitor,
        ExportMonitorClipBinCell
    ],
    providers: [
        TransferService,
        {
            provide: LocalUploadsTrackingService,
            useFactory: getLocalUploadsTrackingService,
            deps: [
                Storage,
                ResumableUploadService,
                SnackBarService,
                ErrorService,
                Router,
                FirebaseFirestoreDataService,
                FeatureFlagService
            ]
        },
        {
            provide: Storage,
            useFactory: STORAGE_FACTORY_METHOD
        }
    ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        DragDropModule,
        MatPaginatorModule,
        FeatureFlagModule,
        FirebaseModule,
        MatOptionModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        PipesModule,
        ReactiveFormsModule,
        RightPanelModule,
        RouterModule,
        SharedModule,
        ManTableDirective,
        ManColDirective,
        SortColBtnComponent,
        UiTableModule,
        SharedModule,
        SharedModule
    ],
    exports: [
        CloudIngestDetails,
        TransferMonitor,
        TransferDetails,
        TransferLanding,
        MultiselectTableHeader,
        AssetDeletionTableLegacy
    ]
})
export class TransferMonitorModule {}

/**
 * Injection token for using a storage singleton that contain a list of saved
 * upload payloads.
 */
export const STORAGE = new InjectionToken<Storage>(
  'Storage of saved upload payloads', {factory: () => window.localStorage});


/** Switch between fake and real `LocalUploadsTrackingService`. */
export function getLocalUploadsTrackingService(
  storage: Storage,
  resumableUploadService: ResumableUploadService,
  snackBar: SnackBarService,
  errorService: ErrorService,
  router: Router,
  dataService: FirebaseFirestoreDataService,
  featureService: FeatureFlagService) {
  return (useMockService) ? new FakeLocalUploadsTrackingService(resumableUploadService, errorService):
  new LocalUploadsTrackingService(storage, resumableUploadService, snackBar, errorService, router, dataService, featureService);
}

