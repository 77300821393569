<ng-container *ngIf="{
  displayMode: (vodSearchService.displayMode$ | async)!,
  results: results$ | async,
  searchMode: searchMode$ | async
} as tplState">
  <div
      class="search-results"
      [class.view-mode-grid]="isGrid(tplState.displayMode)"
      [class.view-mode-list]="isList(tplState.displayMode)"
      [class.loading]="progressbar.visible$ | async"
      #searchResultsEl>
    <ng-container
        *ngIf="
        tplState.results &&
        tplState.searchMode &&
        getSelectionInfo(tplState.results.segments, vodSearchService.selectedSegmentNames) as selectionInfo">
      <div class="results" #scrollView>
        <div class="centered-content" [class.centered-content--list]="isList(tplState.displayMode)">
          <!-- Search result header -->
          <ng-container
              *ngIf="tplState.results.segments.length > 0 || vodSearchService.pagination.pageIndex > 0">
            <div class="asset-results-bar">
              <div class="asset-results-header">
                <span>
                  {{formatInputPlaceholder(tplState.searchMode)}}
                </span>
                <button
                    *ngIf="tplState.searchMode === SearchMode.VIDEO"
                    mat-icon-button
                    class="display-mode-toggle"
                    (click)="this.toggleViewMode(tplState.displayMode)"
                    [matTooltip]="'Switch to ' + (isGrid(tplState.displayMode) ? 'list view': 'grid view')"
                    [mam-firebase-ga]="'Toggle ' + (isGrid(tplState.displayMode) ? 'list view': 'grid view') +' for search results'">
                  <mat-icon class="material-icons-outlined">
                    {{isGrid(tplState.displayMode) ? 'list' : 'view_module'}}
                  </mat-icon>
                </button>
              </div>
              <div class="filler"></div>
              <!-- Paginator for grid view -->
              <ng-container *ngIf="isGrid(tplState.displayMode)">
                <ng-container
                    *ngTemplateOutlet="paginator; context: {showPageSelector: false}">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <!-- Container for Grid/List view-->
          <!-- TODO: Split Grid and list view into separate components -->
          <div
              @slideUpIn
              *ngIf="tplState.results.segments.length > 0; else displayEmptyContents">

            <!-- GRID VIEW -->
            <div *ngIf="isGrid(tplState.displayMode); else listView"
                class="asset-results">
              <!-- TODO: Update with shared/mam-asset-preview -->
              <mat-card
                  appearance="outlined"
                  *ngFor="let segment of tplState.results.segments; let index = index; trackBy: trackSegment"
                  class="mam-elevation-2"
                  [class.no-rendition]="!segment.asset.renditions.length"
                  [class.deleted]="segment.asset.isDeleted ||
                      segment.paddedDuration <= 0"
                  [routerLink]="['/asset', segment.asset.name]"
                  [queryParams]="{'type': 'search', 'initialIndex': vodSearchService.getAbsoluteIndex(index)}"
                  queryParamsHandling="merge"
                  mam-firebase-ga="Open VoD asset from search results"
                  [mam-firebase-resource]="segment.asset.name">
                <mam-seekable-preview
                    [previewUrl]="(segment.asset | bestSeekUrl:segment.duration | async)!"
                    [start]="segment.paddedStartTime"
                    [end]="segment.paddedEndTime"
                    [asset]="segment.asset"
                    [thumbTime]="getThumbTime(segment)"
                    (thumbnailLoad)="onThumbnailLoaded()">
                </mam-seekable-preview>
                <div class="timeline"
                    *ngIf="tplState.searchMode === SearchMode.SEGMENT">
                  <span
                      class="result-area"
                      [style.left]="segment.redAreaLeft * 100 + '%'"
                      [style.width]="segment.redAreaWidth * 100 + '%'">
                  </span>
                </div>
                <div class="info" *ngIf="segment.asset.name">
                  <div class="info-details" #infoLeft>
                    <div class="title" [attr.title]="segment.asset.title">
                      {{segment.asset.title}}
                    </div>
                    <div class="date">{{segment.asset.eventTime | tzdate}}</div>
                    <div class="duration" *ngIf="segment.duration">
                      {{segment.duration | duration}}
                    </div>
                  </div>
                  <div class="actions" *ngIf="!segment.asset.isDeleted">
                    <ng-container *featureFlagOn="'enable-access-management'">
                      <mam-access-restriction-icon *ngIf="segment.permissions" size="medium"></mam-access-restriction-icon>
                    </ng-container>
                    <!-- Import status icon is only shown for full videos. -->
                    <mam-asset-location-status
                        *ngIf="tplState.searchMode === SearchMode.VIDEO"
                        [asset]="segment.asset">
                    </mam-asset-location-status>

                    <mam-asset-extra-actions
                      *ngIf="!segment.asset.isDeleted"
                      [assetPermissions]="segment.permissions"
                      [asset]="segment.asset"
                      [paddedSegment]="segment"
                      [anchor]="infoLeft"
                      (updateRestriction)="updateAssetRestriction(segment, $event)"
                      [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO"
                    ></mam-asset-extra-actions>
                  </div>
                </div>
              </mat-card>
            </div>

            <!-- LIST VIEW -->
            <ng-template #listView>
                <mam-search-list-view></mam-search-list-view>
            </ng-template>

            <!-- Second Paginator -->
            <div class="filler"></div>
            <div id="search-second-paginator">
              <ng-container *ngIf="(vodSearchService.pagination.totalCount/vodSearchService.pagination.pageSize > 1)">
                <ng-container *ngTemplateOutlet="paginator; context: {showPageSelector: false}">
                </ng-container>
              </ng-container>
            </div>
          </div>

          <ng-template #displayEmptyContents>
            <div class="empty-contents">
              <div class="image-container">
                <img alt="Empty Search Results"
                  src="images/empty_contents_logo.svg">
              </div>
              <ng-container
                  *ngIf="tplState.results.isInitialFacetsResponse; else noResults">
                <h1>Loading results...</h1>
                <h2>Please wait</h2>
              </ng-container>
              <ng-template #noResults>
                <h1>No results found</h1>
                <h2>Please try modify your search queries</h2>
              </ng-template>
              <button
                mat-button
                mam-firebase-ga="Clear all facets from no results"
                *ngIf="showClearFacetsButton$ | async"
                class="gmat-button"
                (click)="clearAllFacets()"
              >
                Clear all filters
              </button>
            </div>
          </ng-template>
        </div>
      </div>

      <ng-template #paginator let-showPageSelector="showPageSelector">
        <mat-paginator
            class="main__mat-paginator--border"
            [ngClass]="['mat-paginator', (selectionInfo.indeterminate || selectionInfo.areAll) ? 'hide-paginator' : '']"
            [length]="vodSearchService.pagination.totalCount"
            [pageSize]="vodSearchService.pagination.pageSize"
            [pageIndex]="vodSearchService.pagination.pageIndex"
            (page)="onPageChange($event)"
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [hidePageSize]="!showPageSelector">
        </mat-paginator>
      </ng-template>
    </ng-container>
  </div>

</ng-container>
