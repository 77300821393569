<table mat-table [dataSource]="dataSource$" multiTemplateDataRows *ngIf="dataSource$ | async as dataSource">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef style="padding-left: 56px">Name</th>
        <td mat-cell *matCellDef="let row" [ngClass]="{'p-left-32': isSubRow, 'p-left-28': !isSubRow}">
            <div style="display: flex; align-items: center">
                <div style="width: 24px; display: flex; justify-content: center">
                    <button
                        style="margin-left: -24px"
                        mat-icon-button
                        (click)="toggleExpansion(row); $event.stopPropagation()"
                        *ngIf="isResource(row.element) && row.element.directChildrenCount > 0"
                    >
                        <mat-icon *ngIf="(row.expanded$ | async) === false"> keyboard_arrow_right </mat-icon>
                        <mat-icon *ngIf="(row.expanded$ | async) === true"> keyboard_arrow_up </mat-icon>
                    </button>
                </div>
                <div
                    style="display: flex; align-items: center; cursor: pointer"
                    (click)="
          executeAction('navigate', row.element);
          $event.stopPropagation()
        "
                >
                    @if (isResource(row.element) && row.element.type === 'folder') {
                    <mat-icon>folder</mat-icon>
                    } @if (isResource(row.element) && row.element.type === 'clipbin') {
                    <mat-icon role="img" aria-hidden="true">subscriptions</mat-icon>
                    }
                    <span style="padding-left: 8px">{{ row.element.name }}</span>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="content">
        <th style="margin-left: -200px" mat-header-cell *matHeaderCellDef>Content</th>
        <td mat-cell *matCellDef="let row">
            <div
                [ngPlural]="
          isResource(row.element) && row.element.directChildrenCount
            ? row.element.directChildrenCount
            : 0
        "
            >
                <ng-template ngPluralCase="=0">No items</ng-template>
                <ng-template ngPluralCase="=1">1 item</ng-template>
                <ng-template ngPluralCase="other">
                    {{ isResource(row.element) ? row.element.directChildrenCount : '' }} items
                </ng-template>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="lastModified">
        <th style="margin-left: -24px" mat-header-cell *matHeaderCellDef>Last Modified</th>
        <td mat-cell *matCellDef="let row">
            {{ isResource(row.element) ? (row.element.updatedAt | date : 'mediumDate') : '' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th style="margin-left: -24px" mat-header-cell *matHeaderCellDef>Owner</th>
        <td mat-cell *matCellDef="let row">
            <div style="display: flex; align-items: center">
                @if (isResource(row.element) && ((row.element.owner && row.element.owner.length) || (row.element.owner
                && row.element.owner.length))) {
                <span>
                    {{ row.element!.owner || row.element!.owner!.length ? row.element.owner : row.element.owner }}
                </span>
                } @else {
                <mat-icon style="margin-right: 8px" role="img" aria-hidden="true">person</mat-icon>
                }

                <p title="{{ isResource(row.element) ? row.element.createdBy : '' }}">
                    {{ isResource(row.element) && row.element.owner ? row.element.owner : (isResource(row.element) ?
                    'N/A' : '&nbsp;') }}
                </p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="menu" class="text-align-r">
        <th style="margin-left: -24px" mat-header-cell *matHeaderCellDef style="text-align: right"></th>
        <td mat-cell *matCellDef="let row" style="text-align: right">
            <div style="display: flex; justify-content: flex-end">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    (click)="executeAction('rename', row.element)"
                    mam-firebase-ga="Open rename dialog"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Rename
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('move', row.element)"
                    mam-firebase-ga="Open move dialog"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Move
                </button>
                <button
                    mat-menu-item
                    (click)="executeAction('delete', row.element)"
                    style="color: #f44336"
                    mam-firebase-ga="Select deletion"
                    [mam-firebase-resource]="isResource(row.element) ? row.element.id : ''"
                >
                    Delete
                </button>
            </mat-menu>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="(row.expanded$ | async) ? 'expanded' : 'collapsed'" style="overflow: hidden">
                <div
                    *ngIf="
            (row.expanded$ | async) && !expandedFolderDataCache[row.id]
          "
                    class="loading-animation"
                ></div>
                <mam-cbo-list-display
                    *ngIf="
            (row.expanded$ | async) && expandedFolderDataCache[row.id]
          "
                    [result]="expandedFolderDataCache[row.id]"
                    [isSubRow]="true"
                >
                </mam-cbo-list-display>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [hidden]="isSubRow"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="row.expanded$ | async"
    ></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [hidden]="(row.expanded$ | async) === false"
        class="detail-row"
    ></tr>
</table>

<table *ngIf="showLoadMoreButton" mat-table [dataSource]="[1]">
    <ng-container matColumnDef="loadMore">
        <td mat-cell *matCellDef="let row" colspan="99">
            <div style="text-align: center">
                <button style="border-radius: 10px" mat-raised-button>Load More</button>
            </div>
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: ['loadMore']"></tr>
</table>

<ng-template #subfolderLoadMore let-folderId="folderId">
    <div style="text-align: center; margin-top: 10px">
        <button mat-raised-button>Load More</button>
    </div>
</ng-template>
