<div class="asset" *ngIf="topAsset">
  <div class="asset-thumbnail-container">
    <mam-shared-asset-thumbnail
      class="asset-thumbnail"
      [routerLink]="['/asset/',topAsset.url]"
      queryParamsHandling="merge"
      [asset]="topAsset.original || topAsset.clip">
    </mam-shared-asset-thumbnail>
  </div>
  <div class="asset-info">
    <span class="asset-type">{{topAsset.original? 'Original' : 'Clip'}}</span>
    <span class="asset-url">
      <button
      class="icon-button"
          type="button"
          aria-label="Open parent asset"
          [routerLink]="['/asset/',topAsset.url]"
          queryParamsHandling="merge"
          mam-firebase-ga="Navigate to parent asset from admin metrics panel"
          [mam-firebase-resource]="topAsset.asset_name">
        <mat-icon class="url-icon">movie</mat-icon>
      </button>
    </span>
    <span class="resource-count">
      <mat-icon class="content-icon">content_cut</mat-icon> {{topAsset.event_count}}
    </span>
    <div class="asset-title">{{topAsset.original?.title || topAsset.clip?.title}}</div>

  </div>
  <div class="asset-hr"></div>
</div>
