<div class="title-dialog">
    <ng-container *ngIf="data.icon === 'restrict__custom'; else defaultIcon">
        <mam-access-restriction-icon size="medium"></mam-access-restriction-icon>
    </ng-container>
    <ng-template #defaultIcon>
        <mat-icon>{{data.icon}}</mat-icon>
    </ng-template>

    <h1>{{data.title}}</h1>
</div>
<div class="content-dialog" mat-dialog-content>
    <p>{{data.content}}</p>
</div>
<div class="actions-dialog" mat-dialog-actions>
    <button
        *ngFor="let button of data.buttons"
        mat-button
        [mat-dialog-close]="button.value"
        [class.btn--warning]="button.type === 'warn'"
    >
        {{button.text | uppercase}}
    </button>
</div>
