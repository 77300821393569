import {environment} from '../environments/environment';
import { ApiCopyStateDetailState } from '../services/ias_types';

/**
 * Asserts the given argument. If the argument is falsy, an error is thrown.
 * Enabled only in dev.
 */
export function assertTruthy(value: unknown, msg?: string): asserts value {
  if (environment.compiled) return;

  if (!value) {
    throw new Error(msg ?? `Assertion error: expected truthy but got ${value}`);
  }
}

/** Asserts that provided value is a string */
export function assertString(
    value: unknown, msg?: string): asserts value is string {
  msg = msg ?? `Expected a string but got ${value}`;
  assertTruthy(typeof value === 'string', msg);
}

/** Asserts that provided value is an array */
export function assertArray(
    value: unknown, msg?: string): asserts value is unknown[] {
  msg = msg ?? `Expected an array but got ${value}`;
  assertTruthy(Array.isArray(value), msg);
}

/** Asserts that provided value is not `null` or `undefined` */
export function assertExists<T>(
    value: T, msg?: string): asserts value is NonNullable<T> {
  msg = msg ?? `Expected value to exist but got ${value}`;
  assertTruthy(value != null, msg);
}

/** Asserts that provided value is not `null` or `undefined` and returns it */
export function castExists<T>(value: T, msg?: string): NonNullable<T> {
  assertExists(value, msg);
  return value;
}

/**
 * Fail to compile on unexpected values.
 *
 * assumeExhaustive can be used along with type narrowing to ensure at compile
 * time that all possible types for a value have been handled. At runtime it is
 * a no-op.
 *
 * A common use-case is in switch statements:
 *
 * ```
 * // sensibleDefault: string
 * // numericEnumValue: Enum.A | Enum.B
 * switch(numericEnumValue) {
 *   case Enum.A:
 *     return 'A';
 *   case Enum.B:
 *     return 'B';
 *   default:
 *     assumeExhaustive(numericEnumValue);
 *     return sensibleDefault;
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
export function assumeExhaustive(value: ApiCopyStateDetailState): void {}

/**
 * Fail to compile on unexpected values.
 *
 * assumeExhaustiveAllowing is similar to assumeExhaustive, with one difference
 * that user can specify expected type of value other than 'never'.
 *
 * It is useful when enum contains values that should never occur. Those should
 * be passed as the type argument to assumeExhaustiveAllowing. A common use-case
 * would be like:
 *
 * ```
 * // enumValue: Enum.A | Enum.B | Enum.UNSPECIFIED | Enum.UNKNOWN
 * switch(enumValue) {
 *   case Enum.A:
 *     break;
 *   case Enum.B:
 *     break;
 *   default:
 *     assumeExhaustiveAllowing<Enum.UNSPECIFIED|Enum.UNKNOWN>(enumValue);
 *     break;
 * }
 * ```
 */
export function
assumeExhaustiveAllowing<Allowed = never, Arg extends Allowed = Allowed>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    value: Arg): void {}

/**
 * Throw an exception on unexpected values.
 *
 * checkExhaustive can be used along with type narrowing to ensure at
 * compile time that all possible types for a value have been handled. For cases
 * where exhaustiveness can not be guaranteed at compile time (i.e. proto enums)
 * an exception will be thrown.
 *
 * A common use-case is in switch statements:
 *
 * ```
 * // enumValue: Enum.A | Enum.B
 * switch(enumValue) {
 *   case Enum.A:
 *   case Enum.B:
 *     break;
 *   default:
 *     checkExhaustive(enumValue);
 * }
 * ```
 *
 * This method throws an exception rather than using an assertion because
 * assertions are stripped in production code and we need the check to fail in
 * production.
 *
 * @param value The value to be checked
 * @param msg An optional error message to throw
 */
export function checkExhaustive(value: never, msg?: string): never {
  checkExhaustiveAllowing<never>(value, msg);
}

/**
 * Throw an exception on unexpected values.
 *
 * checkExhaustiveAllowing is similar to checkExhaustive, with one difference
 * that user can specify expected type of value other than 'never'.
 *
 * It is useful when enum contains values that should never occur. Those should
 * be passed as the type argument to checkExhaustiveAllowing. A common use-case
 * would be like:
 *
 * ```
 * // enumValue: Enum.A | Enum.B | Enum.UNSPECIFIED | Enum.UNKNOWN
 * switch(enumValue) {
 *   case Enum.A:
 *   case Enum.B:
 *     break;
 *   default:
 *     checkExhaustiveAllowing<Enum.UNSPECIFIED|Enum.UNKNOWN>(enumValue);
 * }
 * ```
 *
 * @param value The value to be checked
 * @param msg An optional error message to throw
 */
export function
checkExhaustiveAllowing<Allowed, Arg extends Allowed = Allowed>(
    value: Arg, msg = `checkExhaustive: unexpected value "${value}"!`): never {
  throw new Error(msg);
}
