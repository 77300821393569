<h1 mat-dialog-title>Move</h1>
<div mat-dialog-content>
  <div class="move-folder__wrapper">
    <h2 class="sub-title">
      Current Location:
      <span class="move-folder__folder-icon"><img src="../../images/folder_icon_light.svg" alt="Folder icon"></span>
      <span class="move-folder__separator">></span>
      <span class="move-folder__folder-name">{{data.name}}</span>
    </h2>
    <!-- <span class="error" *ngIf="isMaximumLevelError$ | async">You can't move this folder into the selected folder due to maximum depth level.</span> -->
    <div class="move-folder__selected-nested-folder" *ngIf="selectedNestedFolder != null">
      <div class="move-folder__selected-nested-folder__content">
        <div class="icon" (click)="backClicked()">
          <mat-icon>arrow_back</mat-icon>
        </div>
        <div class="folder">
          <span>{{selectedFolder?.name}}</span>
        </div>
      </div>
    </div>
    <hr />
    <div
      [ngClass]="{'move-folder__content--not-nested': !isNestedFolder, 'move-folder__content--nested': isNestedFolder}"
      class="move-folder__content">
      <ng-container *ngIf="isLoading === false; else loadingContent;">

        <ul>
          <li *ngFor="let folder of resources$ | async" data-id={{folder.id}} (click)="selectFolder(folder)"
            [ngClass]="{'move-folder__content__selected-folder': selectedFolder$.value && selectedFolder$.value.id === folder.id}">
            <span class="move-folder__folder-icon">
              <img *ngIf="folder.type === 'folder'" src="../../images/folder_icon_light.svg" alt="Folder icon">
              <mat-icon *ngIf="folder.type !== 'folder'" role="img" aria-hidden="true"> subscriptions </mat-icon>
            </span>
            <span class="move-folder__separator">></span>
            <span class="move-folder__folder-name">{{folder.name}}</span>
            <span class="move-folder__separator" *ngIf="folder.directChildrenCount! > 0">></span>
          </li>
        </ul>
      </ng-container>
    </div>
    <hr />
    <div class="move-folder__selected-folder">
      <span class="error" *ngIf="isMaximumLevelError$ | async">You can't move this folder into the selected folder due
        to maximum depth level.</span>
      <span class="error" *ngIf="hasClipBinInside$ | async">You can't move this folder into the selected folder because
        it has one or more clip bins.</span>
      <div class="move-folder__selected-folder__content"
        *ngIf="!isMaximumLevelError$.value && !hasClipBinInside$.value && selectedFolder != null">
        Selected Location:
        <span class="move-folder__folder-icon"><img src="../../images/folder_icon_light.svg" alt="Folder icon"></span>
        <ng-container *ngIf="!isNestedFolder || selectedNestedFolder == null; else renderBreadcrumb">
          <span class="move-folder__separator">></span>
          <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close mam-firebase-ga="Cancel folder move" [mam-firebase-resource]="data.name">
    Cancel
  </button>
  <button mat-button class="primary" #primary mam-firebase-ga="Confirm folder move" [mam-firebase-resource]="data.name"
    (click)="moveFolderToSelected()" [disabled]="moveButtonDisableCondition()">
    Move
  </button>
</div>

<ng-template #loadingContent>
  <div class="move-folder__loading-content">
    <ul>
      <li *ngFor="let _ of [].constructor(9)" class="loader">
        <span class="move-folder__folder-icon">
          <img src="../../images/folder_icon_light.svg" alt="Folder icon">
        </span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #renderBreadcrumb>
  <span *ngFor="let folder of selectedNestedFolder?.breadcrumb">
    <span class="move-folder__separator">></span>
    <span class="move-folder__folder-name">{{folder.name}}</span>
  </span>
  <span *ngIf="selectedFolder">
    <span class="move-folder__separator">></span>
    <span class="move-folder__folder-name">{{selectedFolder.name}}</span>
  </span>
</ng-template>
