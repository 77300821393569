import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { asyncScheduler, map, Observable, scheduled, startWith } from 'rxjs';

import { PermissionDetail, PermissionEnum, ResourceAccessUser } from 'access_management/models/access_management.model';
import { AccessManagementService } from 'access_management/services/access_management.service';
import { AuthService } from 'auth/auth_service';
import { SnackBarService } from 'services/snackbar_service';

type ResourceItem = ResourceAccessUser & { toRevoke?: boolean };

@Component({
  selector: 'mam-access-restriction-asset-dialog',
  templateUrl: './access-restriction-asset-dialog.component.html',
  styleUrl: './access-restriction-asset-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessRestrictionAssetDialog implements OnInit {
  options: ResourceItem[] = [];
  resourceWithAccess: ResourceItem[] = [];
  searchEmail = new FormControl<string>('');
  filteredOptions$!: Observable<Partial<ResourceItem>[]>;

  constructor(
    readonly dialog: MatDialog,
    readonly snack: SnackBarService,
    readonly authService: AuthService,
    public readonly cdr: ChangeDetectorRef,
    readonly accessManagementService: AccessManagementService,
    readonly dialogRef: MatDialogRef<AccessRestrictionAssetDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { documentId: string, permissions: ResourceAccessUser[], updateManyAsset: boolean}
  ) {}

  ngOnInit(): void {
    this.resourceWithAccess = this.data?.permissions ?? [];
    this.getUsersEmail();
  }

  private getUsersEmail() {
    this.accessManagementService.getIasUsers()
      .subscribe({
        next: (options) => {
          this.options = options.filter(
            (value, index, self) =>
              index === self.findIndex((obj) => obj.email === value.email)
          );

          this.filteredOptions$ = this.searchEmail.valueChanges.pipe(
            startWith(''),
            map((value) => this.filterOptions(value ?? '')),
          );
        }
      });
  }

  private filterOptions(value: string): ResourceItem[] {
    const filterValue = value.toLowerCase();

    return this.options
      .filter(option =>
        option.email.toLowerCase().includes(filterValue)
        || option.displayName.toLowerCase().includes(filterValue)
        && !this.resourceWithAccess.some(p => p.email === filterValue)
      );
  }

  async addToShare(option: MatAutocompleteSelectedEvent) {
    const list = new Set<ResourceItem>(this.resourceWithAccess ?? []);

    const value = this.options.find(f => f.email === option.option.value);
    const existOnList = this.resourceWithAccess.some(r => r.email === option.option.value);
    if (value && !existOnList) {
      list.add(value);
      this.resourceWithAccess = [...list];
    }
    this.searchEmail.setValue('');
  }

  permissionFilterAndTransform(): Partial<PermissionDetail>[] {
    return this.resourceWithAccess
    .filter(p => !p.toRevoke)
    .map((p) => {
      return {
        permission: PermissionEnum.GRANTED,
        userId: p.email,
        displayName: p.displayName
      };
    });
  }

  confirmUpdate() {
    scheduled(this.accessManagementService.updatePermissions(this.data.documentId, this.permissionFilterAndTransform()), asyncScheduler)
      .subscribe(() => {
        this.snack.message("Success");
        this.dialogRef.close(true);
      });
  }

  confirmUpdateMany() {
    this.dialogRef.close(this.permissionFilterAndTransform());
  }

  hasRevoke() {
    return this.resourceWithAccess.some(f => f.toRevoke);
  }
}

