import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {filter, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';

import {assertExists} from '../../../asserts/asserts';
import {Asset} from '../../../services/asset_service';
import {ResizeObserverService} from '../../../services/resize_observer_service';
import {Sprite, SpritesheetService, SpriteStyle} from '../../../shared/spritesheet_service';

/**
 * Renders a thumbnail at a specific time.
 */
@Component({
  selector: 'mam-shared-asset-thumbnail',
  templateUrl: './asset-thumbnail.component.html',
  styleUrls: ['./asset-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedAssetThumbnail implements OnInit, OnDestroy {
  @Input() asset?: Asset;

  /**
   * Time of the thumbnail to be displayed (may not be an exact match). Will
   * default to the asset center time if not provided. Setting negative value
   * results in default thumbnail.
   */
  @Input()
  get time(): number|undefined {
    return this.time$.getValue();
  }
  set time(time: number|undefined) {
    this.time$.next(time);
  }

  /**
   * Emits when the first thumbnail image is shown. Further thumbnails loaded
   * when `time` changes will not emit.
   */
  @Output() readonly thumbnailLoad = new EventEmitter<boolean>();

  /**
   * Will be defined is the current thumbnail is the only sprite on its
   * spritesheet. This allows to use an <img> tag with direct link instead of a
   * <div> with background-image CSS.
   */
  singleImageBase64?: string;

  style: SpriteStyle|null = null;

  constructor(
      private readonly host: ElementRef<HTMLElement>,
      private readonly cdr: ChangeDetectorRef,
      private readonly elRef: ElementRef<HTMLElement>,
      private readonly spriteSheetService: SpritesheetService,
      private readonly resizeObserver: ResizeObserverService,
  ) {}

  ngOnInit() {
    // Hook resize observer.
    this.resizeObserver.observe(this.host.nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.resize$.next();
        });

    // Empty placeholder when there is no asset, or hardcoded thumbnail when it
    // exists (only for the fake backend).
    if (!this.asset || this.asset.thumbnail) {
      this.singleImageBase64 =
          this.spriteSheetService.getDefaultThumbnail(this.asset);
      return;
    }

    // If no time is specified, default to the asset's center time.
    if (this.time == null) {
      const centerTime = (this.asset.endTime + this.asset.startTime) / 2;
      this.time$.next(centerTime);
    }

    this.watchStyleUpdates()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(styleOrImage => {
          this.cdr.markForCheck();

          // Emit whether the first sprite pre-loaded successfully.
          if (!this.style) {
            const success = styleOrImage != null;
            this.thumbnailLoad.emit(success);
            this.thumbnailLoad.complete();
          }

          if (typeof styleOrImage === 'string') {
            this.singleImageBase64 = styleOrImage ||
                this.spriteSheetService.getDefaultThumbnail(this.asset);
          } else {
            // Update style if a new one is received, otherwise keep previous
            // value if any, and in case of error when there was no previous
            // style default to a placeholder.
            this.style = styleOrImage || this.style ||
                this.spriteSheetService.getDefaultThumbnailStyle(this.asset);
          }
        });
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  private readonly time$ = new BehaviorSubject<number|undefined>(undefined);

  /** Preloaded (in-memory) URL of the thumbnail image. */
  private readonly sprite$: Observable<Sprite|null> = this.getSprite();

  /** Emits when this component's dimensions change. */
  private readonly resize$ = new ReplaySubject<void>(1);

  /**
   * Observe changes to the `time` input and emits the matching sprite fully
   * preloaded as a base64 string (and params).
   */
  private getSprite(): Observable<Sprite|null> {
    let currentSpriteRequestTime = -1;
    return this.time$.pipe(
        // No sprite is loaded until we have an actual time and asset.
        filter((time): time is number => !!this.asset && time != null),
        // Find required spritesheet URL.
        map(time => {
          if (time < 0) return {spriteSheet: null, time};
          assertExists(this.asset);
          const spriteSheet =
              this.spriteSheetService.getSpriteSheet(this.asset);
          return {spriteSheet, time};
        }),
        // Preload the sprite containing this asset's time thumbnail. `mergeMap`
        // to not abort the load of a sprite when another one is started.
        mergeMap(({spriteSheet, time}) => {
          if (!spriteSheet) return of(null);
          return this.spriteSheetService.loadSprite(spriteSheet, time);
        }),
        // Ignore preloaded sprites that have been initiated before the
        // latest one that we received (they may have been slower to load).
        filter(sprite => {
          return !sprite || sprite.requestedTime > currentSpriteRequestTime;
        }),
        // Remember when the most recent thumbnail has been initiated so that we
        // discard any older ones in the previous step.
        tap(sprite => {
          if (sprite) {
            currentSpriteRequestTime = sprite.requestedTime;
          }
        }),
    );
  }

  /**
   * Updates the CSS style of this component to display a thumbnail matching
   * the given input time properly. New styles will be generated from the same
   * image file if the dimensions of this component change so that it is still
   * centered on its container.
   */
  private watchStyleUpdates(): Observable<SpriteStyle|string|null> {
    return this.sprite$.pipe(
        // Refresh styles if the dimensions change.
        switchMap(sprite => {
          if (!sprite) return of(null);

          if (sprite.spriteSheet.columnCount === 1 &&
              sprite.spriteSheet.rowCount === 1) {
            return of(sprite.base64);
          }

          // Convert the sprite to a CSS style.
          return this.resize$.pipe(map(() => {
            const hostWidth = this.elRef.nativeElement.offsetWidth;
            const hostHeight = this.elRef.nativeElement.offsetHeight;
            return this.spriteSheetService.generateStyle(
                sprite, hostWidth, hostHeight);
          }));
        }),
    );
  }

  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
