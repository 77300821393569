import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from '../../auth/auth_service';
import { Clip } from '../../services/asset_service';
import { SharedLinksService } from '../../services/shared_links_service';

const CREATE_CLIPBIN_CHILD_LINK_ENDPOINT = '/shared-urls/create-clipbin-child-link';

@Injectable({ providedIn: 'root' })
export class SharedLinkClipbinApiService {

  constructor(
    private readonly http: HttpClient,
    private readonly sharedLinkService: SharedLinksService,
    private readonly authService: AuthService,
  ) {}

  createLink(asset: Clip, additionalProperties: Record<string, string>) {
    const ttl = this.sharedLinkService.daysToTtl(30);
    const data = this.sharedLinkService.prepareCreateLinkData(asset, ttl, additionalProperties);
    const accessToken = this.authService.getAccessToken();
    const headers = {
        'Authorization': `Bearer ${accessToken}`
    };
    return this.http.post(CREATE_CLIPBIN_CHILD_LINK_ENDPOINT, data, {headers});
  }
}
