<button
    *ngIf="segment || isVideoShareable() || isVideoDeletable()"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="gmat-button"
    aria-label="Open asset extra actions"
    (click)="$event.stopPropagation(); getRestriction();"
    mam-firebase-ga="Open asset extra actions menu"
    [mam-firebase-resource]="asset.name">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" class="asset-extra-actions-menu" >
  <!-- Add clip (VoD only) -->
  <div
      *ngIf="!asset.isLive"
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        mat-menu-item
        [mam-firebase-ga]="getFirebaseGa()"
        [mam-firebase-resource]="asset.name"
        (click)="openAddClipDialog()">
      <mat-icon>subscriptions</mat-icon>
      {{segment?.searchMode === SearchMode.SEGMENT ? 'Add enhanced clip' :
      'Add full title' }}
    </button>
  </div>

  <!-- Export (Origin VoD asset only) -->
  <div
      *ngIf="isVideoExportable()"
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        mat-menu-item
        [mam-firebase-ga]="getFirebaseGa()"
        [mam-firebase-resource]="asset.name"
        (click)="openExportAssetDialog()">
      <mat-icon>arrow_outward</mat-icon>
      Export
    </button>
  </div>

  <!-- Share video -->
  <div
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        *ngIf="isVideoShareable()"
        [disabled]="isVideoDeleted() || asset.permissions"
        mat-menu-item
        mam-firebase-ga="Share video from extra actions"
        [mam-firebase-resource]="asset.name"
        (click)="shareVideo()">
      <mat-icon>share</mat-icon>Share {{asset.original && asset.original.duration.toFixed(0) !== asset.duration.toFixed(0) ? "clip" : "video"}}
    </button>
  </div>

<ng-container *featureFlagOn="'enable-access-management'">

     <!-- Restrict asset and Add users to a restricted asset -->
      <div
        [matTooltip]="videoDisabledMessage"
        [matTooltipDisabled]="!isVideoDeleted()">
        <button
          *ngIf="isVideoAbleToApplyRestrictions()"
          [disabled]="isVideoDeleted()"
          mat-menu-item
          mam-firebase-ga="Add users to a restricted asset from extra actions"
          [mam-firebase-resource]="asset.name"
          (click)="addUsersToRestrictedAsset()">

          @if (assetPermissions) {
            <ng-container>
              <span class="restriction-wrapper">
                <span class="icon-container">
                  <mat-icon>group_add</mat-icon>
                </span>
                <span class="text-container">
                  Add Users
                </span>
              </span>
            </ng-container>
          }
          @else {
            <ng-container>
              <span class="restriction-wrapper">
                <span class="icon-container">
                  <mam-access-restriction-icon class="restriction-icon" size="medium"></mam-access-restriction-icon>
                </span>
                <span class="text-container-restricted">
                  Restrict Asset
                </span>
              </span>
            </ng-container>
          }
        </button>
      </div>

      <!-- Make video public to a restricted asset -->
      <div
        *ngIf="isVideoAbleToApplyRestrictions() && assetPermissions"
        [matTooltip]="videoDisabledMessage"
        [matTooltipDisabled]="!isVideoDeleted()">
        <button
          *ngIf="isVideoAbleToApplyRestrictions()"
          [disabled]="isVideoDeleted()"
          mat-menu-item
          mam-firebase-ga="Make a restricted asset public from extra actions"
          [mam-firebase-resource]="asset.name"
          (click)="makeAssetPublic()">
          <mat-icon>public</mat-icon>Make Public
        </button>
      </div>

      <!-- Show restricted asset details for regular users -->
      <div
        *ngIf="showDetailsOptions()"
        [matTooltip]="videoDisabledMessage"
        [matTooltipDisabled]="!isVideoDeleted()">
        <button
          *ngIf="showDetailsOptions()"
          [disabled]="isVideoDeleted()"
          mat-menu-item
          mam-firebase-ga="Check the details restriction"
          [mam-firebase-resource]="asset.name"
          (click)="openDetailsDialog()">
          <mat-icon>info</mat-icon>Details
        </button>
      </div>
</ng-container>

  <!-- Delete asset  -->
  <div
      [matTooltip]="videoDisabledMessage"
      [matTooltipDisabled]="!isVideoDeleted()">
    <button
        [disabled]="isVideoDeleted()"
        *ngIf="isVideoDeletable()"
        class="delete-button"
        [class.disabled]="isVideoDeleted()"
        mat-menu-item
        mam-firebase-ga="Delete asset"
        [mam-firebase-resource]="asset.name"
        (click)="openDeleteAssetDialog()">
      <mat-icon>delete</mat-icon>Delete asset
    </button>
  </div>
</mat-menu>
