<!-- Trigger change detection when on of these changes. -->
<section class="cutdown_section" [style]="{'--container-width': containerWidth + 'px'}">
  <ng-container *ngIf="{
    isEditing: stagingService.isEditing$ | async,
    activeItemSet: stagingService.activeItemSet$ | async
  } as tplState">
    <ng-container *ngIf="asset">
      <div class="header">
        <div class="header-block">
          <h3 class="header__title">Cutdowns</h3>
          <button
              [disabled]="loading || tplState.isEditing"
              class="refresh-button"
              mat-icon-button
              aria-label="Refresh the cutdown list"
              matTooltip="Refresh the cutdown list"
              matTooltipPosition="above"
              mam-firebase-ga="Refresh the cutdown list"
              (click)="reloadCutdowns(true)">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="header-block header-block_buttons">
          <button
              *ngIf="isCutDownButtonDisplayed(asset)"
              [disabled]="tplState.isEditing"
              class="create-cutdown"
              mat-stroked-button
              mam-firebase-ga="Create cutdown from cutdown details"
              mam-firebase-resource="asset.name"
              (click)="createCutdown()">
            <mat-icon inline="true">add</mat-icon>
            <span class="desktopView">New partial cutdown</span>
            <span class="smallerScreensView">New</span>
          </button>
          <button
              [disabled]="tplState.isEditing || !getApprovableCutdowns().length"
              class="create-cutdown"
              mat-stroked-button
              mam-firebase-ga="Create cutdown from cutdown details"
              mam-firebase-resource="asset.name"
              (click)="approveCutdowns()">
            <mat-icon inline="true">check</mat-icon>Confirm all
          </button>
        </div>
      </div>
      <ng-container *ngIf="cutdowns">
        <table mat-table
            [dataSource]="cutdowns"
            class="cutdown-details"
            [trackBy]="trackById">

          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
              Title
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown"
                [title]="getTitle(cutdown)"
                class="main-column">
              <div class="icon-with-text">
                <ng-container [ngSwitch]="getIconState(cutdown)">
                  <mat-icon
                      *ngSwitchCase="IconState.SUCCEEDED" class="success">
                    check_circle
                  </mat-icon>
                  <mat-icon *ngSwitchCase="IconState.ERROR" class="error">
                    error
                  </mat-icon>
                  <mat-spinner
                      *ngSwitchCase="IconState.IN_PROGRESS"
                      diameter="20">
                  </mat-spinner>
                  <mat-icon *ngSwitchCase="IconState.DEFAULT">
                    content_cut
                  </mat-icon>
                </ng-container>
                <span>{{getTitle(cutdown)}}</span>
              </div>
            </td>
          </ng-container>

          <!-- Copy Type Column -->
          <ng-container matColumnDef="type">
            <th
                mat-header-cell
                *matHeaderCellDef>
              Type
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown">
              {{cutdown.isFullCopy ? 'Full' : 'Partial'}}
            </td>
          </ng-container>

          <!-- Duration Column -->
          <ng-container matColumnDef="duration">
            <th
                mat-header-cell
                *matHeaderCellDef>
              Duration
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown"
                title="{{getCutdownDurationInSeconds(cutdown) ?? '?'}}s">
              {{formatCutdownDuration(cutdown)}}
            </td>
          </ng-container>

          <!-- Start offset Column -->
          <ng-container matColumnDef="start">
            <th
                mat-header-cell
                *matHeaderCellDef>
              Start
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown"
                [title]="cutdown.isFullCopy ? '' : cutdown.startOffset+'s'">
              {{cutdown.isFullCopy ? '-' : displayOffsetAsTime(cutdown.startOffset)}}
            </td>
          </ng-container>

          <!-- End offset Column -->
          <ng-container matColumnDef="end">
            <th
                mat-header-cell
                *matHeaderCellDef>
              End
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown"
                [title]="cutdown.isFullCopy ? '' : cutdown.endOffset+'s'">
              {{cutdown.isFullCopy ? '-' : displayOffsetAsTime(cutdown.endOffset)}}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th
                mat-header-cell
                *matHeaderCellDef>
              Status
            </th>
            <td
                mat-cell
                *matCellDef="let cutdown"
                title="{{cutdown.endOffset}}s">
              {{formatStatus(cutdown)}}
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let cutdown">

              <!-- Approval button -->
              <button
                  *ngIf="cutdown.state === ApiCopyStateDetailStateEnum.STATE_DRAFT"
                  [disabled]="tplState.isEditing"
                  (click)="$event.stopPropagation(); approveCutdown(cutdown)"
                  mam-firebase-ga="Approve cutdown from cutdown details"
                  [mam-firebase-resource]="cutdown.name"
                  mat-icon-button
                  aria-label="Confirm cutdown"
                  matTooltip="Confirm cutdown"
                  class="gmat-button approve-cutdown">
                <mat-icon class="material-icons-outlined">check</mat-icon>
              </button>

              <!-- After approval cutdowns cannot be deleted -->
              <button
                  *ngIf="cutdown.state === ApiCopyStateDetailStateEnum.STATE_DRAFT"
                  [disabled]="tplState.isEditing"
                  (click)="$event.stopPropagation(); deleteCutdown(cutdown)"
                  mam-firebase-ga="Delete cutdown"
                  [mam-firebase-resource]="cutdown.name"
                  mat-icon-button
                  aria-label="Delete cutdown"
                  class="gmat-button delete-cutdown-button">
                <mat-icon class="material-icons-outlined">delete</mat-icon>
              </button>
              <button
                  *ngIf="
                      cutdown.vodAssetName &&
                      cutdown.state === ApiCopyStateDetailStateEnum.STATE_VOD_READY"
                  [disabled]="tplState.isEditing"
                  (click)="$event.stopPropagation(); navigateToVodAsset(cutdown)"
                  mam-firebase-ga="Open VoD asset from cutdown details"
                  [mam-firebase-resource]="cutdown.name"
                  mat-icon-button
                  aria-label="Open VoD asset"
                  matTooltip="Open VoD asset"
                  class="gmat-button open-vod-asset">
                <mat-icon class="material-icons-outlined">movie</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr mat-row
              *matRowDef="let cutdown; columns: displayedColumns;"
              mam-firebase-ga="Select cutdown in cutdown details"
              [mam-firebase-resource]="asset.name"
              [class.active]="tplState.activeItemSet?.has(cutdown.name)"
              [class.error]="cutdown.state === ApiCopyStateDetailStateEnum.STATE_ERROR"
              [class.success]="cutdown.state === ApiCopyStateDetailStateEnum.STATE_VOD_READY"
              (click)="setActive(cutdown, asset)">
          </tr>
        </table>
        <div *ngIf="!cutdowns.length" class="empty-message">
          No cutdowns specified.
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
