<div class="title-dialog">
    <h1>Access to restricted asset</h1>
</div>

<mat-divider> </mat-divider>
<div class="content-dialog" mat-dialog-content>

    <div class="people-list">
        @for (item of resourceWithAccess; track $index) {
        <div class="person-item">
            <div class="person-item-container">
                <div class="person-item-container__img">
                    <mat-icon>person</mat-icon>
                </div>
                <div class="person-item-container__description">
                    <p>{{item.displayName}}</p>
                    <p>{{item.email}}</p>
                </div>
            </div>
        </div>
        }
    </div>
</div>

<mat-divider> </mat-divider>
<p class="divider-text">
  Access to this asset is restricted.
  <br>
  Please contact Media Management to request user access.
</p>
<div class="actions-dialog" mat-dialog-actions>
    <button mat-button mat-dialog-close>CLOSE</button>
</div>
