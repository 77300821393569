
import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';


@Pipe({
  name: 'timestamp_to_date'
})

export class TimestampToDatePipe implements PipeTransform {
  transform(timestamp: Timestamp): Date {
    return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
  }
}
