import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AccessConfirm {
  icon: string;
  title: string;
  content: string;
  buttons: {
    text: string;
    type: 'warn' | 'default';
    value: unknown
  }[]
}

@Component({
  selector: 'mam-access-confirm-dialog',
  templateUrl: './access-confirm-dialog.component.html',
  styleUrl: './access-confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessConfirmDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AccessConfirm) {}
}
