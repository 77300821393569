<div class="content admin__mat-tab--spacing">
  <main>
    <div class="main-block">
      <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          [selectedIndex]="selectedTabIndex"
          (selectedTabChange)="changeTab($event.index)">
        <mat-tab
            mam-firebase-ga="Navigate to admin users"
            label="Users">
          <ng-template mat-tab-label>
            <span>Users</span>
          </ng-template>
        </mat-tab>

        <mat-tab
            *ngIf="showMetricsTabFF"
            mam-firebase-ga="Navigate to admin metrics"
            label="Metrics">
          <ng-template mat-tab-label>
            <span>Metrics</span>
          </ng-template>
        </mat-tab>
        <mat-tab
            *ngIf="showAssetsRestriction"
            mam-firebase-ga="Navigate to assets restrictions"
            label="Access Management">
          <ng-template mat-tab-label>
            <span>Asset Restriction</span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <!-- Selected tab content -->
      <ng-container *ngIf="selectedTabIndex != null">
        <ng-container [ngSwitch]="tabs[selectedTabIndex]">
          <ng-container *ngSwitchCase="'users'">
            <mam-admin-users-table>
            </mam-admin-users-table>
          </ng-container>
          <ng-container *ngSwitchCase="'metrics'">
            <mam-admin-metrics-list>
            </mam-admin-metrics-list>
          </ng-container>
          <ng-container *ngSwitchCase="'access-management'">
            <mam-access-management-list>
            </mam-access-management-list>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </main>
</div>
