import { Clip, Original } from "../../services/asset_service";

export type DashBoardReport = {
  title?:string
}

export type topAsset = {
  asset_name?: string,
  event_count?:number,
  resource?:string,
  resource_name?:string,
  original?:Original,
  clip?:Clip,
  url?:string
}

export enum AssetKeys {
  topClippedAssets = 'topClippedAssets',
  topDownloadAssets = 'topDownloadAssets',
  // TODO rename topDownloadedSubclips -> topDownloadSubclips
  topDownloadedSubclips = 'topDownloadedSubclips',
  topSharedAssets = 'topSharedAssets',
  topExportedFullAssets = 'topExportedFullAssets',
  topExportedSubclips = 'topExportedSubclips',
  topImportedPremierTitle = 'topImportedPremierTitle',
  topViewedAssets = 'topViewedAssets',
}
