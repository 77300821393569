import {DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';

import {BestSeekUrlPipe} from './best_seek_url_pipe';
import {BytesPipe} from './bytes_pipe';
import {CastPipe} from './cast/cast.pipe';
import {DurationOrTimePipe} from './duration_or_time_pipe';
import {DurationPipe} from './duration_pipe';
import {FolderStateToExportStatusPipe} from './folder_state_to_export_status';
import {SpacedPipe} from './spaced_pipe';
import {TimeAgoPipe} from './time_ago_pipe';
import { TruncatePipe } from './truncate.pipe';
import {TzDatePipe} from './tzdate_pipe';

@NgModule({
  declarations: [
    DurationPipe,
    DurationOrTimePipe,
    BestSeekUrlPipe,
    BytesPipe,
    SpacedPipe,
    TimeAgoPipe,
    TzDatePipe,
    FolderStateToExportStatusPipe,
    TruncatePipe,
    CastPipe
  ],
  exports: [
    DurationPipe,
    DurationOrTimePipe,
    BestSeekUrlPipe,
    BytesPipe,
    SpacedPipe,
    TimeAgoPipe,
    TzDatePipe,
    FolderStateToExportStatusPipe,
    TruncatePipe,
    CastPipe
  ],
  providers: [
    BestSeekUrlPipe,
    DatePipe,
    DurationPipe,
    TzDatePipe,
    BytesPipe,
  ]
})
export class PipesModule {
}
