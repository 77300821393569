<h1 mat-dialog-title>Delete Clip Bin</h1>
<div mat-dialog-content>
    <p>This will delete all clips in this clip bin, are you sure you want to continue?</p>
</div>
<div mat-dialog-actions>
    <button
        mat-button
        mat-dialog-close
        mam-firebase-ga="Cancel clip bin deletion"
        [mam-firebase-resource]="data.resource.iasData.label.displayName"
    >
        Cancel
    </button>
    <button
        mat-button
        cdkFocusInitial
        [mat-dialog-close]="true"
        (keyup.enter)="dialogRef.close(true)"
        class="danger"
        mam-firebase-ga="Confirm clip bin deletion"
        [mam-firebase-resource]="data.resource.iasData.label.displayName"
    >
        Delete
    </button>
</div>
