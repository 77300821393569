import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { UiTableModule } from 'ui/ui_table.module';

import { FirebaseModule } from '../firebase/firebase_module';
import { SharedModule } from '../shared/shared_module';
import { TransferMonitorModule } from '../transfer_monitor/transfer_monitor_module';

import { AccessManagementList } from './access-management-list/access-management-list';
import { TimestampToDatePipe } from './access-management-list/pipes/timestamp-to-date.pipe';
import { AdminLanding } from './admin_landing';
import { AdminMetricsChart} from './admin_metrics_chart';
import { AdminMetricsList } from './admin_metrics_list';
import { AdminUserTableGoogleWorkspaceCell } from './admin_user_table_google_workspace_cell';
import { AdminUsersTable } from './admin_users_table';
import {TopAssetCardComponent} from './dashboard/components/top-asset-card/top-asset-card.component';


@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatOptionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    TransferMonitorModule,
    FirebaseModule,
    SharedModule,
    FormsModule,
    UiTableModule,
    AdminMetricsChart
  ],
  declarations: [
    TimestampToDatePipe,
    AdminLanding,
    AdminMetricsList,
    AdminUsersTable,
    AdminUserTableGoogleWorkspaceCell,
    TopAssetCardComponent,
    AccessManagementList
  ],
  exports: [
    AdminLanding,
  ],
})
export class AdminModule {
}
