import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FeatureFlagService } from 'feature_flag/feature_flag_service';
import { TableUtils } from 'services/table_utils';

@Component({
  selector: 'mam-sort-col-btn',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  templateUrl: './sort_col_btn.component.html',
  styleUrls: ['./sort_col_btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortColBtnComponent {
  @Input({required: true}) rows: unknown[] | undefined;
  @Input({required: true}) key: string | undefined;
  @Input({required: true}) colName: string | undefined;
  @Input() activeKey: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() mamSortByField = new EventEmitter<any[]>();
  @Output() sortDirection = new EventEmitter<boolean>();
  directionChanged: boolean = false;

  readonly showColClientSort = this.featureService.featureOn('show-col-client-sort');

  constructor(
    private readonly tableUtils: TableUtils,
    private readonly featureService: FeatureFlagService,
    private readonly cdr: ChangeDetectorRef) {}

  onSortByField() {
    if(!this.rows?.length || this.rows?.length === 0 || !this.key) return;
    this.directionChanged = !this.directionChanged;
    const rowsSorted = this.tableUtils.sortByField(this.rows, this.key, this.directionChanged);
    this.mamSortByField.emit(rowsSorted);
    this.sortDirection.emit(this.directionChanged);
    this.cdr.detectChanges();
  }

}
