import {CdkDragMove} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  Optional,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import {MatTabGroup} from '@angular/material/tabs';
import {combineLatest, fromEvent, ReplaySubject} from 'rxjs';
import {debounceTime, filter, startWith, takeUntil} from 'rxjs/operators';

import {assertTruthy} from 'asserts/asserts';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {PreferencesService} from '../services/preferences_service';
import {SearchMode} from '../services/search_service';
import {HomeView, PersistentTab, StateService} from '../services/state_service';
import {VodSearchService} from '../services/vod_search_service';

import {ClipMoveEvent} from './clip_bin_selection';
import {MetadataService} from './metadata_service';
import {MoveClipDialog} from './move_clip_dialog';
import {StagingService} from './staging_service';

// Order of tabs here should be consistent with the order in the template.
const ALL_TABS: readonly PersistentTab[] = [
  'play-feed',
  'insights',
  'metadata',
  'related-views',
  'staging',
  'clipbins',
];

const VIEW_TABS: ReadonlyMap<HomeView, PersistentTab[]> = new Map([
  [HomeView.STAGING, ['staging']],
  [HomeView.SEARCH_RESULTS, ['clipbins', 'staging']],
  [
    HomeView.DETAILS,
    ['play-feed', 'insights', 'related-views', 'metadata', 'clipbins']
  ],
]);

/**
 * Persistent Panel
 * Template for side panel where clipbins and video specific data (metadata,
 * play feed data, smart insights, related views) content will be passed in.
 */
@Component({
  selector: 'mam-persistent-panel',
  templateUrl: './persistent_panel.ng.html',
  styleUrls: ['./persistent_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersistentPanel implements AfterViewInit, OnDestroy {
  @HostBinding('class.collapsed')
  get isCollapsed() {
    return !this.expanded;
  }

  @HostBinding('style.width')
  get getWidth() {
    return this.width;
  }

  @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;

  @ViewChild(MatSidenav) sideNav?: MatSidenav;

  readonly HomeView = HomeView;

  selectedTabIndex?: number;

  /** Whether the panel is expanded and its contents are visible. */
  expanded = true;

  /** Used to dynamically move the resize handle. */
  freeDragPosition = {x: 0, y: 0};

  displayedTabs: PersistentTab[] = [];

  @HostBinding('class.staging-view') isStagingView: boolean = false;

  hidePersistentPanel:boolean = false;

  constructor(
      private readonly cdr: ChangeDetectorRef,
      private readonly host: ElementRef<HTMLElement>,
      private readonly preferences: PreferencesService,
      private readonly feature: FeatureFlagService,
      private readonly dialog: MatDialog,
      readonly stateService: StateService,
      readonly stagingService: StagingService,
      readonly metadataService: MetadataService,
      @Optional() private readonly vodSearchService?: VodSearchService,
  ) {
    this.monitorCurrentViewAndTab();

    // Whenever the VoD segment search changes, update the list of keywords to
    // reflect the chips typed.
    this.vodSearchService?.searchQuery$
        .pipe(
            takeUntil(this.destroyed$),
            filter(
                search => search.searchMode === SearchMode.SEGMENT &&
                    search.chips.length > 0))
        .subscribe(search => {
          this.stateService.currentKeywords$.next(search.chips);
        });

    this.stateService.togglePersistentPanel$.pipe(takeUntil(this.destroyed$))
        .subscribe(expand => {
          if (this.expanded === expand) return;
          this.cdr.markForCheck();
          this.expanded = expand;

          if (this.expanded) {
            // Update ink position when the panel is expanded.
            this.tabGroup?.realignInkBar();
          }
          // Update resize handle position after `this.expanded` is applied.
          setTimeout(() => this.refreshPanelSize(), 0);
        });

    this.stateService.currentView$.pipe(takeUntil(this.destroyed$)).subscribe(view => {
      this.isStagingView = view === HomeView.STAGING;
      this.cdr.markForCheck();
    });

    // Apply the user's preferred width.
    const preferredWidth = this.preferences.load('user_panel_width');
    if (preferredWidth) {
      this.width = preferredWidth;
    }

    // Hide if on mobile device
    if (window.innerWidth <= 1024) this.stateService.togglePersistentPanel$.next(false);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onWidthChange(width: number) {
     this.stateService.togglePersistentPanel$.next(width >= 1024);
  }

  ngAfterViewInit() {
    // Ensure that the resize handle is properly placed when the window
    // size changes, which may change this panel size.
    fromEvent(window, 'resize')
        .pipe(
            takeUntil(this.destroyed$), debounceTime(500), startWith(undefined))
        .subscribe(() => {
          this.cdr.markForCheck();
          this.refreshPanelSize();
        });

    this.sideNav?.open();
  }

  selectTab(index: number) {
    assertTruthy(
        index >= 0 && index < this.displayedTabs.length,
        `Tab index ${index} is out of bounds.`);
    const type = this.displayedTabs[index];
    this.stateService.currentPersistentTab$.next(type);
  }

  getCollapseMessage() {
    return `${this.expanded ? 'Collapse' : 'Expand'} panel`;
  }

  getAssetsMetadataTooltip(): string {
    return `${this.isStagingView ? 'Staging' : 'Assets'} Metadata`;
  }

  /** Sets panel width to match mouse position while handle is dragged. */
  resizePanel(e: CdkDragMove) {
    const handleRight =
        e.source.element.nativeElement.getBoundingClientRect().right;
    const hostRight = this.host.nativeElement.getBoundingClientRect().right;
    const newWidth = `${hostRight - handleRight}px`;
    this.width = newWidth;
  }

  /**
   * Updates the handle position to match the panel width, since it may have
   * been dropped in an invalid position outside the panel's min and max width.
   */
  refreshPanelSize() {
    const pixelWidth = this.host.nativeElement.getBoundingClientRect().width;

    // Move handle to match the current panel width.
    this.freeDragPosition = {x: -pixelWidth, y: 0};

    return pixelWidth;
  }

  /** Saves panel width for future retrieval and updates the handle position. */
  saveAndRefreshPanelSize() {
    const pixelWidth = this.refreshPanelSize();

    const width = `${pixelWidth}px`;

    // Persist current width to user preferences.
    this.preferences.save('user_panel_width', width);
  }

  // Unsubscribe from pending subscriptions.
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openClipMoveDialog(event: ClipMoveEvent) {
    this.dialog.open(MoveClipDialog, MoveClipDialog.getDialogOptions({
      clips: [event.clip],
      action: event.action,
    }));
  }

  isMobilePlatform() {
    const ua = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
      return true;

    return false;
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);

  /**
   * Width of this component updated by moving the resize handle. Will be bound
   * between min-width and max-width regardless of its value by CSS.
   */
  private width?: string;
  private readonly allTabs = this.getAllEnabledTabs();

  private monitorCurrentViewAndTab() {
    combineLatest([
      this.stateService.currentView$, this.stateService.currentPersistentTab$
    ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([view, tab]) => {
          this.cdr.markForCheck();

          this.displayedTabs =
              this.allTabs.filter(tab => VIEW_TABS.get(view)?.includes(tab));

          /**
           * Determines whether to hide the persistent panel based on the current view and tab.
           *
           * If the panel is not hidden, it will remain visible if the current view is `HomeView.SEARCH_RESULTS`
           * and the tab is not `'staging'`. Otherwise, the panel will be hidden.
           *
           * @param {HomeView} view The current view.
           * @param {string} tab The current tab.
           */
          const activeItems = this.stagingService.getActive();
          this.hidePersistentPanel = !(view === HomeView.SEARCH_RESULTS && tab !== 'staging' && !(activeItems?.assets?.length));

          // If current tab exists the keep it.
          if (this.displayedTabs.includes(tab)) {
            this.selectedTabIndex = this.displayedTabs.indexOf(tab);
            return;
          }

          // Default to clipbins tab.
          if (this.displayedTabs.includes('clipbins')) {
            this.selectedTabIndex = this.displayedTabs.indexOf('clipbins');
            return;
          }

          // Default to last available tab. Otherwise, return -1;
          this.selectedTabIndex = this.displayedTabs.length - 1;
        });
  }

  private getAllEnabledTabs() {
    const tabs = [...ALL_TABS];
    if (this.feature.featureOff('use-persistent-related-views')) {
      return tabs.filter(tab => tab !== 'related-views');
    }
    return tabs;
  }
}
