import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';

import {assertTruthy} from 'asserts/asserts';

import {AssetService, Clip} from '../services/asset_service';
import {SnackBarService} from '../services/snackbar_service';
import {ClipbinStorageService} from '../services/storage/clip_bin_storage.service';
import {UtilsService} from '../services/utils_service';
import {SharedLinkClipBinService} from '../shared_clipbin/services/shared_link_clipbin.service';

/**
 * Represents a button that given all the inputs can perform copy/move action on
 * the clip.
 * Used by component `MoveClipDialog`.
 */
@Component({
  selector: 'mam-move-clip-button',
  templateUrl: './move_clip_button.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveClipButton {
  @Input() action!: MoveClipAction;

  @Input() clips!: Clip[];

  /** Names of clip bins to move/copy the clip. */
  @Input() selectedBins: string[] = [];

  /**
   * Emits when user clicks the active button. The emitted observable itself
   * will emit once the action succeeds or fails.
   */
  @Output()
  readonly actionTriggered = new EventEmitter<ReplaySubject<boolean>>();

  actionInProgress = false;

  constructor(
      private readonly snackBar: SnackBarService,
      private readonly assetService: AssetService,
      private readonly cdr: ChangeDetectorRef,
      private readonly utils: UtilsService,
      private readonly router: Router,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      private readonly clipbinStorageService: ClipbinStorageService,
  ) {}

  executeAction() {
    this.populateTrackIndexes();

    this.actionTriggered.emit(this.actionDone$);
    if (this.action === 'move') {
      this.moveClips();
      return;
    }
    this.copyClips();
  }

  getActionName() {
    return this.action === 'move' ? 'Move' : 'Copy';
  }

  /** Emits when the action finishes (and whether it fully succeeded). */
  private readonly actionDone$ = new ReplaySubject<boolean>(1);

  private moveClips() {
    assertTruthy(
        this.selectedBins.length === 1, 'Exactly 1 bin should be selected');
    const selectedBin = this.selectedBins[0];

    this.actionInProgress = true;

    this.utils
        .batchApiCalls(
            this.clips,
            clip => this.assetService.moveClip(clip.name, selectedBin))
        .subscribe(results => {
          this.cdr.markForCheck();
          this.actionInProgress = false;

          this.actionDone$.next(false);

          this.updateClipBinSharedLinkOnMove(selectedBin);
          this.updateClipBinsInfoOnMove(results, selectedBin);

          const numberOfFailures =
              results.reduce((total, success) => total + (success ? 0 : 1), 0);

          // Success cases, may print
          // - "Clip moved to the selected clip bin successfully"
          // - "5 clips moved to the selected clip bin successfully"
          if (numberOfFailures === 0) {
            this.actionDone$.next(true);
            const clipsLabel = this.utils.pluralize(
                this.clips.length, 'Clip', `${this.clips.length} clips`);
            const message =
                `${clipsLabel} moved to the selected clip bin successfully.`;
            this.snackBar.message(message, 'OPEN BIN')
                .onAction()
                .subscribe(() => {
                  this.router.navigate(
                      ['clipbin', selectedBin],
                      {queryParamsHandling: 'preserve'});
                });
            return;
          }

          // Error cases, may print
          // - "The clip could not be moved, please try again."
          // - "5 clips could not be moved, please try again."
          this.actionDone$.next(false);
          const copyLabel = this.utils.pluralize(
              numberOfFailures, 'The clip', `${numberOfFailures} clips`);
          this.snackBar.error(
              `${copyLabel} could not be moved, please try again.`);
        });
  }

  private copyClips() {
    this.actionInProgress = true;

    this.utils
        .batchApiCalls(
            this.clips,
            clip => {
              const {original, startTime, endTime, title, trackIndex} = clip;
              return this.assetService.createClipInMultipleBins(
                  original, this.selectedBins, startTime, endTime, title, trackIndex);
            })
        .subscribe(failuresPerCall => {
          const numberOfFailures = failuresPerCall.reduce(
              (total, callFailures) => total + callFailures, 0);

          this.cdr.markForCheck();
          this.actionInProgress = false;

          this.updateClipBinSharedLinkOnCopy();

          // Success cases, may print
          // - "Clip copied to the selected clip bin successfully"
          // - "5 clips copied the selected clip bin successfully"
          // - "Clip copied to 4 clip bins successfully"
          // - "5 clips copied to 4 clip bins successfully"
          if (numberOfFailures === 0) {
            this.actionDone$.next(true);
            const clipsLabel = this.utils.pluralize(
                this.clips.length, 'Clip', `${this.clips.length} clips`);
            const clipbinsLabel = this.selectedBins.length > 1 ?
                `${this.selectedBins.length} clip bins` :
                `the selected clip bin`;
            const message =
                `${clipsLabel} copied to ${clipbinsLabel} successfully.`;
            // If the clips were added to only 1 bin, show a snackbar action to
            // open it.
            if (this.selectedBins.length > 1) {
              this.snackBar.message(message);
            } else {
              this.snackBar.message(message, 'OPEN BIN')
                  .onAction()
                  .subscribe(() => {
                    this.router.navigate(
                        ['clipbin', this.selectedBins[0]],
                        {queryParamsHandling: 'preserve'});
                  });
            }
            return;
          }

          // Error cases, may print
          // - "The clip could not be copied, please try again."
          // - "5 clips could not be copied, please try again."
          this.actionDone$.next(false);
          const copyLabel = this.utils.pluralize(
              numberOfFailures, 'The clip', `${numberOfFailures} clips`);
          this.snackBar.error(
              `${copyLabel} could not be copied, please try again.`);
        });
  }

  private collectTrackIndexes() {
    return new Map(this.clipbinStorageService.clipsList.map(i => [i.name, i.audioTrack.current]));
  }

  private updateClipBinSharedLinkOnMove(selectedBin: string) {
    const affectedBins =  this.clips.map(clip => clip.label);
    const affectedUniqueBins = [...new Set(affectedBins), selectedBin];
    affectedUniqueBins.forEach((bin) => {
      this.sharedLinkClipBinService.updateClipBinSharedLinkIfExist(bin);
    });
  }

  private updateClipBinSharedLinkOnCopy() {
    this.selectedBins.forEach((bin) => {
      this.sharedLinkClipBinService.updateClipBinSharedLinkIfExist(bin);
    });
  }

  private updateClipBinsInfoOnMove(moveResult: (Clip | null)[], selectedBin: string) {
    const trackIndexes = this.collectTrackIndexes();
    const clipsToUpdate: Clip[] = [];
    moveResult.forEach(clip => {
      if (clip !== null && trackIndexes.has(clip.name)) {
        clipsToUpdate.push(clip);
      }
    });
    if (!clipsToUpdate.length) {
      return;
    }

    this.clipbinStorageService.updateClipsInBin(clipsToUpdate, trackIndexes, selectedBin);
    this.clipbinStorageService.removeClips(clipsToUpdate.map(clip => clip.name));
  }

  private populateTrackIndexes() {
    const trackIndexes = this.collectTrackIndexes();
    this.clips.forEach(clip => clip.trackIndex = trackIndexes.get(clip.name));
  }
}

/** Clip action types. */
export type MoveClipAction = 'copy'|'move';
