import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mam-access-restriction-icon',
  templateUrl: './access-restriction-icon.component.html',
  styleUrl: './access-restriction-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessRestrictionIconComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
}
