import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { from, interval, Observable } from 'rxjs';
import { delayWhen, mergeMap, reduce, tap } from 'rxjs/operators';

import { AuthService } from 'auth/auth_service';
import { SharedLink } from 'models';

import { ErrorResponse } from '../error_service/error_response';
import { ErrorService } from '../error_service/error_service';
import { FirebaseFirestoreDataService } from '../firebase/firebase_firestore_data_service';

import { makeFakeClip, makeFakeOriginal } from './asset_api_fake_service';
import { getStartTimecode, getStateInfo } from './asset_api_service';
import { Asset, Original } from './asset_service';
import { prepareCreateLinkData, SharedLinksApiService } from './shared_links_api_service';

/** Returns the location origin. */
export const LOCATION_ORIGIN = new InjectionToken<string>(
    'Location origin', {factory: () => window.location.origin});

/** Possible number of days before a shared link expires. */
export type ExpirationDaysOption = 30|7|1|0;

/** Max expiration days allowed by BE API. */
export const MAX_EXPIRATION_DAYS = 30;

export const EXPIRE_DATE_FORMAT = 'MMM dd, yyyy - HH:mm';

export const NEVER_EXPIRED_TTL = 'Never';

/**
 * Provides capabilities to generate and access asset shared links.
 */
@Injectable({providedIn: 'root'})
export class SharedLinksService {
  constructor(
      private readonly sharedLinksApi: SharedLinksApiService,
      private readonly route: ActivatedRoute,
      private readonly errorService: ErrorService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly authService:AuthService,
      @Inject(LOCATION_ORIGIN) private readonly origin: string,
  ) {}

  /**
   * Possible number of days before a shared link expires.
   * Value '0' is considered as 'never expired'.
   */
  readonly expirationDaysOptions: ExpirationDaysOption[] = this.authService.isAdmin? [30, 7, 1,0] : [30, 7, 1];

  readonly defaultExpirationDays: ExpirationDaysOption = 30;

  prepareCreateLinkData(
      asset: Asset,
      ttl: string,
      additionalProperties?: Record<string, string>,
  ) {
    return prepareCreateLinkData(asset, ttl, additionalProperties);
  }

  createLink(asset: Asset, additionalProperties?: Record<string, string>):
      Observable<SharedLink|ErrorResponse> {
    return this.sharedLinksApi
        .create(
            asset,
            this.daysToTtl(this.defaultExpirationDays),
            additionalProperties,
            )
        .pipe(this.errorService.catchError());
  }

  createFullClipLink(asset: Asset, additionalProperties?: Record<string, string>):
      Observable<SharedLink|ErrorResponse|null> {
    return this.sharedLinksApi
        .createFullClip(
            asset,
            this.daysToTtl(this.defaultExpirationDays),
            additionalProperties,
            )
        .pipe(this.errorService.catchError());
  }

  /** Check if the clip is a full video. */
  isFullClip(endTime: number | string, duration: number | string): boolean {
    return endTime === duration;
  }

  /** Get hash of the original video link. */
  getOriginalHash(link: SharedLink) {
    const hash = this.encodeLink(link.name);
    return `?originalHash=${hash}`;
  }

  getLinkUrl(link: SharedLink) {
    const hash = this.encodeLink(link.name);
    // Location origin is such as `https://ias.google.com`
    // Shared URL is such as `https://ias.google.com/shared/HASH`
    return `${this.origin}/shared/${hash}`;
  }

  getClipBinLinkUrl(clipBinName: string) {
    // Location origin is such as `https://ias.google.com`
    // Shared CLip Bin URL is such as `https://ias.google.com/shared-clipbin/clipBinName`
    return `${this.origin}/shared-clipbin/${clipBinName}`;
  }

  getClipBinAssetLinkHash(link: SharedLink) {
    return this.encodeLink(link.name);
  }

  /**
   * Updates shared link ttl by calling the underlying service.
   * Processes 'never expired' mark on each successfully update.
   *
   * @param linkName shared link name.
   * @param newExpirationDays new 'expiration days' (ttl) value.
   */
  updateLinkTtl(linkName: string, newExpirationDays: ExpirationDaysOption):
      Observable<SharedLink|null> {
    const ttl = this.daysToTtl(newExpirationDays);
    return this.sharedLinksApi.updateExpiration(linkName, ttl)
        .pipe(
          tap(update => this.processExpirationDateNever(newExpirationDays, update?.name)));
  }

  /**
   * Processes expiration date 'never'.
   * Creates 'shared link never expired' entity in case of the expiration days equals 0
   * (that is considered as 'never expired').
   * Otherwise, deletes 'shared link never expired' item/record (that possibly was created before).
   *
   * @param expirationDays expiration days.
   * @param name shared link name.
   */
  processExpirationDateNever(expirationDays: ExpirationDaysOption, name?: string) {
    if (!name) {
      return;
    }

    if (expirationDays === 0) {
      const ttlInMillis = MAX_EXPIRATION_DAYS * 24 * 3600 * 1000;
      const expireTime = new Date(Date.now() + ttlInMillis);
      this.dataService.createSharedLinkNeverExpired({name, expireTime})
          .catch(error => this.errorService.handle(`Can't store expiration date 'never': ${error}`));
    } else {
      this.dataService.deleteSharedLinkNeverExpired(name)
          .catch(error => this.errorService.handle(`Can't delete expiration date 'never': ${error}`));
    }
  }

  getPreviewFromHash(hash: string) {
    const linkName = this.decodeLink(hash);
    return this.getPreview(linkName);
  }

  getPreview(linkName: string) {
    return this.sharedLinksApi.preview(linkName);
  }

  search(titleQuery: string, pageSize: number, pageToken?: string) {
    return this.sharedLinksApi.list(titleQuery, pageSize, pageToken);
  }

  /**
   * Revoke all given links by making parallel requests (spaced out by 20ms to
   * cap the QPS), and returns the number of deletions that succeeded.
   */
  revokeAll(linkNames: string[]): Observable<number> {
    return from(linkNames).pipe(
        delayWhen((linkName, index) => interval(index * 20)),
        mergeMap(linkName => {
          return this.sharedLinksApi.delete(linkName);
        }),
        reduce((revokedCount, deletedLink) => {
          return revokedCount + (deletedLink != null ? 1 : 0);
        }, 0));
  }

  /** Whether the current page is a public shared link. */
  isSharedLinksPage() {
    const route = this.route.firstChild || this.route;
    return route.snapshot.data['type'] === 'shared';
  }

  /**
   * Converts a number of days to a Time to Live in seconds.
   * Replaces value '0' (that is considered as 'never expired') with the
   * max allowed value.
   */
  daysToTtl(days: ExpirationDaysOption): string {
    days ||= MAX_EXPIRATION_DAYS;
    return `${days * 3600 * 24}s`;
  }

  private encodeLink(linkName: string) {
    return btoa(linkName);
  }

  private decodeLink(hash: string) {
    return atob(hash);
  }

  formatExpireTime(link: SharedLink) {
    const isoTime = link.expireTime;
    if (isoTime) {
      link.expireTime = DateTime.fromISO(isoTime).toFormat(EXPIRE_DATE_FORMAT);
    }
  }
}

/** Converts an `ApiSharedLink` to a UI-compatible `Asset` type. */
export function convertApiSharedLinkToUiAsset(link: SharedLink): Asset {
  const startOffset = Number(link.startOffset.replace('s', ''));
  const endOffset = Number(link.endOffset.replace('s', ''));

  const stateInfo = getStateInfo(link);

  // Properties used by the details component
  const playbackProperties: Partial<Original> = {
    title: link.title,
    renditions: link.renditions,
    duration: endOffset - startOffset,
    startTime: startOffset,
    endTime: endOffset,
    startTimecode: getStartTimecode(link.snippet),
    thumbnail: undefined,
    ...stateInfo,
  };

  // Make sure that the asset computed from a shared link has a `original`
  // property, so it is considered a Clip by our code.
  return link.clip ?
    makeFakeClip(playbackProperties, playbackProperties) :
    makeFakeOriginal(playbackProperties);
}
