<mat-toolbar class="upper-toolbar">
    <a class="title">
        <span class="product-icon" aria-hidden="true">
            <img ngSrc="images/ias_logo_medium.svg" alt="IAS logo" height="35" width="35" />
        </span>
        <h1>Intelligent Asset Service</h1>
    </a>

    <mat-divider [vertical]="true" aria-hidden="true"></mat-divider>

    <!-- Placeholder content -->
    <ng-template #placeholder>
        <div class="breadcrumbs">
            <mat-icon class="separator" aria-hidden="true">subscriptions</mat-icon>
            <h2>Loading Clip bin...</h2>
        </div>
    </ng-template>

    <!-- Breadcrumbs -->
    <div class="center" *ngIf="clipBinTitle ; else placeholder">
        <div class="breadcrumbs">
            <div class="breadcrumbs_bin">
                <mat-icon class="separator" aria-hidden="true">subscriptions</mat-icon>
                <div class="breadcrumbs_text">
                  <h2 matTooltip="test-share-clip-bin" matTooltipPosition="below" matTooltipClass="mobile-tooltip">
                    {{ clipBinTitle }}
                  </h2>
                  <div class="breadcrumbs_clip" *ngIf="linkTitle">
                    <mat-icon class="separator" aria-hidden="true">chevron_right</mat-icon>
                    <h3
                      [attr.title]="linkTitle"
                      matTooltip="{{linkTitle}}"
                      matTooltipPosition="below"
                      matTooltipClass="mobile-tooltip">
                      {{linkTitle}}
                    </h3>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="right shared-link-download-buttons" *ngIf="link?.downloadable || originalHash">
        <a #downloadAnchor>
            <button
                class="download-original-button"
                mat-flat-button
                mam-firebase-ga="Download original video file"
                *ngIf="!rawSourceUrl"
                [mam-firebase-resource]="link?.name"
                [matTooltip]="getDownloadTooltip()"
                (click)="downloadFile()"
            >
                <mat-icon aria-hidden="true">download</mat-icon>
                Download
            </button>

            <button
                class="download-original-button"
                mat-flat-button
                aria-label="Download menu"
                *ngIf="rawSourceUrl"
                [matMenuTriggerFor]="downloadMenu"
            >
                <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
                Download
            </button>
            <mat-menu #downloadMenu="matMenu">
                <button
                    mat-menu-item
                    mam-firebase-ga="Download mezzanine video file"
                    [mam-firebase-resource]="link?.name"
                    [matTooltip]="getDownloadTooltip()"
                    (click)="downloadFile()"
                >
                    Mezzanine
                </button>

                <button
                    mat-menu-item
                    mam-firebase-ga="Download raw video file"
                    [mam-firebase-resource]="link?.name"
                    matTooltip="Download raw video file"
                    (click)="downloadFile(true)"
                >
                    Raw source
                </button>
            </mat-menu>
        </a>
    </div>
</mat-toolbar>

<main class="shared-clipbin" #scrollView>
    <!-- Details view -->
    <div class="main-block shared-clipbin_main">
      <div class="navigation">
        <button
          mam-firebase-ga="Navigate to previous asset in shared link"
          [mam-firebase-resource]="link?.asset"
          mat-icon-button
          aria-label="Previous"
          class="gmat-button"
          (click)="navigatePrevious()" [disabled]="currentIndex === 0"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          mam-firebase-ga="Navigate to next asset in shared link"
          [mam-firebase-resource]="link?.asset"
          mat-icon-button
          aria-label="Next"
          class="gmat-button"
          (click)="navigateNext()" [disabled]="currentIndex === clipList.length - 1"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <router-outlet *ngIf="(navigationService.reload$ | async) === false"> </router-outlet>
    </div>

    <aside class="shared-clipbin_panel panel-body">
        <div class="clips-search-bar-container searchbar">
            <div class="searchbar_header">
                <h2 class="searchbar_header__title">Clips total: <span>{{ clipList.length || 0 }}</span></h2>
            </div>

            <!-- Clips search bar -->
            <div class="searchbar_input">
                <mat-icon class="separator icon" aria-hidden="true">search</mat-icon>
                <input
                    type="text"
                    placeholder="Search clip links"
                    aria-label="Search clip links"
                    autocomplete="off"
                    [formControl]="sharedClipsSearchControl"
                />
                <button class="searchbar_input__clear" (click)="clearClipSearch()">
                    <mat-icon class="separator" aria-hidden="true" *ngIf="sharedClipsSearchControl?.value?.length"
                        >close</mat-icon
                    >
                </button>
            </div>
        </div>

        <!-- Clips list -->
        <ng-container *ngIf="filteredClips$ | async as clips">
            <ng-container *ngIf="clips.length;">
                <div
                    infinite-scroll
                    class="clips panel-scroll-view"
                    cdkDropList
                    cdkDropListAutoScrollStep="6"
                >
                    <!-- Clip item -->
                    <mat-card *ngFor="let clip of clips; let idx = index"
                        #clipCard
                        [id]="clip.title"
                        appearance="outlined"
                        class="clips_item"
                        [class.active]="clip.link === linkHash"
                        (click)="clickClipCard(clip.link, idx)"
                    >
                        <mat-icon
                            class="separator"
                            aria-hidden="true"
                            >video_file</mat-icon
                        >

                        <div class="clips_item__info">
                            <h4 class="clips_item__title" [attr.title]="clip.title">{{ clip.title }}</h4>
                            <span class="clips_item__time">{{ clip.duration | duration }}</span>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="!clipList.length" class="empty-clips">
          <h3 *ngIf="loadingClips$ | async">Loading...</h3>
          <h3 *ngIf="(loadingClips$ | async) === false">No clips found or added to this clip bin.</h3>
        </div>

    </aside>
</main>
