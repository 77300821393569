<ng-container *ngIf="assets.length">
  <ng-container *ngIf="{
  isEditing: metadataService.isEditing$| async,
  isLoading: stateService.isPanelLoading$ | async,
  resourceKey: generateResourceKey(assets)
} as tplState">
    <div class="panel-header">
      <h1>Metadata {{assets.length > 1 ? '('+assets.length+')': ''}}</h1>
      <!-- Edit/Save buttons -->
      <ng-container *ngIf="metadataService.fields.length || schemaSelection">
        <div
            *ngIf="!tplState.isEditing"
            matTooltip="Editing is disabled for upcoming, airing or approved live assets"
            [matTooltipDisabled]="!isEditDisabled(assets)">
          <button
              [disabled]="isEditDisabled(assets)"
              class="edit"
              mat-button
              mam-firebase-ga="Start metadata changes"
              [mam-firebase-resource]="tplState.resourceKey"
              (click)="metadataService.edit()">
            Edit
          </button>
        </div>
        <button
            *ngIf="!tplState.isEditing && closePanel.observed"
            class="close"
            mat-icon-button
            aria-label="Close panel"
            mam-firebase-ga="Close metadata panel"
            [mam-firebase-resource]="tplState.resourceKey"
            (click)="closePanel.emit()">
          <mat-icon class="gmat-icon">close</mat-icon>
        </button>
        <button
            *ngIf="tplState.isEditing"
            class="cancel"
            mat-button
            mam-firebase-ga="Cancel metadata changes"
            [mam-firebase-resource]="tplState.resourceKey"
            [disabled]="tplState.isLoading"
            (click)="metadataService.cancel()">
          Cancel
        </button>
        <button
            *ngIf="tplState.isEditing"
            class="save"
            mat-button
            mam-firebase-ga="Save metadata changes"
            [mam-firebase-resource]="tplState.resourceKey"
            [disabled]="!metadataService.isValid() || tplState.isLoading"
            (click)="metadataService.save()">
          Save
        </button>
      </ng-container>

      <!-- Sync button-->
      <ng-container *featureFlagOn="'use-sync-metadata'">
        <div
            *ngIf="showSyncButton && getSingleAsset(assets) as asset"
            matTooltip="Sync is disabled for upcoming, airing or approved live assets"
            [matTooltipDisabled]="!isEditDisabled(assets)">
          <button
              mat-icon-button
              aria-label="Open sync metadata dialog"
              mam-firebase-ga="Open sync metadata dialog"
              [mam-firebase-resource]="asset.name"
              [disabled]="isEditDisabled(assets) || tplState.isEditing"
              matTooltip="Sync metadata"
              (click)="openSyncMetadataDialog(asset)">
            <mat-icon>cloud_sync</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <!-- List of fields and special items -->
    <div class="panel-scrollview" [class.loading]="tplState.isLoading">

      <!-- Special embedded player -->
      <ng-container
          *ngIf="playerVisible && getSingleAsset(assets) as asset">
        <mam-player-with-controls
            [asset]="asset"
            [compact]="true"
            [disabledMarking]="true"
            [disabledAddClip]="true"
            [disabledShortcuts]="(isAnyDialogOpen$ | async)!"
            [signer]="signer"
            (playerError)="onPlayerError(asset, $event)">
        </mam-player-with-controls>
      </ng-container>

      <!-- Special schema selector -->
      <ng-container *ngIf="schemaSelection">
        <mam-select-schema
            [schemas]="schemas"
            [placeholder]="tplState.isEditing ? 'Choose a schema' : 'No schema'"
            [resourceKey]="tplState.resourceKey"
            [control]="metadataService.selectedSchema"
            [metadataFieldStyle]="true">
        </mam-select-schema>
      </ng-container>

      <!-- Sections available only when there is one selected asset -->
      <ng-container *ngIf="getSingleAsset(assets) as asset">

        <!-- Special video information fields -->
        <mat-expansion-panel
            *ngIf="!asset.isLive"
            mam-firebase-ga="Toggle video format information"
            [disabled]="tplState.isEditing"
            [expanded]="videoFormatExpanded && !tplState.isEditing"
            (opened)="videoFormatExpanded = true"
            (closed)="videoFormatExpanded = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Video format information {{tplState.isEditing ? 'is not editable' : ''}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="pseudo-field" *ngFor="let videoFormatField of videoFormatFields">
              <div class="pseudo-field-label">{{videoFormatField}}</div>
              <div class="info-field-content">
                <div class="info-title">{{getVideoFormatValue(asset, videoFormatField)}}</div>
              </div>
            </div>
        </mat-expansion-panel>

        <!-- Special asset location item -->
        <div class="pseudo-field location-field">
          <div class="pseudo-field-label">Storage</div>
          <div class="info-field-content">
            <mam-asset-location-status
                [asset]="asset"
                [onpremOperation]="true"
                [displayLocationText]="true">
            </mam-asset-location-status>
          </div>
        </div>

        <!-- Mezzanine asset location item -->
        <div
            class="pseudo-field location-field"
            *ngIf="!asset.original && asset.rawSourceUrl">
          <div class="pseudo-field-label">raw source location</div>
          <div class="info-field-content">
            <mam-asset-location-status
                [asset]="asset"
                [checkRawSource]="true"
                [onpremOperation]="true"
                [displayLocationText]="true">
            </mam-asset-location-status>
          </div>
        </div>

        <!-- Special parent asset link for clips -->
        <div class="pseudo-field" *ngIf="asset.original">
          <div class="pseudo-field-label">Original Asset</div>
          <div class="info-field-content parent-link">
            <div class="info-title">Show me full source asset</div>
            <button
                mat-icon-button
                type="button"
                aria-label="Open parent asset"
                [routerLink]="['/asset', asset.original.name]"
                [queryParams]="{type: undefined}"
                queryParamsHandling="merge"
                mam-firebase-ga="Navigate to parent asset from metadata panel"
                [mam-firebase-resource]="asset.name">
              <mat-icon class="gmat-icon material-icons-outlined">movie
              </mat-icon>
            </button>
          </div>
        </div>

        <!-- Special optional extra actions -->
        <ng-container *ngIf="extraActions">
          <div
              *ngFor="let action of extraActions"
              class="pseudo-field extra-action">
            <div class="pseudo-field-label">{{action.label}}</div>
            <div class="info-field-content">
              <div class="info-title">{{action.text}}</div>
              <button
                  mat-icon-button
                  type="button"
                  [attr.aria-label]="action.description"
                  [mam-firebase-ga]="action.description"
                  [mam-firebase-resource]="asset.name"
                  (click)="extraActionTriggered.emit(action)">
                <mat-icon class="gmat-icon material-icons-outlined">
                  {{action.icon}}
                </mat-icon>
              </button>
            </div>
          </div>
        </ng-container>

        <!-- End of single asset -->
      </ng-container>

        <!-- Restrict assets -->
         <!-- Restrict asset for ADMINS and to original assets
         Not allow restrict clips
         -->
      <ng-container *featureFlagOn="'enable-access-management'">
          <mam-access-restriction-options
              [disabled]="(metadataService.isEditing$ | async) === false"
              *ngIf="authService.isAdmin && !getSingleAsset(assets)?.original"
              (update)="stateService.restrictAssetUpdateFromMetadata$.next(true)"
              [asset]="getSingleAsset(assets)!"
              >
          </mam-access-restriction-options>
      </ng-container>

      <!-- Metadata fields -->
      <mam-metadata-fields></mam-metadata-fields>

      <p
          *ngIf="!metadataService.fields.length && !tplState.isLoading"
          class="no-metadata">
        <ng-container
            *ngIf="assets.length > 1 && !metadataService.selectedSchema.value; else defaultMessage">
          Some assets either have different schemas or don't have a schema at
          all. Please first select the schema from the dropdown above.
        </ng-container>
        <ng-template #defaultMessage>No metadata to display.</ng-template>
      </p>
    </div>
  </ng-container>
</ng-container>
