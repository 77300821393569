import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, take } from 'rxjs';

import { FolderItemRef } from '../../firebase/clip_bins_folders.service';
import { ResourceTypes } from '../../landing/clip-bin-section/service/resource-types';
import { ResourceService } from '../../landing/clip-bin-section/service/resource.service';
import { BinSectionContent } from '../../services/bin.service';
import { SnackBarService } from '../../services/snackbar_service';
import { StateService } from '../../services/state_service';

/** Clipbin deletion dialog */
@Component({
    selector: 'mam-delete-bin-dialog',
    templateUrl: './clipbin_folder_delete_dialog.ng.html',
    styleUrls: ['./clipbin_folder_delete_dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinFolderDeleteDialog implements OnInit, OnDestroy {
    static readonly dialogOptions = {
        hasBackdrop: true
    };
    contextId = window.location.pathname.split('/').pop() || '';
    hasChildrenError$ = new BehaviorSubject<boolean>(false);
    /** Handles on-destroy Subject, used for unsubscribing. */
    private readonly destroyed$ = new Subject<void>();

    constructor(
        readonly dialogRef: MatDialogRef<ClipBinFolderDeleteDialog>,
        private readonly snackBar: SnackBarService,
        private readonly resourceService: ResourceService,
        private readonly stateService: StateService,
        @Inject(MAT_DIALOG_DATA) public data: FolderItemRef
    ) {}

    ngOnInit() {
        if (this.data && this.data.directChildrenCount) this.hasChildrenError$.next(this.data.directChildrenCount > 0);

        this.dialogRef.afterClosed().subscribe((deletionConfirmed) => {
            if (!deletionConfirmed) return;
            this.resourceService
                .deleteResource(ResourceTypes.FOLDER, ResourceTypes.FOLDER, this.data?.id, this.contextId)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        //TODO: remove references in folder
                        this.snackBar.message('Folder has been deleted successfully.');
                        this.stateService.searchModeSelected$.next(BinSectionContent.FOLDER);
                    },
                    error: (error) => {
                        this.snackBar.error('Folder could not be deleted.', undefined, error);
                    }
                });
        });
    }

    isDeleteDisabled() {
        return this.hasChildrenError$.value;
    }

    ngOnDestroy(): void {
        // Unsubscribes all pending subscriptions.
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
