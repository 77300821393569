/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  signal,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  asyncScheduler,
  combineLatestWith,
  concatMap,
  delay,
  EMPTY, finalize,
  map,
  Observable,
  of,
  ReplaySubject,
  scheduled,
  take,
  takeUntil,
  tap
} from 'rxjs';

import { AuthService } from '../auth/auth_service';
import { FirebaseViewsService } from '../firebase/firebase_views_service';
import { ResizeObserverService } from '../services/resize_observer_service';
import { SnackBarService } from '../services/snackbar_service';

import { DeleteViewDialog } from './ui-table-view-delete-dialog/delete-view-dialog';
import { UiTableViewNotFoundDialog } from './ui-table-view-not-found-dialog/ui-table-view-not-found-dialog';
import { UpdateViewDialog } from './ui-table-view-update-dialog/update-view-dialog';
import { ComponeUiTableViewsDialogComponent } from './ui-table-views-dialog/ui-table-views-dialog.component';
import { ComponeUiTableViewsShareDialogComponent } from './ui-table-views-share-dialog/ui-table-views-share-dialog.component';
import { RowEvent, TableCol, TableOpt, TableSort, TableSortDirection } from './ui_table.type';
import { IasTableViewName, IasViewsSharing, IasViewTable, IasViewUser, ViewsShared } from './ui_table_view.interface';

export type TableTypes = 'default' | 'full-video' | 'site-selector' | 'transfer';

@Component({
  selector: 'mam-ui-table',
  templateUrl: './ui_table.ng.html',
  styleUrls: ['./ui_table.scss'],
  //* Turn on ViewEncapsulation.None for avoid ng-deep on _table_mixins
  //* we need to turn this component to get styles from global css class,
  //* for example we use ng-template to create dynamic column and we need to
  //* set comum styles on it, to avoid duplicate class let's use what is done before
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableComponent implements AfterViewInit, AfterContentInit, OnDestroy, OnInit {
  @Input({ required: true }) cols: TableCol[] = [];
  @Input({ required: true }) data: any[] = [];
  @Input() tableType: TableTypes = 'default';
  @Input() options!: TableOpt;
  @Input() emptyMessage: string = 'No data available.';
  @Input() expandedRows = new Set<string>();
  @Input() selectedRows = new Set<string>();
  @Input() activeRows = new Set<string>();

  @Output() rowClick = new EventEmitter<any & RowEvent>();
  @Output() sortClick = new EventEmitter<TableSort>();
  @Output() containerResize = new EventEmitter<number>();

  @ContentChild('headerTpl') headerTpl!: TemplateRef<any>;
  @ContentChild('cellTpl') cellTpl!: TemplateRef<any>;
  @ContentChild('footerTpl') footerTpl!: TemplateRef<any>;
  @ContentChild('pipeTpl') pipeTpl!: TemplateRef<any>;
  @ContentChild('multiTpl') multiTpl!: TemplateRef<any>;
  @ViewChild('uiTable') uiTable!: ElementRef;
  @ViewChild('table') table!: ElementRef;
  // Handles the ths reference and fires a Behavior subject when the
  // value of ths is ready for use.
  @ViewChildren('th') ths: QueryList<ElementRef> = new QueryList();

  displayCols = signal<string[]>([]);
  uiTableWidth: number = 0;
  tableLoaded: boolean = false;
  isTableDirty: boolean = false;
  uiTable$!: Observable<DOMRectReadOnly>;

  sortDirection?: TableSortDirection;
  sortActive?: string;
  @Input() set activeSort(sort: TableSort) {
    this.sortDirection = sort.direction;
    this.sortActive = sort.active;
  }

  viewSelectedName?: string;

  // Cols used for changes
  colsManipulation!: TableCol[];
  // Cols copy of the default view
  colsCopy!: TableCol[];
  // Cols populated with Firebase info
  colsOriginal: TableCol[] = [];
  private readonly destroyed$ = new ReplaySubject<void>(1);
  readonly DEFAULT_VIEW_ID: string = 'default';
  readonly DEFAULT_VIEW_NAME: string = 'Default View';
  viewSelected: string = this.DEFAULT_VIEW_ID;
  viewsOwned: IasTableViewName | undefined;
  viewsSharedWithMe: IasTableViewName | undefined;
  viewUser: IasViewUser = {};
  showViewsIcon: boolean = false;
  // Boolean to highlight "Select View" menu item
  viewListTag: boolean = false;
  // Boolean to highlight "Views Actions" menu item
  actionListTag: boolean = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly renderer: Renderer2,
    private readonly snack: SnackBarService,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly resizeObserver: ResizeObserverService,
    private readonly firebaseViewsService: FirebaseViewsService,
  ) {}

  ngOnInit() {
    /**
     * Keep the copy from original columns to recreate.
     */
    this.colsManipulation = structuredClone(this.cols);
    this.colsCopy = structuredClone(this.cols);
  }

  ngAfterContentInit() {
    this.populateView();
  }

  ngAfterViewInit() {
    if (!this.uiTable) return;
    this.uiTable$ = this.resizeObserver.observe(this.uiTable?.nativeElement);
     this.uiTable$.pipe(
       takeUntil(this.destroyed$),
       map((uiTable) => uiTable.width),
     ).subscribe((value) => {
          this.uiTableWidth = value;
          this.setColsWidth();
          this.containerResize.emit(value);
     });

     // Adjust table when tables are loaded
     this.ths.changes.pipe(
       tap(()=>this.tableLoaded = false),
       finalize(() => this.tableLoaded = true),
       take(1)
     ).subscribe(()=> {
        this.setColsWidth();
        // workaround for responsive table
        this.onResetView();
      });
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Sets the columns to be displayed in the table.
   * It takes into account the hidden columns and sets the columns to be displayed
   * in the order of the `order` property.
   */
  setDisplayCols() {
    const columnsToShowUp: TableCol[] = this.getColumnsNotHidden();
    this.setRemainLastColumnFixed(columnsToShowUp);
    columnsToShowUp.sort((a, b) => a.order - b.order);
    const colKeys = columnsToShowUp.map((c) => c.key);
    this.cols = [...columnsToShowUp];
    this.displayCols.set(colKeys);
  }

  /**
   * Remain the last column with the original order
   *
   * @param columnsToShowUp list of visible columns
   */
  private setRemainLastColumnFixed(columnsToShowUp: TableCol[]) {
    const lastCol = structuredClone(this.colsCopy[this.colsCopy.length - 1]);
    if (lastCol) {
      const lastindex = columnsToShowUp.findIndex((c) => c.key === lastCol.key);
      if (columnsToShowUp[lastindex]) columnsToShowUp[lastindex].order = this.colsCopy.length - 1;
    }
  }

  /**
   * Set Cols Width based on the widths inside colsOriginal object.
   */
  setColsWidth(isResetWidth: boolean = false) {
    setTimeout(() => {
      let widthSum = 0;
      let hasCheckboxColumn: boolean = false;
      const mainElIndex = this.options?.mainColIdx ?? 0;
      const colsBase = this.isDefaultView() ? this.colsCopy : this.colsOriginal;

      this.displayCols().map((column, i) => {
        if (i === mainElIndex && !isResetWidth) return;
        let columnElIdx = 0;
        const columnEl = colsBase.find((el, idx) => {
          columnElIdx = idx;
          return el.key === column;
        });
        // assigns the width
        hasCheckboxColumn = ['checkbox','select', 'icon'].includes(column);
        const elWidth = hasCheckboxColumn ? '46px'
          : columnEl?.headerStyle?.width || '100px';

        // get the template element and applies the width
        const thEl = this.ths.get(i)?.nativeElement;
        if (thEl) this.renderer.setStyle(thEl, 'width', elWidth);
        widthSum = widthSum + Number(elWidth?.replace('px', ''));
        this.colsManipulation[columnElIdx].headerStyle.width = elWidth;
      });

      // set main column width
      const mainEl = this.ths?.get(mainElIndex)?.nativeElement;
      const minWidth =
        colsBase[mainElIndex]?.headerStyle?.minWidth || colsBase[mainElIndex]?.headerStyle?.width || '100px';

      if (this.isDefaultView()) {
        if (!hasCheckboxColumn) widthSum = widthSum + 52;
        const value = ((widthSum + Number(minWidth.replace('px', ''))) < this.uiTableWidth)
          ? this.uiTableWidth - widthSum : Number(minWidth.replace('px', ''));
        const mainWidth = `calc(${value}px - ${5 * (this.ths.length - 1)}px)`;
        if (mainEl) this.renderer.setStyle(mainEl, 'width', mainWidth);
        this.colsManipulation[mainElIndex].headerStyle.width = mainWidth;
      }
      else this.renderer.setStyle(mainEl, 'width', colsBase[mainElIndex]?.headerStyle?.width || '100px');
    });
  }

  /**
   * Reset the size of the columns to the original width
   */
  onResetColsSize() {
    if (this.isDefaultView()) {
      this.setColsWidth();
      return;
    }

    this.displayCols().map((column) => {
        let colBaseColumnIdx = 0;
        const columnEl = this.colsOriginal.find((el, idx) => {
          if (el.key === column) colBaseColumnIdx = idx;
          return el.key === column;
        });
        // assigns the width
       const elWidth = ['checkbox','select', 'icon'].includes(column) ? '46px'
          : columnEl?.headerStyle?.width || '100px';
        // get the template element and applies the width
        this.colsManipulation[colBaseColumnIdx].headerStyle.width = elWidth;
      const thEl = this.ths.find((el) => el.nativeElement.id === column)?.nativeElement;
        this.renderer.setStyle(thEl, 'width', elWidth);
      });

  }

  /**
   * Reset order and width of the columns to the original values
   */
  onResetView() {
    this.colsManipulation = structuredClone(
      this.viewSelected === this.DEFAULT_VIEW_ID ? this.colsCopy : this.mergeColumns(this.colsOriginal),
    );
    this.setDisplayCols();
  }

  /**
   * Change Columns Sort Direction
   */
  onSort(col: TableCol) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortActive = col.key;
    this.sortClick.emit({ direction: this.sortDirection, active: this.sortActive });
  }

  /**
   * Handles columns Drag and drop rearranging the columns order
   *
   * @param e // CdkDragDrop event
   */
  onDrop(e: CdkDragDrop<string[], any, TableCol>) {
    const col = this.cols?.find((c: TableCol, i: number) => i === e.currentIndex && !c?.dragger);
    const { previousIndex, currentIndex } = e;
    if (col || previousIndex === currentIndex) return;
    moveItemInArray(this.displayCols(), previousIndex, currentIndex);
    of('')
      .pipe(delay(500))
      .subscribe({
        next: () => {
          this.reorderColumnsProperties();
        },
      });
  }

  /**
   * Set the new index on the dragged item and reset the order values on array
   *
   */
  private reorderColumnsProperties() {
    this.colsManipulation.forEach((col, index) => {
      const indexToMove = this.displayCols().indexOf(col.key);
      col.order = indexToMove !== -1 ? indexToMove : index;
    });
    const length = this.colsManipulation.length - 1;
    this.colsManipulation[length].order = length;
  }

  /**
   * handles the row click
   *
   * @param row // current row
   * @param event // MouseEvent
   * @param index // index row number
   */
  onRowClick(row: any & RowEvent, event: MouseEvent, index: number) {
    row.event = event;
    row.index = index;
    this.rowClick.emit(row);
  }

  /**
   * Track By Index
   *
   * @param i // index
   */
  trackBy = (i: number) => i;

  /**
   * Get all the active columns, meaning, columns which the 'hidden' field set as false.
   *
   * @returns columns which is not hidden
   */
  private getColumnsNotHidden(): TableCol[] {
    const columns: TableCol[] = [];
    this.colsManipulation.forEach((column) => {
      if (!column.hidden) {
        columns.push(column);
      }
    });
    return columns;
  }

  /**
   * Toggles the visibility of the given column and updates the displayed columns.
   *
   * @param col - The column to toggle.
   */
  addAndRemoveColumns(col: TableCol) {
    col.hidden = !col.hidden;
    this.setDisplayCols();
    this.setColsWidth();
  }

  /**
   * Populates the viewSelected and viewsOwned properties from the 'ias-views-users' Firestore collection.
   * It is called on AfterViewInit lifecycle hook.
   */
  private populateView() {
    this.tableLoaded = false;
    scheduled(this.getViewUser(), asyncScheduler)
      .pipe(
        take(1),
        combineLatestWith(this.getViewsShared().pipe(take(1))),
        concatMap(([viewUser, viewShared]) => {
          viewUser ??= {};
          this.viewUser = viewUser;
          const { viewSelectedId, viewsOwned } = viewUser[this.options.tableInfo.id] ?? {};
          this.viewSelected = viewSelectedId ?? this.DEFAULT_VIEW_ID;
          this.viewsOwned = viewsOwned ?? {};
          this.viewsSharedWithMe = this.buildViewsSharedList(viewShared);
          //if the selected view doesn't exist on viewsSharedWithMe, select the default and pop up the dialog
          if (!(this.viewsOwned[viewSelectedId] || this.viewsSharedWithMe?.[viewSelectedId] || this.viewSelected === this.DEFAULT_VIEW_ID)) {
            this.viewSelected = this.DEFAULT_VIEW_ID;
            this.openViewNotFoundAlert();
          }

          return (this.viewSelected === 'default'
            ? of({ viewTable: { columns: [] } } as unknown as IasViewTable)
            : scheduled(this.getViewsTable(this.viewSelected), asyncScheduler)).pipe(take(1));
        }),
      )
      .subscribe({
        next: (viewTable) => {
          this.colsManipulation = this.mergeColumns(viewTable?.columns ?? []);
          this.setDisplayCols();
          this.showViewsIcon = true;
          this.tableLoaded = true;
        },
      });
  }

  buildViewsSharedList(viewShared: IasViewsSharing[]): IasTableViewName | undefined {
    if (!viewShared?.length) return undefined;

    return viewShared.reduce<IasTableViewName>((acc: IasTableViewName, curr: IasViewsSharing) => {
      acc = { ...acc, [curr.viewId]: { name: curr.viewName } };
      return acc;
    }, {});
  }

  /**
   * Populates the colsManipulation property by merging the columns from 'ias-views' Firestore collection
   * with the columns in the component.
   */
  populateColumns() {
    scheduled(this.getViewsTable(this.viewSelected), asyncScheduler).pipe(take(1))
      .subscribe({
        next: (viewTable) => {
          this.colsManipulation = this.mergeColumns(viewTable?.columns ?? []);
          this.setDisplayCols();
        },
      });
  }

  /**
   * Merges the  given columns from Firestore with the local columns.
   *
   * @param columnsFromFirestore Array of columns retrieved from Firestore.
   * @returns Array of merged columns.
   */
  mergeColumns(columnsFromFirestore: Partial<TableCol>[]) {
    return this.colsManipulation.map((colData, index) => {
      return { ...colData, ...columnsFromFirestore[index] };
    });
  }

  /**
   * Create a partial 'columns' object from the TableCol interface
   * to be saved in 'ias-views' Firestore collection.
   *
   * @param cols The columns to create the partial column objects from.
   * @returns An array of partial column objects.
   */
  private createColumnObjectToSave(cols: TableCol[]): Partial<TableCol>[] {
    return cols.map((c) => {
      const width = c.headerStyle?.width ?? null;
      return {
        key: c.key,
        hidden: !!c.hidden,
        order: c.order,
        headerStyle: {
          width
        },
      };
    }) as Partial<TableCol>[];
  }

  /**
   * Get the view table for the given viewSelected ID.
   *
   * @param viewSelected ID of the view to be retrieved.
   * @returns The view table object.
   */
  async getViewsTable(viewSelected: string): Promise<IasViewTable> {
    const data = await this.firebaseViewsService.getViewById(viewSelected);
    const columns = data.data() as IasViewTable;
    this.colsOriginal = structuredClone(columns?.columns ?? []) as TableCol[];
    if (!columns?.columns?.length) {
      this.deleteAndSetDefaultView(viewSelected);
    }

    return structuredClone(columns);
  }

  private deleteAndSetDefaultView(viewSelected: string) {
    delete this.viewsOwned?.[viewSelected];
    delete this.viewsSharedWithMe?.[viewSelected];
    this.deleteViewFromUserView(viewSelected)
      .subscribe({
        next: () => {
          this.openViewNotFoundAlert();
          this.setViewTableSelected(this.DEFAULT_VIEW_ID, this.DEFAULT_VIEW_NAME);
        }
      });
  }

  openViewNotFoundAlert() {
    this.dialog.open(UiTableViewNotFoundDialog);
  }

  /**
   * Retrieves the user view inside the 'ias-views-users' Firestore collection
   * using the currently authenticated user's email.
   *
   * @returns A promise that resolves to the IasViewUser object. Promise<IasViewUser>
   */
  async getViewUser(): Promise<IasViewUser> {
    const data = await this.firebaseViewsService.getViewsUserById(this.authService.getUserEmail());
    return data.data() as IasViewUser;
  }

  async inactiveViewsShared(viewId: string): Promise<void> {
    await this.firebaseViewsService.deactivateSharedView(viewId);
  }

  /**
   *
   * @returns all views Ids shared with the logged user
   */
  getViewsShared(): Observable<IasViewsSharing[]> {
    return this.firebaseViewsService.getViewSharedWithUser(this.authService.getUserEmail(), this.options.tableInfo.id);
  }

  openViewDialog() {
    this.dialog
      .open(ComponeUiTableViewsDialogComponent)
      .afterClosed()
      .subscribe({
        next: (name) => {
          if (name) {
            this.saveNewView(name);
          }
        },
      });
  }

  /**
   * Creates a new view object, assigns it a unique identifier,
   * and saves it to the 'ias-views-users' Firestore collection.
   *
   * @param name - The name for the new view to be saved. {string}
   */
  async saveNewView(name: string) {
    const columns = this.createColumnObjectToSave(this.colsManipulation);
    const createdBy = this.authService.getUserEmail();
    const view: IasViewTable = {
      columns,
      createdBy,
      fromTable: this.options.tableInfo,
      name,
    };
    const viewId = crypto.randomUUID();
    const viewName: IasTableViewName = { [viewId]: { name } };
    const viewUser: IasViewUser = {
      [this.options.tableInfo.id]: {
        viewsOwned: { ...this.viewsOwned, ...viewName },
        viewSelectedId: viewId,
      },
    };

    const getViewsUser$ = scheduled(this.getViewUser(), asyncScheduler);
    const setDocumentView$ = scheduled(this.firebaseViewsService.setDocumentViews(viewId, view), asyncScheduler);
    const setDocumentViewsUser$ = scheduled(
      this.firebaseViewsService.setDocumentViewsUser(this.authService.getUserEmail(), viewUser),
      asyncScheduler,
    );

    setDocumentView$
      .pipe(concatMap(() => setDocumentViewsUser$.pipe(concatMap(() => getViewsUser$))))
      .subscribe((views) => {
        this.viewsOwned = views[this.options.tableInfo.id].viewsOwned ?? {};
        this.viewSelected = viewId;
        this.viewSelectedName = name;
        this.colsOriginal = structuredClone(this.colsManipulation);
      });
  }

  /**
   * Updates the view selected by the user in the 'ias-views-users' Firestore collection.
   * If the viewId is 'default', resets the columns to the original columns
   * and set the display columns. Otherwise, it will populate the columns from the
   * selected view.
   *
   * @param viewId - The id of the view to be selected. {string}
   * @param viewName The name of the view. {string}
   */
  setViewTableSelected(viewId: string, viewName: string) {
    if (viewId === this.viewSelected) return;

    this.firebaseViewsService.updateFieldFromViewUser(
      this.authService.getUserEmail(),
      `${this.options.tableInfo.id}.viewSelectedId`,
      viewId,
    );
    this.viewSelected = viewId;
    this.viewSelectedName = viewName;

    if (viewId === 'default') {
      this.colsManipulation = structuredClone(this.colsCopy);
      this.setDisplayCols();
    } else {
      this.populateColumns();
    }
    this.onResetView();
  }

  openShareViewDialog(name: string) {

    scheduled(this.firebaseViewsService.getSharedViewById(this.viewSelected), asyncScheduler).subscribe({
      next: (viewsSharing: IasViewsSharing) => {
        const sharedWith: string[] = viewsSharing?.sharedWith ?? [];

        this.dialog
          .open(ComponeUiTableViewsShareDialogComponent, { data: { name, sharedWith }, disableClose: true })
          .afterClosed()
          .pipe(
            concatMap((viewSharing: ViewsShared) => {
              if (viewSharing) {
                const data: IasViewsSharing = {
                  ownerId: this.authService.getUserEmail(),
                  sharedWith: viewSharing.sharedWith,
                  viewName: name ?? '',
                  viewId: this.viewSelected,
                  tableId: this.options.tableInfo.id,
                  isActive: true,
                };

                return scheduled(
                  this.firebaseViewsService.setDocumentSharedView(this.viewSelected, data),
                  asyncScheduler,
                );
              }
              return EMPTY;
            }),
          )
          .subscribe({
            next: () => this.snack.message('Shared!'),
            error: () => this.snack.error('Something went wrong, Try again!'),
          });
      },
    });
  }

  openDeleteViewDialog() {
    const viewId = this.viewSelected;
    this.dialog
      .open(DeleteViewDialog)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.setViewTableSelected(this.DEFAULT_VIEW_ID, this.DEFAULT_VIEW_NAME);
          this.deleteView(viewId);
        }
      });
  }

  /**
   * Deletes the view with the specified viewId.
   * It updates the 'ias-views-users' Firestore collection and removes the view from 'ias-views' Firestore collection.
   *
   * @param viewId - The ID of the view to be deleted. {string}
   */
  deleteView(viewId?: string) {
    if (!viewId) return;

    if (this.viewsOwned?.[viewId]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.viewsOwned[viewId];
    }
    const deleteView$ = scheduled(this.firebaseViewsService.deleteViewDocById(viewId), asyncScheduler);
    const deleteUserView$ = this.deleteViewFromUserView(viewId);
    const getViewUser$ = scheduled(this.getViewUser(), asyncScheduler);
    const inactiveViewShared$ = scheduled(this.inactiveViewsShared(viewId), asyncScheduler);

    deleteView$
      .pipe(
        concatMap(() => deleteUserView$.pipe(
          concatMap(() => inactiveViewShared$.pipe(
            concatMap(() => getViewUser$))
          )
        )),
      )
      .subscribe((viewsUser) => {
        this.viewsOwned = viewsUser[this.options.tableInfo.id].viewsOwned ?? {};
        this.snack.message('Deleted');
      });
  }

  private deleteViewFromUserView(viewId: string) {
    return scheduled(
      this.firebaseViewsService.deleteFieldFromViewsUser(
        this.authService.getUserEmail(),
        `${this.options.tableInfo.id}.viewsOwned.${viewId}`
      ),
      asyncScheduler
    );
  }

  openDialogToEditSelectedView(name: string) {
    const viewId = this.viewSelected;
    const updateViewName$ = (newName: string) =>
      scheduled(
        this.firebaseViewsService.updateFieldFromViewUser(
          this.authService.getUserEmail(),
          `${this.options.tableInfo.id}.viewsOwned.${viewId}.name`,
          newName,
        ),
        asyncScheduler,
      ).pipe(
        concatMap(() =>
          scheduled(this.firebaseViewsService.updateViewField(viewId, 'name', newName), asyncScheduler)
            .pipe(
              (concatMap(() => scheduled(this.firebaseViewsService.updateNameSharedView(viewId, newName), asyncScheduler)))
            ),
        ),
        concatMap(() => scheduled(this.getViewUser(), asyncScheduler)),
        combineLatestWith(of(newName))
      );

    this.dialog
      .open(ComponeUiTableViewsDialogComponent, {
        data: {
          id: viewId,
          name,
        },
      })
      .afterClosed()
      .pipe(concatMap((newName: string) => (newName ? updateViewName$(newName) : EMPTY)))
      .subscribe({
        next: ([viewsUser, updatedName]) => {
          //set the new name here
          this.viewsOwned = { ...this.viewsOwned, ...viewsUser[this.options.tableInfo.id].viewsOwned };
          this.viewSelectedName = updatedName;
          this.snack.message('Success');
        },
      });
  }

  /**
   * Updates the selected view.
   * Opens a confirmation dialog to ensure the user wants to update the view.
   * If confirmed, it will update the view in both the 'ias-views-users' and 'ias-views' Firestore collections.
   */
  updateSelectedView() {
    const columns = this.createColumnObjectToSave(this.colsManipulation);

    this.dialog
      .open(UpdateViewDialog)
      .afterClosed()
      .pipe(
        concatMap((confirm) =>
          confirm
            ? scheduled(
              this.firebaseViewsService.updateViewField(this.viewSelected, 'columns', columns),
              asyncScheduler,
            )
            : EMPTY,
        ),
      )
      .subscribe({
        next: () => {
          this.snack.message('Success');
          this.colsOriginal = structuredClone(columns) as TableCol[];
        },
      });
  }

  /** Checks if the current view is the default view. */
  isDefaultView(columnCheck: boolean = false): boolean {
    if (columnCheck) {
      return this.colsManipulation.filter((cl) => !!cl.name).every((cl) => !cl.hidden);
    }
    return this.viewSelected === 'default';
  }

  /** Checks if the current view is **identical** to the original view. */
  isViewSame(): boolean {
    const compareObject = JSON.stringify(this.createObjectToCompare(this.colsManipulation));
    const compareOriginalView = JSON.stringify(this.createObjectToCompare(this.colsOriginal));

    return compareObject === compareOriginalView;
  }

  createObjectToCompare(
    cols: TableCol[],
  ): { [key: string]: { order: number; width: string | undefined; hidden: boolean } }[] {
    return cols.map((col) => {
      return {
        [col.key]: {
          order: col.order,
          width: col.headerStyle?.width,
          hidden: !!col.hidden,
        },
      };
    });
  }

  /**
   * Updates the width of the given column.
   *
   * @param width - Width of the column in pixels.
   * @param key - Key of the column.
   */
  updateWidth(width: number, key: string) {
    const widthPx = width + 'px';
    const columnIndex = this.colsManipulation.findIndex((c) => c.key === key);
    if (this.colsManipulation[columnIndex]?.headerStyle.width !== widthPx) {
      this.colsManipulation[columnIndex].headerStyle.width = widthPx;
    }
  }

  sortByOrderMenu(cols: TableCol[]) {
    return cols.sort((a, b) => a.orderMenu - b.orderMenu);
  }
}
