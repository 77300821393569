<div class="sort-wrapper">
  <ng-content></ng-content>
  <button
  matTooltip="Sort by {{colName}}"
  matTooltipPosition="below"
  *ngIf="showColClientSort"
  (click)="onSortByField(); $event.stopPropagation()"
  class="sort-field-btn"
  [style]="{'color': key === activeKey ?'#dadce0' : '#80868b'}"
>
    <svg width="24px" height="24px" fill="currentcolor" stroke="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    [style]="directionChanged ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"
    class="sort-field-icon">
      <path d="M16.707,13.293c-0.391-0.391-1.023-0.391-1.414,0L13,15.586V8c0-0.552-0.447-1-1-1s-1,0.448-1,1v7.586l-2.293-2.293 c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L12,19.414l4.707-4.707C17.098,14.316,17.098,13.684,16.707,13.293z"/>
    </svg>
  </button>
</div>
