import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, forkJoin, from, Observable, of, pipe, Subject, timer} from 'rxjs';
import {catchError, expand, first, map, mergeMap, reduce, shareReplay, switchMap, take, tap, toArray} from 'rxjs/operators';

import {AssetsListRequestParams} from 'api/ias/api/projects.service';
import {ApiVideoFormat} from 'api/ias/model/models';
import {assumeExhaustiveAllowing} from 'asserts/asserts';
import {AssetCopyStats, AssetRendition, ExportInfo, Metadata, PfrInfo} from 'models';

import { PermissionDetail } from '../access_management/models/access_management.model';
import { AccessManagementService } from '../access_management/services/access_management.service';
import {ErrorResponse, isErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {GcmaQueryExpressions} from '../query_expressions/gcma_query_expressions';

import {BatchedResourceChanges} from './api_client.module';
import {AssetApiService, AssetState, MetadataField, PRE_INGESTION_SCHEMA_ID} from './asset_api_service';
import {ClipApiService} from './clip_api_service';
import {ApiAssetStateEnum, ApiCompReelState, ApiCopyStateDetailState, ApiEventMetadataStreamingState, ApiExportStatusExportState, ApiPFRStateInfoState} from './ias_types';
import {LiveAssetManifestInfo, ManifestService} from './manifest.service';
import {MetadataSchema, SchemaApiService} from './schema_api_service';
import {ClipbinStorageService} from './storage/clip_bin_storage.service';
import {TimezoneService} from './timezone_service';
import {DEFAULT_CONCURRENT_REQUEST_NUMBER, UtilsService} from './utils_service';


export {AssetState} from './asset_api_service';

/** Available export type for monitoring */
export type ExportType = 'VoD'|'Live'|'CompReel';

/** How often to pull a list of processing assets, in ms. */
const PROCESSING_INTERVAL_MS = 5 * 60 * 1000;

/** How often to pull a list of recent assets, in ms. */
const RECENTS_INTERVAL_MS = 120 * 1000;

/** Schema cache duration = 1 hour. */
const CACHE_DURATION = 60 * 60 * 1000;

/**
 * How many schemas to load in memory at most. Schemas are expected to be
 * filterable soon by IAS, which should limit the number received. If too many
 * schemas are listed, we will need to implement pagination.
 */
const MAX_SCHEMAS_COUNT = 300;

/** Serve assets */
@Injectable({providedIn: 'root'})
export class AssetService {
  private readonly clipUpdated = new BehaviorSubject<void>(undefined);
  /**
   * Emits any time a clip is created, deleted, renamed, or moved.
   */
  readonly clipUpdated$ = this.clipUpdated as Observable<void>;

  /**
   * Used for re-using recent clips when entering details page and avoid an
   * extra api call.
   */
  cachedRecents?: Asset[];

  /** Emits whenever an asset is updated via the api. */
  readonly assetChanged$ = this.assetApi.assetChanged$;

  /** Emits asset updates batched together within 300ms */
  readonly assetsChanged$ = this.assetApi.assetsChanged$;

  constructor(
      private readonly assetApi: AssetApiService,
      private readonly clipApi: ClipApiService,
      private readonly errorService: ErrorService,
      private readonly schemaApi: SchemaApiService,
      private readonly manifestService: ManifestService,
      private readonly utils: UtilsService,
      private readonly gcmaQueries: GcmaQueryExpressions,
      private readonly timezone: TimezoneService,
      private readonly featureService: FeatureFlagService,
      private readonly clipbinStorageService: ClipbinStorageService,
      private readonly accessManagerService: AccessManagementService,
    ) {}

  // Only used for permission check
  // If the user doesn't have right permission, the API will return 403.
  checkPermission(): Observable<PermissionStatus> {
    return this.assetApi.getRecents().pipe(
        map(() => PermissionStatus.AUTHORIZED),
        this.errorService.retryLong(),
        catchError((response: ErrorResponse) => {
          if (response.status === StatusCode.FORBIDDEN) {
            return of(PermissionStatus.UNAUTHORIZED);
          }
          return of(PermissionStatus.UNRESOLVED);
        }),
    );
  }

  /**
   * Extract last part of the given schema name.
   * E.g. `projects/12345/locations/global/metadataSchemas/event-default` =>
   * `event-default`.
   */
  formatSchemaName(schemaName?: string): string {
    return this.utils.lastPart(schemaName);
  }

  /** Return metadata schema title based on asset's schema name. */
  getSchemaTitle(asset: Asset): Observable<string|undefined> {
    const schemaName = asset.assetMetadata.schema;
    if (!schemaName) return of('');
    return this.getSchema(schemaName)
        .pipe(
            map(schema => schema?.title || this.formatSchemaName(schemaName)));
  }

  getRecents(): Observable<Original[] | null> {
    return this.assetApi.getRecents().pipe(
      switchMap((assets: Original[]) => {
        if (!assets) return of(null);

        if (this.featureService.featureOff('enable-access-management')) {
          // Create an observable for each asset to get its restrictions
          const assetsWithPermissions$ = assets.map(asset => {
            return this.accessManagerService
              .getResourceAccessInfoByResource(
                'RESTRICTION',
                asset.name,
                'ASSET'
              ).pipe(
                first(),
                map((accessInfo) => {
                  if ( !accessInfo || accessInfo.length === 0 ) return asset;
                  asset.permissions = accessInfo[0].permissions;
                  return asset;
                })
            );
          });

          // Wait for all observables to complete
          return forkJoin(assetsWithPermissions$);
        } else {
          return of(assets);
        }
      }),
      this.errorService.retryLong(),
      catchError(error => {
        this.errorService.handle(error);
        return of(null);
      })
    );
  }

  getClipsFromAsset(
      originalAssetName: string, pageSize: number,
      pageToken?: string): Observable<ListResponse<Clip>|null> {
    return this.clipApi
        .getClipsFromAsset(originalAssetName, pageSize, pageToken)
        .pipe(
            this.errorService.retryLong(),
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
        );
  }

  /**
   * Continuously fetches all clips that belong to the given asset, emitting
   * each page of results in sequence.
   */
  expandClipsFromAsset(originalAssetName: string) {
    const clipsPageSize = 25;
    return this.getClipsFromAsset(originalAssetName, clipsPageSize)
        .pipe(expand((result) => {
          if (!result?.nextPageToken) return EMPTY;
          return this.getClipsFromAsset(
              originalAssetName, clipsPageSize, result.nextPageToken);
        }));
  }

  getAsset(name: string, noRetry = false): Observable<Original|null> {
    return this.assetApi.getOne(name).pipe(
        noRetry ? pipe() : this.errorService.retryLong(),
        catchError(error => {
          this.errorService.handle(error);
          return of(null);
        }),
    );
  }

  getClip(name: string): Observable<Clip|ErrorResponse> {
    return this.clipApi.getOne(name).pipe(
        this.errorService.retryShort(), this.errorService.catchError());
  }

  searchClips(binName: string, query = '', pageToken?: string, pageSize = 24):
      Observable<ListResponse<Clip>|ErrorResponse> {
    return this.clipApi.searchClips(binName, query, pageToken, pageSize)
        .pipe(
            this.errorService.retryShort(),
            this.errorService.catchError(),
        );
  }

  getClipsCount(clipbinName: string): Observable<number|ErrorResponse> {
    return this.clipApi.getCount(clipbinName)
        .pipe(
            this.errorService.retryShort(), this.errorService.catchError(),
            mapOnSuccess(response => response.count));
  }

  getLiveClipCount(clipbinName: string) {
    return this.clipApi.getLiveClipCount(clipbinName)
        .pipe(
            this.errorService.retryShort(), this.errorService.catchError(),
            mapOnSuccess(response => response.count));
  }

  /**
   * Creates a clip in each of the provided clip bins.
   *
   * @returns Number of failures.
   */
  createClipInMultipleBins(
      original: Original, bins: string[], startTime: number, endTime: number,
      title = original.title, trackIndex?: number): Observable<number> {
    return from(bins).pipe(
        mergeMap(
            bin => this.clipApi.create(original, bin, startTime, endTime, title)
                       .pipe(catchError(error => {
                         this.errorService.handle(error);
                         return of(null);
                       })),
            DEFAULT_CONCURRENT_REQUEST_NUMBER),
        tap(clip => this.storeClipData(clip, trackIndex)),
        reduce((total, currentResult) => total + (!currentResult ? 1 : 0), 0),
        tap(() => {
          this.clipUpdated.next();
        }),
    );
  }

  createClipSilent(
      original: Original, bin: string, startTime: number, endTime: number,
      title = original.title): Observable<Clip> {
    return this.clipApi.create(original, bin, startTime, endTime, title);
  }

  /**
   * Stores additional clip's data (audio track index for now) in local storage.
   */
  private storeClipData(clip: Clip | null, trackIndex?: number) {
    if (!clip || !trackIndex) {
      return;
    }

    this.clipbinStorageService.updateClipInBin(clip, trackIndex);
  }

  /**
   * For each asset creates a full duration clip and adds it to each
   * clip bin.
   *
   * @returns `null` when all requests failed. Otherwise, return failure count.
   */
  createFullDurationClipsInBins(assets: Original[], bins: string[]):
      Observable<Array<Clip|ErrorResponse>> {
    // For each asset...
    return from(assets).pipe(
        // Fetch asset durations.
        mergeMap(
            asset => {
              return this.getAssetDuration(asset).pipe(
                  map(duration => ({asset, duration})));
            },
            DEFAULT_CONCURRENT_REQUEST_NUMBER),
        // Wait for all assets duration requests to complete.
        toArray(),
        // Create all individual clip creation requests.
        switchMap(assetsWithDuration => {
          const requests: Array<{asset: Original, duration: number|ErrorResponse, bin: string}> = [];
          for (const {asset, duration} of assetsWithDuration) {
            for (const bin of bins) {
              requests.push({asset, duration, bin});
            }
          }
          return from(requests);
        }),
        // Process all individual clip creation requests with controlled
        // concurrency.
        mergeMap(
            ({asset, bin, duration}) => {
              if (isErrorResponse(duration)) return of(duration);
              return this.clipApi.create(asset, bin, 0, duration, asset.title)
                  .pipe(this.errorService.catchError());
            },
            DEFAULT_CONCURRENT_REQUEST_NUMBER),
        toArray(),
        tap(() => {
          this.clipUpdated.next();
        }),
    );
  }

  renameClip(name: string, title: string): Observable<Asset|null> {
    return this.clipApi.rename(name, title).pipe(tap(() => {
      this.clipUpdated.next();
    }));
  }

  moveClip(name: string, label: string): Observable<Clip|null> {
    return this.clipApi.move(name, label)
        .pipe(
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
            tap(result => {
              if (result) {
                this.clipUpdated.next();
              }
            }));
  }

  deleteClip(name: string): Observable<null> {
    return this.clipApi.delete(name).pipe(tap(() => {
      this.clipUpdated.next();
    }));
  }

  deleteClipSilent(name: string): Observable<null> {
    return this.clipApi.delete(name);
  }

  exportClip(name: string, exportFolder: string):
      Observable<Clip|ErrorResponse> {
    return this.clipApi.export(name, exportFolder)
        .pipe(
            tap(() => {
              this.clipUpdated.next();
            }),
            this.errorService.catchError());
  }

  /**
   * Reorders clips in a bin. Will only reorder if the clips belong to the same
   * bin
   *
   * @param clip Clip to be reordered
   * @param previousClipName Name of clip that will precede moved clip.
   *     Can be left empty if clip is moved to the front.
   * @param nextClipName Name of clip that will succeed moved clip. Can be left
   *     empty if clip is moved to the end.
   */
  reorderClip(clip: Clip, previousClipName?: string, nextClipName?: string):
      Observable<Clip|null> {
    return this.clipApi.reorder(clip, previousClipName, nextClipName)
        .pipe(catchError(error => {
          this.errorService.handle(error);
          return of(null);
        }));
  }

  updateMetadata(
      asset: Original, schema: string, jsonMetadata: Record<string, unknown>):
      Observable<Original|ErrorResponse> {
    return this.assetApi.updateMetadata(asset, schema, jsonMetadata)
        .pipe(this.errorService.catchError());
  }

  createCopy(asset: Original, copy: AssetCopy) {
    return this.assetApi.createCopy(asset, copy)
        .pipe(this.errorService.catchError());
  }

  listCopies(asset: Original) {
    return this.assetApi.listCopies(asset).pipe(
        this.errorService.retryShort(), this.errorService.catchError());
  }

  approveCopy(copy: AssetCopy): Observable<AssetCopy|ErrorResponse> {
    return this.assetApi.approveCopy(copy).pipe(this.errorService.catchError());
  }

  deleteCopy(copy: AssetCopy): Observable<ErrorResponse|null> {
    return this.assetApi.deleteCopy(copy).pipe(this.errorService.catchError());
  }

  updateCopyMetadata(copy: AssetCopy, copyMetadata: Metadata):
      Observable<AssetCopy|ErrorResponse> {
    return this.assetApi.updateCopyMetadata(copy, copyMetadata)
        .pipe(this.errorService.catchError());
  }

  /** Periodically checks for the list of recent assets. */
  watchRecents(): Observable<Original[]|null> {
    return timer(0, RECENTS_INTERVAL_MS)
        .pipe(switchMap(() => this.getRecents()));
  }

  /** Periodically checks for the list of processing assets. */
  watchProcessing(): Observable<string[]> {
    return timer(0, PROCESSING_INTERVAL_MS)
        .pipe(
            switchMap(
                () => this.assetApi.getProcessing(101).pipe(
                    catchError((error: Error) => {
                      this.errorService.handle(error);
                      return of(null);
                    }))),
            map(response => {
              if (!response) return [];
              return response.assets.map(asset => {
                const url = asset.gcsLocationUrl;
                // Unsign and extract name with extension such as `name.mp4`
                const match = url.replace(/\?.*$/, '').match(/([^/]*)$/);
                return match ? match[1] : asset.title;
              });
            }),
        );
  }

  /** Retrieves duration of ended live assets and VoDs. */
  getAssetDuration(asset: Asset): Observable<number|ErrorResponse> {
    // For VoDs and clips duration is known.
    if (!asset.isLive || asset.original) return of(asset.duration);

    // Live assets in states other than airing, ended and processing state have
    // no content.
    if (![AssetState.AIRING, AssetState.ENDED, AssetState.PROCESSING].includes(
            asset.state)) {
      this.errorService.handle(
          `Duration cannot be requested for the asset in state ${asset.state}`);
      return of(new ErrorResponse(`Failed to get asset duration`));
    }

    // For live assets we need to fetch and parse manifest file.
    return this.manifestService.getLiveAssetTimeline(asset).pipe(
        mapOnSuccess(info => info.durationInSeconds));
  }

  /**
   * Loads schema from the backend or cache.
   *
   * Under the hood requests and caches all schemas to avoid additional requests
   * in the future. If the number of schemas becomes unreasonable for a single
   * request consider reverting cr/420184002 and going with lightweight list
   * request and individual requests for when full schema is needed.
   */
  getSchema(uri: string): Observable<MetadataSchema|null> {
    const prevRequestTimestamp = this.schemaRequestTimestamp;
    return this.getSchemaInternal(uri).pipe(switchMap(schema => {
      // Return the schema.
      if (schema) return of(schema);

      // If schema is not found but schemas response was cached, query again
      // without the cache. Timestamp being 0 indicates that the last request
      // errored out => do not retry.
      if (this.schemaRequestTimestamp !== 0 &&
          prevRequestTimestamp === this.schemaRequestTimestamp) {
        return this.getSchemaInternal(uri, /* invalidateCache */ true);
      }

      return of(null);
    }));
  }

  /**
   * Returns either schemas filtered by their scope and whether they are hidden.
   * Force the inclusion of the current schema if provided.
   */
  listFilteredSchemas(
      scope: 'asset_or_undefined'|'annotated_segment',
      currentSchemaName?: string) {
    return this.listAllSchemas().pipe(map(schemas => {
      if (!schemas) return [];

      if (this.featureService.featureOff('use-schema-scope-filter')) {
        return schemas;
      }

      // Sort schemas by title
      schemas.sort((a, b) => a.title < b.title ? -1 : 1);

      return schemas.filter(schema => {
        // Keep current segment schema in the list even if its scope is not
        // correct, ot it's a hidden schema.
        const isCurrent =
            currentSchemaName && schema.name === currentSchemaName;
        if (isCurrent) return true;

        // Otherwise hide any hidden schema.
        if (schema.hidden) return false;

        // Hide schemas without the title
        if (!schema.title) return false;

        // Hide the cutdown schema if it wasn't current.
        if (schema.subScope === 'cutdown') return false;

        // Because schemas scopes are only defined when they are of the type
        // `annotated_segment`, we check for the presence or absence of this
        // scope. Eventually, asset schemas should have an `asset` scope.
        const isValid = scope === 'annotated_segment' ?
            schema.scope === 'annotated_segment' :
            !schema.scope || schema.scope === 'asset';

        return isValid;
      });
    }));
  }

  /** Returns all metadata schemas available. Caches response for 5 minutes. */
  listAllSchemas(invalidateCache = false): Observable<MetadataSchema[]|null> {
    const timestamp = Date.now();

    // Drop cached response if needed.
    if (invalidateCache ||
        timestamp - this.schemaRequestTimestamp >= CACHE_DURATION) {
      this.schemasResponse$ = undefined;
    }

    // Make request with response that can be shared with all consumers.
    if (!this.schemasResponse$) {
      this.schemaRequestTimestamp = timestamp;
      this.schemasResponse$ = this.listAllSchemasInternal().pipe(
          tap(schemas => {
            if (schemas == null) {
              // Invalidate error response right away.
              this.schemaRequestTimestamp = 0;
            }
          }),
          shareReplay({bufferSize: 1, refCount: false}));
    }

    return this.schemasResponse$.pipe(take(1));
  }

  /**
   * Returns the wall-clock beginning of the timeline (in milliseconds since
   * epoch).
   * - For an original live stream, it is its `availabilityStartTime`.
   * - For a live clip, it is the real time at which the clip starts.
   * - For a VoD assets converted from a live event, it is the number of ms
   * since the beginning of the day in which the original live stream started
   * (in case of a clip, that is also offset by the clip start time).
   * - In other cases (VoD without timecodes), returns `undefined`.
   */
  getWallclockStartTimestamp(
      asset: Asset, liveManifestInfo: Partial<LiveAssetManifestInfo>|null,
      ignoreTimezone?: boolean): number|undefined {
    // 0 in case of an original asset.
    const clipStartOffsetMs = asset.startTime * 1000;

    // Live assets have their start timecode in the manifest.
    if (liveManifestInfo?.start) {
      const manifestStartMs = liveManifestInfo.start.getTime();
      return manifestStartMs + clipStartOffsetMs;
    }

    // VoD assets converted from live have their start timecode as a property.
    if (asset.startTimecode) {
      // `startTimecode` are intended to be read in the pre-configured timezone,
      // even though the back-end des not include it.
      const timezoneOffset =
          ignoreTimezone ? 0 : this.timezone.getTimezoneOffset();
      return asset.startTimecode - timezoneOffset + clipStartOffsetMs;
    }

    return undefined;
  }

  deleteAsset(asset: Original): Observable<Original|ErrorResponse> {
    const delete$ = asset.isLive ? this.assetApi.deleteEvent(asset) :
                                   this.assetApi.delete(asset);
    return delete$.pipe(this.errorService.catchError());
  }

  getDeletedAssets(userQuery: string, pageSize: number, pageToken?: string):
      Observable<ListResponse<Original>|ErrorResponse> {
    const query = this.gcmaQueries.and([
      // Look for partial match in gcs url or video title.
      userQuery ? this.gcmaQueries.or([
        this.gcmaQueries.includes('gcsObject', userQuery),
        this.gcmaQueries.includes('title', userQuery)
      ]) :
                  '',
      // Get deleted VoDs only.
      this.gcmaQueries.is('state', ApiAssetStateEnum.STATE_DELETED),
    ]);

    const params: Partial<AssetsListRequestParams> = {
      showDeleted: true,
      pageSize,
      pageToken,
      filter: query,
    };
    return this.assetApi.list(params).pipe(
        this.errorService.retryShort(), this.errorService.catchError());
  }

  undeleteAsset(asset: Original): Observable<Asset|ErrorResponse> {
    return this.assetApi.undelete(asset).pipe(this.errorService.catchError());
  }

  /**
   * Returns the asset title if it has one, otherwise its base filename if it
   * has one.
   */
  getAssetTitle(asset: Original): string {
    return asset.assetMetadata.jsonMetadata[MetadataField.TITLE] ||
        this.utils.lastPart(asset.gcsLocationUrl) || asset.title;
  }

  signUrls(rawUrls: string[]) {
    return this.assetApi.signUrls(rawUrls).pipe(
        this.errorService.retryShort(),
        this.errorService.catchError(),
    );
  }

  /**
   * Format the VoD, Live, and comp reel export status.
   */
  formatExportFolderStatus(state?: ApiCompReelState|ApiPFRStateInfoState|
                           ApiExportStatusExportState): ClipOperationStatus {
    switch (state) {
      case 'DOWNLOAD_COMPLETED':
      case 'COMP_REEL_READY':
      case 'EXPORT_COMPLETED':
        return 'Completed';

      case 'RESTORE_FAILED':
      case 'DOWNLOAD_FAILED':
      case 'COMP_REEL_STATE_UNSPECIFIED':
      case 'COMP_REEL_FAILED':
      case 'EXPORT_STATE_UNSPECIFIED':
      case 'EXPORT_FAILED':
        return 'Failed';

      case 'PENDING':
      case 'COMP_REEL_PENDING':
      case 'EXPORT_PENDING':
        return 'Pending';

      case 'FILE_GENERATED':
        return 'Downloading';

      case 'STATE_UNSPECIFIED':
        return 'Not started';

      default:
        assumeExhaustiveAllowing<undefined>(state);
        return 'Not started';
    }
  }

  isVideoShareable(asset: Asset) {
    if (asset.state === AssetState.VOD) return true;

    // Share only ended live streams due to security concerns.
    return asset.state === AssetState.ENDED;
  }

  /** Timestamp of the latest request to fetch all schemas. */
  private schemaRequestTimestamp = 0;

  /** Contains the latest response to fetch all schemas request. */
  private schemasResponse$?: Observable<MetadataSchema[]|null>;

  private listAllSchemasInternal(): Observable<MetadataSchema[]|null> {

    return this.schemaApi.list(MAX_SCHEMAS_COUNT)
        .pipe(
            map(schemas => {
              // Filter out pre-ingestion schema
              return schemas.filter(
                  s => !s.name.endsWith(PRE_INGESTION_SCHEMA_ID));
            }),
            this.errorService.retryLong(), catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
            tap((schemas) => {
              if (!schemas) return;

              if (schemas.length > MAX_SCHEMAS_COUNT * 0.75) {
                this.errorService.handle(
                    'Received large number of schemas, pagination is needed.');
              }
            }));
  }

  /** Loads schema from the backend unless it has been cached. */
  private getSchemaInternal(uri: string, invalidateCache = false):
      Observable<MetadataSchema|null> {
    return this.listAllSchemas(invalidateCache).pipe(map(schemas => {
      // Return schema or error indicator that schema is not found.
      return schemas?.find(s => s.name === uri) || null;
    }));
  }
}

/** Shared properties between full `Original` and `Clip` assets. */
interface BaseAsset {
  /** Unique identifier for this asset, expected by ui-table */
  id?:string

  /** Unique identifier for this asset */
  name: string;

  /** Display title */
  title: string;

  /** Duration of this asset in seconds. `0` for original live assets. */
  duration: number;

  /** Date added (milliseconds since epoch) */
  createTime: number;

  /** Time when the event recorded happened (milliseconds since epoch) */
  eventTime: number;

  /** List of available renditions of the original asset */
  renditions: AssetRendition[];

  /** Asset thumbnail, only used if there is no spriteSheet */
  thumbnail?: string;

  /**
   * Start time in seconds:
   * - `0` for an original asset (VOD or live);
   * - Mark-in offset for a clip, in seconds.
   */
  startTime: number;

  /**
   * End time in seconds:
   * - `duration` for an original VOD asset;
   * - `0` for original live asset;
   * - Mark-out offset for a clip, in seconds.
   */
  endTime: number;

  /** Last modified date (milliseconds since epoch) */
  updateTime: number;

  /**
   * - Schedule start timestamp (milliseconds since epoch) for a live asset.
   * - `0` for VoDs.
   */
  eventStartTime: number;

  /**
   * - Schedule end timestamp (milliseconds since epoch) for a live asset.
   * - `0` for VoDs.
   */
  eventEndTime: number;

  /**
   * Optional start timecode of an asset converted from a live stream, as a
   * number of milliseconds. These have no date or timezone information, for
   * instance `00:01am` is encoded as `60_000`.
   */
  startTimecode?: number;

  /**
   * Original asset GCS location URI (gs://). Always known for VoDs. For live,
   * it is only known once the file is fully uploaded to GCS, and undefined
   * before that.
   */
  gcsLocationUrl: string;

  /** State of the original asset such as VoD, Live, etc. */
  state: AssetState;

  /** Properties related to camera feed. Mostly used for multi-camera events. */
  camera?: CameraInfo;

  /** Original asset media metadata, includes json metadata and schema uri */
  assetMetadata: Metadata;

  /**
   * Indicates if this asset or clip refers to a live stream event in any state.
   */
  isLive: boolean;

  /**
   * Whether this asset has been approved from content staging. For live assets
   * approved state indicates that there is an approved full duration copy.
   */
  approved: boolean;

  /**
   * Name of VoD asset resulted from successful full duration cutdown. Used for
   * live assets only.
   */
  archiveName?: string;

  /**
   * Asset's source.
   * Possible values:
   * - 'Cloud Ingest': when asset was created from media placed into 'Cloud_Ingest'
   * folder;
   * - 'Local Upload': when asset was created from media placed into 'local-uploads'
   * folder;
   * - < username/creator or username/delete > (for example, 'Foo Bar'): when it was possible to read
   * creator for the 'Local Upload' or 'Delete Asset' events;
   * - < site > (for example, 'DEV'/'QA' for staging env, 'LAX'/'CLT' for prod env,
   * etc.): when asset was created from media placed into 'mediacache' folder
   * where it appeared after copying from on-prem storage.
   */
  source?: string;
}

/** A full original asset, which `original` property is always `undefined`. */
export interface Original extends BaseAsset {
  /** Never defined for `Original` assets. */
  original?: never;

  /**
   * Offset since the start of a live stream for cut-down beginning.
   * `0`for VoDs.
   */
  preCut: number;

  /**
   * Offset since the start of a live stream for cut-down end. `0` for VoDs.
   */
  postCut: number;

  /** Indicates that the asset is in error state. */
  hasError: boolean;

  /** When the asset is in error state, may contain an error reason.  */
  errorReason?: string;

  /** Indicates whether the asset is softly deleted. */
  isDeleted: boolean;

  /**
   * Streaming state of the live event. Set to `STREAMING_STATE_UNSPECIFIED` for
   * VoDs.
   */
  streamingState: ApiEventMetadataStreamingState;

  /**
   * If it's a mezzanine asset, the signed url will be provided; otherwise it's
   * undefined.
   */
  rawSourceUrl?: string;

  /** Source video information, such as framerate. */
  videoFormat: ApiVideoFormat;

  /** Contains information about copies scheduled/made from this asset. */
  copyStats: AssetCopyStats;

  /** Permissions and DocumentId retrieved from Firestore (access management functionality). */
  permissions?: PermissionDetail[];
  permissionsDocumentId?: string;
}

/** Order by options for asset rows. */
export interface AssetRowSort {
  active: 'title'|'source'|'type'|'description'|'sport'|'start'|'end'|'camera'|'courtesy';
  direction: 'asc'|'desc';
}

/** Filter option for assets rows */
export interface AssetRowFilter {
  title?:string;
  name?: string;
}

/** Represents a single smallest change of filter. */
export type AssetRowFilterChange = {
  [K in keyof AssetRowFilter] -?: {type: K; value: AssetRowFilter[K];}
}[keyof AssetRowFilter];

/** A clip, which always has an `original` asset. */
export interface Clip extends BaseAsset {
  /** Original asset this clip is based on. */
  original: Original;
  /** Clipbin name of this clip. */
  label: string;
  /** Export clip Info for live clip. */
  exportInfo: ExportInfo;
  /** Partial File Restore Info for clips. */
  pfrInfo: PfrInfo;
  /** Track index. */
  trackIndex?: number;
  /** Permissions retrieved from Firestore (access management functionality). */
  permissions?: PermissionDetail[];
}

/**
 * Either an `Original` or a `Clip` asset. Read its `original` property to
 * determine which type it is.
 */
export type Asset = Original|Clip;

/** Represents the ListResponse */
export interface ListResponse<T extends Asset> {
  assets: T[];
  nextPageToken?: string;
}

/** Represents the status if the user is authorized to our app. */
export enum PermissionStatus {
  AUTHORIZED,
  UNAUTHORIZED,
  // Can not decide the status due to system is down
  UNRESOLVED,
}

/**
 * Asset properties related to camera feed. Mostly used for multi-camera
 * events.
 */
export interface CameraInfo {
  /** Indicates whether this is the broadcast feed asset. */
  isBroadcast?: boolean;
  /** Camera angle label for the multi-camera event. */
  label: string;
  /**
   * Id that ties together all camera angles for the same multi-camera event.
   */
  correlationId: string;
  /** Number of camera angles available for the multi-camera event. */
  totalCount?: number;
}

/** Represents a visual marking area, used for clips and cut-down segments. */
export interface ClipMarking {
  markIn: number;
  markOut: number;
}

/** Test helper to generate AssetBatchChanged emission. */
export function fakeEmitAssetsChanged(
    subject: Subject<BatchedResourceChanges<Original>>, assets: Original[]) {
  subject.next({updates: new Map(assets.map(a => ([a.name, a])))});
}

/**
 * Represents an asset copy request. Common usage: live asset full or partial
 * cutdown.
 */
export interface AssetCopy {
  name: string;
  isFullCopy: boolean;
  startOffset: number;
  endOffset: number;
  fileName: string;
  metadata: Metadata;
  state: ApiCopyStateDetailState;
  errorReason?: string;
  vodAssetName?: string;
}

/** The clip status reflects IAS operations results. */
export type ClipOperationStatus =
    'Not started'|'Failed'|'Pending'|'Completed'|'Downloading';
